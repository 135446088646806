import React, { useMemo } from 'react'
import blockHourBg from './icons/appt-block-hour.svg';
import timeIcon from './icons/time.svg';
import { momentTimezone } from 'utils/time';
const BlockHourItem = ({ data }) => {
  const startTime = React.useMemo(() => momentTimezone(data.startTime), [data]);
  const endTime = useMemo(() => momentTimezone(data.endTime), [data]);
  const extraData = useMemo(() => data.data, [data]);
  return (
    <div className='block-hour-item-container' style={{ backgroundImage: `url(${blockHourBg})` }}>
      <div className="block-hour-top">
        <img src={timeIcon} alt="timeIcon" className="icon" />
        <div className="block-hour-time">
          <span>{startTime.format('hh:mm A')} - {endTime.format('hh:mm A')}</span>
        </div>
      </div>
      <div className="block-hour-note">Reason: {extraData.note || '--'}</div>
    </div>
  )
}

export default BlockHourItem;
