import React, { useState, useEffect } from 'react';

// reactstrap components
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { API } from 'common';
import constant from 'constant';
import classNames from "classnames";
import ScrollContainer from "react-indiana-drag-scroll";

export default function ReassignTickets() {
	const [reassignAppointList, setReassignAppointList] = useState([]);
	const [staffSelect, setStaffSelect] = useState(null);
	const [ticketSelect, setTicketSelect] = useState(null);
	const [ openConfirm, setOpenConfirm ] = useState(false);

	const fetch_ticket_detail = async () => {
		try {
			let response = await API.get(`admin/getListAssignAppointment`);
			if (response.status == 200) {
				let { payload } = response.data;
				if (payload.length > 0) {
					setReassignAppointList(payload);
				}
			}
		} catch (error) {
			console.error('fetch_ticket_detail', error);
		}
	}

	useEffect(() => {
		fetch_ticket_detail();
	}, [])

	const selectStaff = (e, staff, ticket) => {
		e.preventDefault();
		setStaffSelect(staff);
		setTicketSelect(ticket)
		// open popup to confirm
		setOpenConfirm(true);
	}
	const close_confirm = () => {
		setStaffSelect(null);
		setTicketSelect(null);
		setOpenConfirm(false);
	}

	const reassign_staff = async (e) => {
		e.preventDefault();
		
		let response = await API.post('admin/assignAppointment', {
			payload: {
				appointmentId: ticketSelect.appointmentId,
				assignAppointmentId: ticketSelect.assignAppointmentId,
				staffIdTo: staffSelect.staffId
			}
		});
		
		if (response.status == 200) {
			fetch_ticket_detail();
			// close popup
			setOpenConfirm(false);
		}
	}

	return (
		<>
			<Helmet title="Reassign Tickets" />
			<Container style={{ backgroundColor: '#F0F4FA' }} >
				<div className="reassign-tickets pt-3 pb-3">
					{reassignAppointList && reassignAppointList.map((item, index) => {
						let staffs_available = [];
						if (item.staffAvailable && item.staffAvailable.length > 0) {
							staffs_available = item.staffAvailable;
						}

						return (
							<Row className="ticket-item m-0 pt-2 mb-3" key={index}>
								<Col xs={12}>
									<div className="ticket-id">
										Ticket: <span>#{item.appointmentId}</span>
									</div>
									<div className="d-flex mt-2 mb-2 w-100 justify-content-between">
										<div className="title">From</div>
										<div className="name">{item.staffNameFrom}</div>
									</div>
									<div className="d-flex mt-2 mb-2 w-100 justify-content-between">
										<div className="title">Assign To:</div>
										<div className="name">{item.staffNameTo}</div>
									</div>
									<div className="d-flex mt-2 mb-2 w-100 justify-content-between">
										<div className="title">Reasons</div>
										<div className="name">Family issue</div>
									</div>
									{
										item.reasons == constant.REASONS_LIST[4].value ?
											<div className="content">
												{item.note}
											</div>
											: ''
									}
									<div className="staff-list mt-4">
										<p>Available Staff</p>
										<ScrollContainer className="scroll-container">
											<div className="d-flex">
												{staffs_available.map((staff) => {
													return (
														<div
															key={staff.staffId}
															className={classNames("staff-item", {
																selected: ((staffSelect && staffSelect.staffId == staff.staffId) && (ticketSelect && ticketSelect == item)),
															})}
															onClick={(e) => selectStaff(e, staff, item)}
														>
															<div>
																<img
																	src={
																		staff.avatar
																			? staff.avatar
																			: require('assets/img/theme/team-4-800x800.jpg')
																	}
																/>
															</div>
															<h5>
																{staff.staffName}
															</h5>
														</div>
													);
												})}
											</div>
										</ScrollContainer>
									</div>
								</Col>
							</Row>
						)
					})
					}
				</div>
				<Modal isOpen={openConfirm} className="modal-dialog-centered" border>
					<ModalHeader style={{borderBottom: '1px solid #e9ecef'}}>Confirm</ModalHeader>
					<ModalBody>
						Are you sure assign this ticket to staff <b>"{staffSelect && staffSelect.staffName}"</b>?
					</ModalBody>
					<ModalFooter className="d-block text-center">
						<Button color="primary" onClick={(e) => reassign_staff(e)}>Yes</Button>
						<Button color="secondary" onClick={() => close_confirm() }>No</Button>
					</ModalFooter>
				</Modal>
			</Container>
		</>
	);
}
