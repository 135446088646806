import Cookies from 'js-cookie';
import axios from 'axios';
import JwtDecode from 'jwt-decode';
import { format, toDate } from 'date-fns';

export const isAuthenticated = () => {
	const staff_info = JSON.parse(localStorage.getItem('infor_staff'));
	let token = Cookies.get(process.env.REACT_APP_COOKIE_KEY);
	const isManager = Cookies.get(process.env.REACT_APP_COOKIE_KEY_ISMANAGER);
	if (staff_info && token && isManager != undefined) {
		return true;
	}
	return false;
};

export const getDataCookie = (key) => Cookies.get(key);

export const API = axios.create({
	baseURL: process.env.REACT_APP_API,
	headers: {
		// 'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/json',
	},
});

export const initHeader = () => {
	let token = isAuthenticated();
	API.defaults.headers.common['Authorization'] = token || '';
};

export const setHeader = (apiKey) => {
	let key = apiKey || '';
	API.defaults.headers.common['x-api-key'] = key;
	key !== '' &&
		setCookieAuthenticate(
			key,
			parseInt(process.env.REACT_APP_EXPIRES_COOKIE),
			process.env.REACT_APP_API_X_KEY
		);
};

// initHeader();
setHeader();
API.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error.response.status === 401) {
			Cookies.remove(process.env.REACT_APP_COOKIE_KEY);
		}
		return Promise.reject(error);
	}
);
// Add a request interceptor
API.interceptors.request.use(
	function (config) {
		config.headers = {
			'x-api-key': getDataCookie(process.env.REACT_APP_API_X_KEY),
		};
		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

// Set cookie for authentication
export const setCookieAuthenticate = (
	value = '',
	expires,
	key = process.env.REACT_APP_COOKIE_KEY
) => {
	Cookies.set(key, value, { expires: expires });
};

export const logOut = (callback) => {
	Cookies.remove(process.env.REACT_APP_COOKIE_KEY);
	Cookies.remove(process.env.REACT_APP_API_X_KEY);
	Cookies.remove(process.env.REACT_APP_COOKIE_KEY_ISMANAGER);
	localStorage.clear();
	callback();
};

// Get user information from cookie
export const getUserInfo = () => {
	let token = isAuthenticated();
	let userInfo = {};
	if (token) {
		userInfo = JwtDecode(token);
	}
	return userInfo;
};

// Format time
export const formatTime = (data, timestamp = true, formatime) => {
	let stringFormat = formatime || 'dd MM yyyy';
	return timestamp
		? format(toDate(data), stringFormat)
		: format(data, stringFormat);
};

export const setCookie = (
	value = '',
	key = process.env.REACT_APP_COOKIE_KEY,
	expire = process.env.REACT_APP_EXPIRES_COOKIE
) => {
	Cookies.set(key, value, { expires: expire });
};


