import React from 'react';

const CouponContainer = ({ active, children }) => {
  return (
    <div className='coupon-info-box-styled'>
      <div className='selling-box'>
        <div className={`box ${active ? 'active' : ''}`} >
          <div style={{ width: '100%' }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponContainer;