import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// core components
import { Helmet } from 'react-helmet';

import routes, { AdminAptRoutes, AptRoutes } from 'routes.js';
import withHeader from 'components/HOC/withHeader';
import ErrorPage from 'views/404';
import { Container } from 'reactstrap';
import Cookies from 'js-cookie';
import getNewAptRoute, { NewAppointmentContext } from 'views/new-appointment';
import { connect } from 'react-redux';
import shopActions from 'store/shop/actions';
import { NEW_APT_STEPS } from 'constant';
import { getBookingRoute } from 'views/new-appointment/booking';
class Admin extends React.Component {
	componentDidMount() {
		this.props.initShopData();
	}
	componentDidUpdate() {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}
	getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.layout !== 'admin') return null;
			if (Cookies.get('isManager') == prop.isManager) {
				// redirtect to index page
				if (prop.isManager == true && window.location.pathname == '/') {
					// is manager
					window.location.href = '/overview';
				}
				else if (prop.isManager == false && window.location.pathname == '/overview') {
					// is staff
					window.location.href = '/';
				}
				return this.renderComponent(
					prop.path,
					withHeader(prop.component, {
						title: prop.name,
						stats: prop.stats || false,
						isDisplayHeader: prop.hasOwnProperty('isDisplayHeader')
							? prop.isDisplayHeader
							: true,
						isDisplayNav: prop.hasOwnProperty('isDisplayNav')
							? prop.isDisplayNav
							: true,
						isManager: prop.hasOwnProperty('isManager')
							? prop.isManager
							: true,
						isHistoryBack: prop.hasOwnProperty('isManager')
							? prop.isHistoryBack
							: true,
					}),
					key
				);
			}

		});
	};

	renderComponent = (path, component, key) => (
		<Route exact key={key} path={path} component={component} />
	);
	render() {
		return (
			<Container className="px-0 mx-0" fluid>
				<Helmet titleTemplate={process.env.REACT_APP_NAME + ' | %s'} />
				<Switch>
					{this.getRoutes(routes)}
					{this.getRoutes([getBookingRoute()])}
					<NewAppointmentContext>
						<Switch>
							{this.getRoutes([
								getNewAptRoute(),
								getNewAptRoute(NEW_APT_STEPS.SERVICE),
								getNewAptRoute(NEW_APT_STEPS.DATE_STAFF),
								getNewAptRoute(NEW_APT_STEPS.TIME_SLOT),
								getNewAptRoute(NEW_APT_STEPS.REVIEW),
								getNewAptRoute(NEW_APT_STEPS.SUCCESS),
							])}
						</Switch>
					</NewAppointmentContext>
					{/* <Route path="/404" name="Page not found" component={ErrorPage} />
					<Redirect to="/404" /> */}
				</Switch>
			</Container>
		);
	}
}

export default connect(null, (dispatch) => ({
	initShopData: () => dispatch(shopActions.init()),
}))(Admin);