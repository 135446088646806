import React, { useState, useEffect } from 'react';

// reactstrap components
import { Helmet } from 'react-helmet';
import {
	Container,
	Row, 
	Col,
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
} from 'reactstrap';
import { API } from 'common';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';

export default function TicketByStatff() {
	const [activeTab, setActiveTab] = useState('ALL');
	const [data, setData] = useState([]);
	const [countData, setCountData] = useState({
			'ALL': 0,
			'OPEN': 0,
			'CLOSED': 0
		});

	const {id, name} = useParams();
	const staff_info = JSON.parse(localStorage.getItem('infor_staff'));

	const get_ticket_by_staff = async () => {
		try {
			let response = await API.get(`admin/ticket-by-staff?shopId=${staff_info.shopId}&staffId=${id}`);
			if (response.status == 200) {
				let { payload } = response.data;
				if (payload) {
					setData(payload);
					let OPEN_count = 0;
					let CLOSED_count = 0;
					payload.filter(item => {
						if (item.status == 'OPEN') OPEN_count++;
						if (item.status == 'CLOSED') CLOSED_count++;
					});
					setCountData({
						'ALL': payload.length,
						'OPEN': OPEN_count,
						'CLOSED': CLOSED_count,
					})
				}
			}
		} catch (error) {
			console.error('get_ticket_by_staff', error);
		}
	}

	useEffect(() => {
		get_ticket_by_staff();
	}, [])

	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	const tab_pane_html = (type) => data.map((item) => {
			if (type != '' && type !== item.status) return;
			let status_bgcolor = item.status == 'OPEN' ? 'rgba(70, 146, 244, 0.1)' : 'rgba(222, 19, 32, 0.1)';
			let status_color = item.status == 'OPEN' ? '#4692F4' : '#DE1320';
		return (
				<>
					<Row className="m-0">
						<Col className='ticket-by-staff' xl="12" sm="12" style={{borderColor: status_color, backgroundColor: status_bgcolor}}>
							<div className='d-flex justify-content-between'>
								<div>Ticket #: <b>{item.billId}</b></div>
								<div>{item.time}</div>
							</div>
							<div>Services:</div>
							{
								item.services && 
								item.services.length > 0 &&
								item.services.map((service) => (
									<div className='d-flex justify-content-between'>
										<div><b>{service.name}</b></div>
										<div className='font-weight-bold'>${service.price !=undefined && service.price.toFixed(2)}</div>
									</div>
								))
							}
							<div className='d-flex justify-content-between'>
								<div></div>
								<div>Status: <span className='font-weight-bold' style={{color: status_color}}>{item.status}</span></div>
							</div>
							<hr className='mt-0 mb-2'/>
							{
								item.status == 'OPEN' ? 
								(
									<>
										<div className='d-flex justify-content-between'>
											<div>Sub Total</div>
											<div className='font-weight-bold'>${item.subTotal !=undefined && item.subTotal.toFixed(2)}</div>
										</div>
										<div className='d-flex justify-content-between'>
											<div>Discount</div>
											<div className='font-weight-bold'>${item.discount !=undefined && item.discount.toFixed(2)}</div>
										</div>
										<div className='d-flex justify-content-between'>
											<div>TIP</div>
											<div className='font-weight-bold'>${item.tip !=undefined && item.tip.toFixed(2)}</div>
										</div>
										<div className='d-flex justify-content-between'>
											<div>Total</div>
											<div className='font-weight-bold'>${item.total !=undefined && item.total.toFixed(2)}</div>
										</div>
									</>
								)
								:
								(
									<>
										<div className='d-flex justify-content-between'>
											<div>Credit Card</div>
											<div className='font-weight-bold'>${item.creditCard !=undefined && item.creditCard.toFixed(2)}</div>
										</div>
										<div className='d-flex justify-content-between'>
											<div>Cash</div>
											<div className='font-weight-bold'>${item.cash !=undefined && item.cash.toFixed(2)}</div>
										</div>
										<div className='d-flex justify-content-between'>
											<div>Check/Other</div>
											<div className='font-weight-bold'>${item.checkOther !=undefined && item.checkOther.toFixed(2)}</div>
										</div>
										<div className='d-flex justify-content-between'>
											<div>Gift</div>
											<div className='font-weight-bold'>${item.gift !=undefined && item.gift.toFixed(2)}</div>
										</div>
										<div className='d-flex justify-content-between'>
											<div>Reward Point</div>
											<div className='font-weight-bold'>${item.reward !=undefined && item.reward.toFixed(2)}</div>
										</div>
									</>
								)
							}
						</Col>
					</Row>
				</>
			)
	});

	return (
		<>
			<Helmet title={name} />
			<Container className="overview m-0 p-0">
				<Nav tabs className="overview-custom-nav">
					<NavItem className="tab-item">
						<NavLink
							className={classnames({ active: activeTab === 'ALL' })}
							onClick={() => {
								toggle('ALL');
							}}
						>
							ALL ({countData.ALL})
						</NavLink>
					</NavItem>
					<NavItem className="tab-item">
						<NavLink
							className={classnames({ active: activeTab === 'OPEN' })}
							onClick={() => {
								toggle('OPEN');
							}}
						>
							OPEN ({countData.OPEN})
						</NavLink>
					</NavItem>
					<NavItem className="tab-item">
						<NavLink
							className={classnames({ active: activeTab === 'CLOSED' })}
							onClick={() => {
								toggle('CLOSED');
							}}
						>
							CLOSED ({countData.CLOSED})
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={activeTab} style={{color: "#000000", backgroundColor: '#fff'}} className="pt-2">
					<TabPane tabId="ALL">
						{tab_pane_html('')}
					</TabPane>
					<TabPane tabId="OPEN">
						{tab_pane_html('OPEN')}
					</TabPane>
					<TabPane tabId="CLOSED">
						{tab_pane_html('CLOSED')}
					</TabPane>
				</TabContent>
			</Container>
		</>
	);
}
