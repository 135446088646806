import moment from 'moment';
import React, { useState } from 'react'
import DatePicker from "react-datepicker";

const OverviewRangePicker = ({ stringData }) => {
  const calendarRef = React.createRef();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  return (
    <div className='overview_kit__summary'>
      <div className="overview_kit__summary_report_date" style={{ marginBottom: '10px' }}>Report range</div>
      <div className="d-flex align-items-center justify-content-center" style={{ marginBottom: '8px', gap: '8px' }}>
        <DatePicker
          ref={calendarRef}
          selected={startDate}
          onChange={async (date) => {
            setStartDate(date);
            const start_date = moment(date).format('MM-DD-YYYY');
            const end_date = moment(endDate).format('MM-DD-YYYY')
            const range_param = `startDate=${start_date}&endDate=${end_date}`;
            await stringData(range_param);
          }}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          withPortal
          customInput={
            <div className='overview_kit__summary_report_date range' onClick={() => { calendarRef.current.setOpen(true) }}>
              From: {moment(startDate).format('MM-DD-YYYY')}
              <CalendarIcon />
            </div>
          }
        />
        <DatePicker
          ref={calendarRef}
          selected={endDate}
          onChange={async (date) => {
            setEndDate(date);
            const start_date = moment(startDate).format('MM-DD-YYYY');
            const end_date = moment(date).format('MM-DD-YYYY')
            const range_param = `startDate=${start_date}&endDate=${end_date}`;
            await stringData(range_param);
          }}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          withPortal
          customInput={
            <div className='overview_kit__summary_report_date range' onClick={() => { calendarRef.current.setOpen(true) }}>
              To: {moment(endDate).format('MM-DD-YYYY')}
              <CalendarIcon />
            </div>
          }
        />
      </div>
    </div>
  )
}

export default OverviewRangePicker;


const CalendarIcon = () => (
  <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="calendar">
      <path id="Vector" d="M17 3H3C1.89543 3 1 3.89543 1 5V19C1 20.1046 1.89543 21 3 21H17C18.1046 21 19 20.1046 19 19V5C19 3.89543 18.1046 3 17 3Z" stroke="#686868" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path id="Vector_2" d="M14 1V5" stroke="#686868" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path id="Vector_3" d="M6 1V5" stroke="#686868" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path id="Vector_4" d="M1 9H19" stroke="#686868" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </svg>
);
