import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react'
import shopSelectors from 'store/shop/selectors';
import { useSelector } from 'react-redux';
import BottomSheet, { useBottomSheetRef } from 'components/BottomSheet';
import useDebounce from 'utils/useDebounce';
import Empty from 'components/new-appointment/Empty';
import { ANYONE_APPOINTMENT } from 'constant';

export const ALL_STAFF_ID = 'ALL_STAFF_ID';

const StaffFilterInput = ({ onFilterWith = () => undefined }) => {
  const staffListRef = useRef();
  const bsRef = useBottomSheetRef();
  const [activeStaff, setStaff] = useState(null);

  const handleChooseStaff = (_staff) => {
    setStaff(_staff);
    onFilterWith(_staff);
    bsRef.current.close();
  };

  const handleSearch = (text) => {
    if (!staffListRef.current) return;
    staffListRef.current.search(text);
  };

  return (
    <div className='dropdown__staff_input_kit'>
      <button className='btn-input' onClick={() => bsRef.current.open()}>
        <span>{activeStaff?.name || 'All Staff'}</span>
        <IconArrow />
      </button>
      <BottomSheet ref={bsRef} title={'Filter Staff'}
        header={<InputSearch onDebounce={handleSearch} />}
      >
        <div className='dropdown__staff_input_container_bs'>

          <Staffs
            ref={staffListRef}
            selected={activeStaff}
            onChooseStaff={handleChooseStaff}
          />
        </div>
      </BottomSheet>
    </div>
  )
}

export default StaffFilterInput;

export const StaffBSPicker = forwardRef(({ }, ref) => {
  const staffListRef = useRef();
  const bsRef = useBottomSheetRef();
  const [activeStaff, setStaff] = useState(null);
  const callBackRef = useRef(() => undefined);

  const handleChooseStaff = (_staff) => {
    callBackRef.current(_staff);
    bsRef.current.close();
  };

  const handleSearch = (text) => {
    if (!staffListRef.current) return;
    staffListRef.current.search(text);
  };
  useImperativeHandle(ref, () => ({
    open: (staffId, cb) => {
      bsRef.current.open();
      setStaff(staffId ? { id: staffId } : null);
      callBackRef.current = cb;
    }
  }))
  return (
    <BottomSheet ref={bsRef} title={'Change Staff'}
      header={<InputSearch onDebounce={handleSearch} />}
    >
      <div className='dropdown__staff_input_container_bs'>
        <Staffs
          anyone
          ref={staffListRef}
          selected={activeStaff}
          onChooseStaff={handleChooseStaff}
        />
      </div>
    </BottomSheet>
  )
});
StaffBSPicker.displayName = 'StaffBSPicker';

const Staffs = forwardRef(({ onChooseStaff, selected, anyone = false }, ref) => {
  const data = useSelector(shopSelectors.staff);

  const [search, setSearch] = useState("");

  const staffs = useMemo(() => {
    const str = search?.trim()?.toLowerCase();
    if (!str) return data;
    return data.filter((o) =>
      String(o.name || "")
        ?.toLowerCase()
        ?.includes(str)
    );
  }, [data, search]);

  const handleChooseStaff = (_staff) => () => {
    onChooseStaff(_staff);
  };

  useImperativeHandle(ref, () => ({
    search: (text) => setSearch(text),
  }));

  return (
    <div className="dropdown__staffs list-items" style={{ padding: '8px 16px' }}>
      <Staff
        avatar={ANYONE_APPOINTMENT.avatar}
        name={anyone ? 'Anyone' : "All Staff"}
        active={!selected}
        onClick={handleChooseStaff(null)}
      />
      {staffs.length ? staffs.map((o) => (
        <Staff
          key={o.id}
          avatar={o.avatar}
          name={o.name}
          active={selected?.id === o.id}
          onClick={handleChooseStaff(o)}
        />
      )) : <Empty message="No found" />}
    </div>
  );
});

const InputSearch = ({ onDebounce }) => {
  const [text, onChange] = useDebounce("", onDebounce);
  return (
    <div className="search-box" style={{ marginTop: 10 }}>
      <Search />
      <input
        value={text}
        onChange={onChange}
        type="search"
        placeholder="Staff Name"
      />
    </div>
  );
};

const Staff = ({ avatar, name, active, onClick }) => {
  return (
    <button
      className={`dropdown__staff ${active ? "active" : ""}`}
      onClick={onClick}
    >
      <div className="outbox_staffAvatar">
        <img className="staffAvatar" src={avatar} alt={name} />
      </div>
      <p className='dropdown__staff_name'>{name}</p>
      {active && (
        <div className="tick">
          <TickIcon />
        </div>
      )}
    </button>
  );
};

const TickIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M9.72692 18C9.40676 18 9.10261 17.8661 8.8785 17.6317L4.34817 12.8936C3.88394 12.4081 3.88394 11.6045 4.34817 11.1189C4.8124 10.6334 5.58078 10.6334 6.04501 11.1189L9.72692 14.9697L17.955 6.36414C18.4192 5.87862 19.1876 5.87862 19.6518 6.36414C20.1161 6.84967 20.1161 7.6533 19.6518 8.13882L10.5753 17.6317C10.3512 17.8661 10.0471 18 9.72692 18Z" fill="#0C719C" />
  </svg>
);

const Search = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7_25711)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z"
        fill="#686868"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.487 17.9555C17.7796 17.6622 18.2544 17.6616 18.5477 17.9541L22.0717 21.469C22.365 21.7615 22.3656 22.2364 22.0731 22.5297C21.7806 22.8229 21.3057 22.8235 21.0125 22.531L17.4884 19.0162C17.1952 18.7236 17.1945 18.2488 17.487 17.9555Z"
        fill="#686868"
      />
    </g>
    <defs>
      <clipPath id="clip0_7_25711">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const IconArrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.29289 18.7071C7.90237 18.3166 7.90237 17.6834 8.29289 17.2929L13.5858 12L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071Z" fill="#686868" />
  </svg>
);
