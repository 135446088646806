import ContentContainer from "components/new-appointment/ContentContainer";
import Header from "components/new-appointment/Header";
import Title from "components/new-appointment/Title";
import { ANYONE_APPOINTMENT, NEW_APT_STEPS } from "constant";
import React, { useEffect, useMemo, useState } from "react";
import { useAptContext } from "./hooks/index.context";
import { getTimeSlots } from "./hooks/index.data";
import moment from "moment";
import Loader from "components/Loader";
import { fi } from "date-fns/locale";

const TimeSlot = (props) => {
  const context = useAptContext();
  const [timeSlot, setTimeSlot] = useState(context.state.timeSlot);
  const [timeSlots, setTimeSlots] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchTimeSlots = async () => {
    const staffId = context.state.selectedStaff?.id
      ? context.state.selectedStaff?.id
      : null;
    const date = context.state.selectedDate || new Date();
    const dateStr = moment(date).format("YYYY-MM-DD");
    setLoading(true);
    try {
      const _timeSlots = await getTimeSlots(
        staffId == ANYONE_APPOINTMENT.id ? null : staffId,
        dateStr
      );
      if (_timeSlots) {
        setTimeSlots(_timeSlots || []);
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchTimeSlots();
  }, []);

  const handleChooseTimeSlot = (time) => {
    setTimeSlot(time);
    context.actions.setTimeSlot(time);
  };

  const onNext = () => {
    context.actions.setTimeSlot(timeSlot);
    props.go(NEW_APT_STEPS.REVIEW);
  };
  return (
    <>
      <Header disabled={!timeSlot} onNext={onNext} />
      {loading && <Loader/>}
      <ContentContainer>
        <Title step={NEW_APT_STEPS.TIME_SLOT} />
        <TimeSlotsBlock
          selected={timeSlot}
          label={"Morning"}
          data={timeSlots.filter((o) => o.endsWith("AM"))}
          onChooseTimeSlot={handleChooseTimeSlot}
        />
        <TimeSlotsBlock
          selected={timeSlot}
          label={"Afternoon"}
          data={timeSlots.filter((o) => o.endsWith("PM"))}
          onChooseTimeSlot={handleChooseTimeSlot}
        />
      </ContentContainer>
    </>
  );
};

export default TimeSlot;

const TimeSlotsBlock = ({ data, selected, label, onChooseTimeSlot }) => {
  if (!data?.length) return null;

  return (
    <div className="new_appointment__time_slots_block">
      <p className="new_appointment__time_slots_label">{label}</p>
      <div className="new_appointment__time_slots">
        {data.map((o) => (
          <button
            type="button"
            key={o}
            className={`new_appointment__time_slot ${
              selected === o ? "active" : ""
            }`}
            onClick={() => onChooseTimeSlot(o)}
          >
            <p>{o}</p>
          </button>
        ))}
      </div>
    </div>
  );
};
