import React from 'react'
import { useHistory } from 'react-router-dom';
import { stateNewAppointmentRef } from 'views/new-appointment';

const Header = ({ back = true, onBack, onNext, onBook, onDone, disabled = false }) => {
  const _history = useHistory();
  const isBackToReview = stateNewAppointmentRef.current ? stateNewAppointmentRef.current.isBackToReview : false;

  const _handleBack = () => {
    if (isBackToReview) {
      stateNewAppointmentRef.current.isBackToReview = false;
      _history.push('/new-appointment/' + 'review', undefined);
      return;
    }
    if (onBack) return onBack();
    _history.goBack();
  };

  const Container = ({ children }) => (
    <div className='new_appointment__header'>
      <p className="title">BOOKING</p>
      <div className='actions'>{children}</div>
    </div>
  );

  const BackButton = () => <button className="button btn-back" type='button' onClick={_handleBack}>BACK</button>;
  const NextButton = () => onNext ? <button disabled={disabled} className="button btn-next" type='button' onClick={onNext}>NEXT</button> : null;
  const BookButton = () => onBook ? <button disabled={disabled} className="button btn-next btn-book" type='button' onClick={onBook}>BOOK</button> : null;
  const DoneButton = () => onDone ? <button disabled={disabled} className="button btn-next btn-done" type='button' onClick={onDone}>DONE</button> : null;

  if (isBackToReview) {
    return (
      <Container>
        <BackButton />
        <NextButton />
      </Container>
    )
  }

  return (
    <Container>
      {back && <BackButton />}
      <NextButton />
      <BookButton />
      <DoneButton />
    </Container>
  )
}

export default Header;
