import React from 'react';

const Percent = ({ isLarge, data }) => {
  return (
    <div className='top-percent-styled'>
      <div className={`money-box ${isLarge ? 'isLarge' : ''}`}>{data.discountValue}</div>
      <div className={`money-content  ${isLarge ? 'isLarge' : ''}`}>
        <div className="top">
          <span className='money-suffix'>%</span>
          <span className='money-off'>OFF</span>
        </div>
        <div className="coupon-name">{data.couponName}</div>
      </div>
    </div>
  );
};

export default Percent;
