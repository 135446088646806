import React from 'react'
import MaskedInput from 'react-text-mask';

const InputPhone = (props) => {
  return (
    <MaskedInput
      {...props}
      className={'phone_input ' + props.className}
      mask={
        ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
      }
      placeholder='(000) 000-0000'
    />
  )
};

export default InputPhone;
