import Header from "components/new-appointment/Header";
import React from "react";
import { useAptContext } from "./hooks/index.context";
import moment from "moment";
import { formatCurrency } from "utils/formatCurrency";
import { NEW_APT_STEPS } from "constant";

const Success = (props) => {
  const context = useAptContext();
  const {
    selectedStaff,
    selectedDate,
    timeSlot,
    selectedServices = [],
    isRequestStaff,
    note,
  } = context.state;
  const dateMoment = moment(selectedDate || new Date());
  const onDone = () => props.home();

  const makeNewApt = () => {
    context.actions.reset();
    props.go(NEW_APT_STEPS.INFORMATION);
  };

  return (
    <div>
      <Header onDone={onDone} back={false} />
      <div className="booking_success">
        <div className="booking_success_header">
          <span>Done</span>
          <p>THANK FOR YOUR BOOKING</p>
          <SuccessIcon />
          <p className="date">
            {dateMoment.format("ddd, MMM, DD, YYYY")} {timeSlot}
          </p>
        </div>

        <div className="d-flex justify-content-between align-items-center booking_success__staff_info">
          <div className="d-flex align-items-center">
            <p>Staff </p>
            {isRequestStaff && <span>request</span>}
          </div>
          <div className="d-flex align-items-center">
            <img
              style={{ width: "40px", height: "40px", borderRadius: "100%", backgroundColor:"#979797", marginRight: 5, objectFit: 'cover' }}
              src={selectedStaff?.avatar}
              alt={selectedStaff?.name}
            />
            <p>{selectedStaff?.name}</p>
          </div>
        </div>
        {selectedServices.map((o) => (
          <div
            key={o.id}
            className="d-flex align-items-center justify-content-between booking_success__services_row"
          >
            <p>{o.name}</p>
            <span>{formatCurrency(o.price)}</span>
          </div>
        ))}
        <p className="booking_success__note_title">Notes</p>
        <p className="booking_success__note">{note}</p>
        <button onClick={makeNewApt}>BOOK ANOTHER APPOINTMENT</button>
      </div>
    </div>
  );
};

export default Success;

const SuccessIcon = () => (
  <svg
    width="90"
    height="90"
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45 7.5C24.3375 7.5 7.5 24.3375 7.5 45C7.5 65.6625 24.3375 82.5 45 82.5C65.6625 82.5 82.5 65.6625 82.5 45C82.5 24.3375 65.6625 7.5 45 7.5ZM62.925 36.375L41.6625 57.6375C41.1375 58.1625 40.425 58.4625 39.675 58.4625C38.925 58.4625 38.2125 58.1625 37.6875 57.6375L27.075 47.025C25.9875 45.9375 25.9875 44.1375 27.075 43.05C28.1625 41.9625 29.9625 41.9625 31.05 43.05L39.675 51.675L58.95 32.4C60.0375 31.3125 61.8375 31.3125 62.925 32.4C64.0125 33.4875 64.0125 35.25 62.925 36.375Z"
      fill="#0C719C"
    />
    <path
      d="M39.3173 58.6842C38.5169 58.6842 37.7565 58.364 37.1962 57.8038L25.8704 46.4782C24.7099 45.3176 24.7099 43.3966 25.8704 42.2361C27.031 41.0755 28.952 41.0755 30.1125 42.2361L39.3173 51.4406L59.8875 30.8704C61.048 29.7099 62.969 29.7099 64.1296 30.8704C65.2901 32.031 65.2901 33.952 64.1296 35.1125L41.4383 57.8038C40.8781 58.364 40.1177 58.6842 39.3173 58.6842Z"
      fill="white"
    />
  </svg>
);
