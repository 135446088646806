import { API } from 'common';

const getCategories = () => API.get(`service/category/list`);

const getServices = () => API.get(`service/list`);

const getStaffs = () => API.get(`staff/list`);

const shopInfo = () => API.get('shop/detail');

const shopApis = {
  getStaffs,
  getCategories,
  getServices,
  shopInfo,
};

export default shopApis;
