import React from 'react'
import MultiAppointment from './MultiAppointment';
import AppointmentItem from './AppointmentItem';
import { get } from 'lodash';
import BlockHourItem from './BlockHourItem';

const CalendarItem = ({ data = [], headerData, disabled, onViewAppointmentDetail }) => {
  const aptList = data.filter(o => o.type === 'apt');
  const blockHoursList = data.filter(o => o.type === 'block-hour');
  if (blockHoursList.length) {
    return <div className='calendar-item'>
      <BlockHourItem
        headerData={headerData}
        data={get(blockHoursList, [0])}
      />
    </div>
  }
  if (aptList.length) {
    return <div className='calendar-item'>
      {(aptList.length > 1 ?
        <MultiAppointment
          headerData={headerData}
          data={aptList}
          onViewAppointmentDetail={onViewAppointmentDetail}
        /> : <AppointmentItem
          single
          disabled={disabled}
          headerData={headerData}
          data={get(aptList, [0])}
          onViewDetail={onViewAppointmentDetail}
        />)}
    </div>
  }
  return null;
}

export default CalendarItem;
