import BottomSheet, { useBottomSheetRef } from 'components/BottomSheet';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Button, Input } from 'reactstrap';

const ModalReason = forwardRef(({ }, ref) => {
  const bsRef = useBottomSheetRef();
  const callBackRef = useRef(() => undefined);
  const [reason, setReason] = React.useState('');

  const handleDone = () => {
    callBackRef.current(reason);
  }

  useImperativeHandle(ref, () => ({
    open: (cb) => {
      bsRef.current.open();
      callBackRef.current = cb;
      setReason('');
    }
  }));

  return (
    <BottomSheet ref={bsRef} title={'Reason'} maxHeightRatio={0.6}
      onOK={handleDone}
      className="new-appointment-wrapper new-appointment-wrapper-v2"
    >
      <div className='modal-reason-container'>
        <Input placeholder='Please enter reason' type='textarea' value={reason} onChange={e => setReason(e.target.value)} />
        <div className="suggestions">
          <Button size={'sm'} onClick={() => setReason('Busy')} className="suggestion-item">Busy</Button>
          <Button size={'sm'} onClick={() => setReason('Forgot')} className="suggestion-item">Forgot</Button>
          <Button size={'sm'} onClick={() => setReason('Sick')} className="suggestion-item">Sick</Button>
          <Button size={'sm'} onClick={() => setReason('Others')} className="suggestion-item">Others</Button>
        </div>
      </div>
    </BottomSheet >
  )
});
ModalReason.displayName = 'ModalReason';

export default ModalReason;
