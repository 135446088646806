import Calendar from "components/new-appointment/Calendar";
import { ANYONE_APPOINTMENT } from "constant";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getTimeSlots } from "../hooks/index.data";

const DateTimeTab = ({ staffId, date, setDate, timeSlot, setTimeSlot }) => {
  const [timeSlots, setTimeSlots] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const fetchTimeSlots = async (_date, _staffId) => {
    const dateStr = moment(_date).format("YYYY-MM-DD");
    setLoading(true);
    try {
      const _timeSlots = await getTimeSlots(
        _staffId == ANYONE_APPOINTMENT.id ? null : _staffId,
        dateStr
      );
      if (_timeSlots) {
        setTimeSlots(_timeSlots || []);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { fetchTimeSlots(date, staffId); }, [date, staffId]);

  const handleChooseTimeSlot = (time) => setTimeSlot(time);

  const TimeBox = () => {
    if (loading)
      return (
        <div className="d-flex align-items-center justify-content-center pt-4"><div className="spinner small-size"></div></div>
      );

    if (!timeSlots?.length) return <EmptyBox />;

    return (
      <>
        <TimeSlotsBlock
          selected={timeSlot}
          label={"Morning"}
          data={timeSlots.filter((o) => o.endsWith("AM"))}
          onChooseTimeSlot={handleChooseTimeSlot}
        />
        <TimeSlotsBlock
          selected={timeSlot}
          label={"Afternoon"}
          data={timeSlots.filter((o) => o.endsWith("PM"))}
          onChooseTimeSlot={handleChooseTimeSlot}
        />
      </>
    )
  }

  return (
    <div className='container-box'>
      <div className="text-label">SELECT TIME</div>
      <Calendar value={date} onChange={setDate} />
      <TimeBox />
    </div>
  );
};

export default DateTimeTab;

export const TimeSlotsBlock = ({ data, selected, label, onChooseTimeSlot }) => {
  if (!data?.length) return null;

  return (
    <div className="new_appointment__time_slots_block">
      <p className="new_appointment__time_slots_label">{label}</p>
      <div className="new_appointment__time_slots">
        {data.map((o) => (
          <button
            type="button"
            key={o}
            className={`new_appointment__time_slot ${selected === o ? "active" : ""
              }`}
            onClick={() => onChooseTimeSlot(o)}
          >
            <p>{o}</p>
          </button>
        ))}
      </div>
    </div>
  );
};

export const EmptyBox = () => (
  <div className='empty-data-box'>
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
        <g clipPath="url(#clip0_464_7322)">
          <path d="M66.8438 33.375H13.1641C12.1719 33.375 11.2578 33.9141 10.7734 34.7812L0.34375 53.4922C0.117188 53.8984 0 54.3594 0 54.8281V77.2578C0 78.7734 1.22656 80 2.74219 80H77.2656C78.7812 80 80.0078 78.7734 80.0078 77.2578V54.8281C80.0078 54.3594 79.8906 53.9063 79.6641 53.4922L69.2344 34.7812C68.75 33.9141 67.8359 33.375 66.8438 33.375ZM52.6094 54.3438C51.3203 54.3438 50.2031 55.2422 49.9297 56.5078C48.9375 61.0781 44.8672 64.5 39.9922 64.5C35.1172 64.5 31.0469 61.0781 30.0547 56.5078C29.7812 55.25 28.6641 54.3438 27.375 54.3438H9.29688C7.23438 54.3438 5.91406 52.1563 6.86719 50.3281L13.25 38.1562C13.7266 37.25 14.6562 36.6875 15.6797 36.6875H64.3047C65.3203 36.6875 66.2578 37.25 66.7344 38.1562L73.1172 50.3281C74.0703 52.1563 72.75 54.3438 70.6875 54.3438H52.6094ZM64.0547 16.3672C63.9766 16.2266 63.8906 16.0781 63.8047 15.9375C63.0391 14.6406 61.375 14.2031 60.0703 14.9531L50.6641 20.3906C49.3359 21.1562 48.8906 22.8594 49.6797 24.1719C49.75 24.2812 49.8125 24.3984 49.875 24.5156C50.6172 25.8437 52.3125 26.2969 53.625 25.5312L63.0469 20.1016C64.3516 19.3437 64.7969 17.6797 64.0547 16.3672ZM29.3281 20.3984L19.9219 14.9688C18.6172 14.2188 16.9531 14.6562 16.1875 15.9531C16.1016 16.0937 16.0234 16.2344 15.9375 16.3828C15.1953 17.6953 15.6406 19.3594 16.9453 20.1172L26.3516 25.5469C27.6719 26.3047 29.3672 25.8594 30.1016 24.5234C30.1641 24.4062 30.2344 24.2969 30.2969 24.1797C31.0937 22.8672 30.6484 21.1641 29.3281 20.3984ZM40 18.6953H40.1953C41.7188 18.7188 42.9688 17.4844 42.9688 15.9531V5.09375C42.9688 3.58594 41.75 2.36719 40.25 2.35156H39.75C38.25 2.36719 37.0312 3.59375 37.0312 5.09375V15.9531C37.0312 17.4766 38.2734 18.7188 39.8047 18.6953H40Z" fill="#CECECE" />
        </g>
        <defs>
          <clipPath id="clip0_464_7322">
            <rect width="80" height="80" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <div className='text-empty-data'>No Data</div>
    </div>
  </div>
)
