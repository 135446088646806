import moment from "moment";
import { ANYONE_ID } from "./CalendarUI/anyone";

const mappingAptCalendarData = (appointmentList = []) => appointmentList.map(o => {
  const startTime = moment(o.start_time, 'MM/DD/YYYY HH:mm A');
  let endTime = moment(o.end_time, 'MM/DD/YYYY HH:mm A');
  if (o.start_time === o.end_time) {
    endTime = startTime.clone().add(15, 'minute');
  }
  return ({
    id: o.id?.toString(),
    type: 'apt',
    data: {
      id: o.id?.toString(),
      customerId: o.customerId?.toString(),
      customerName: o.customerName,
      customerPhone: o.customerPhone,
      services: o.services.map(s => ({
        duration: s.duration,
        serviceId: s.id.toString(),
        serviceName: s.serviceName,
      })),
      sourceType: o.bookingType,
      staffId: o.staffId?.toString(),
      startTime: startTime.format('MM-DD-YYYY HH:mm:ss'),
      endTime: endTime.format('MM-DD-YYYY HH:mm:ss'),
      note: o.note || '',
      status: o.status,
      isAnybody: !!o.isAnybody,
      requestStaff: o.requestStaff ?? false,
      confirmed: o.strStatus === 'CONFIRMED',
    },
    staffId: (!o.staffId || o.staffId === 0) ? ANYONE_ID : o.staffId.toString(),
    startTime: startTime.format('MM-DD-YYYY HH:mm:ss'),
    endTime: endTime.format('MM-DD-YYYY HH:mm:ss'),
  })
});

export default mappingAptCalendarData;
