import React, { useState, useEffect } from 'react';

// reactstrap components
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'reactstrap';
import { API } from 'common';
import constant from 'constant';
import BG1 from '../assets/img/ticket/BG1.png';
import BG2 from '../assets/img/ticket/BG2.png';

export default function ReviewInformation() {
	const [reviewsList, setReviewsList] = useState([]);

	const fetch_ticket_detail = async () => {
		try {
			let response = await API.get(`admin/getListReviewStaff`);
			if (response.status == 200) {
				let { payload } = response.data;
				console.log(payload);
				if (payload.length > 0) {
					setReviewsList(payload);
				}
			}
		} catch (error) {
			console.error('fetch_ticket_detail', error);
		}
	}

	useEffect(() => {
		fetch_ticket_detail();
	}, [])

	return (
		<>
			<Helmet title="Ticket Details" />
			<Container style={{ backgroundColor: '#F0F4FA' }}>
				{reviewsList && reviewsList.map((item, index) => {
					let bg_style = {
						background: `url(${BG2}) no-repeat`,
						backgroundPosition: 'center',
						backgroundSize: 'cover'
					}
					if (item.feedBack < 0 || item.feedBack > 2) {
						bg_style.background = `url(${BG1}) no-repeat`;
					}
					return (
						<Row className={`m-0 pt-3  ticket-customer ${reviewsList.length -1 == index ? 'pb-3' : ''}` } key={index}>
							<Col xs={12} className="customer-info pt-3 d-flex" style={bg_style}>
								<div className="info">
									<div className="name">{item.staffName && item.staffName}</div>
									<div className="type">03/01/2019 12:50:35 PM</div>
								</div>
								<div className="avatar" style={{ backgroundColor: '#fff'}}>
									<img
										style={{padding: '0 3px'}}
										height={40}
										width={40}
										className="rounded-circle float-left"
										alt="..."
										src={require('assets/img/theme/team-4-800x800.jpg')}
									/>
								</div>
							</Col>
							<Col xs={12} className="customer-feedback feedback-border pt-3">
								<div style={{ fontSize: "17px", fontWeight: "600" }}>Feedback from customer</div>
								<div className="smile d-flex text-center mt-2">
									{
										constant.SMILE_LIST.map((smile, index) => {
											let img_style = 'none';
											let text_style = 'none';
											if (item && item.feedBack != (index + 1)) {
												img_style = "#FFDA71";
												text_style = "#FFBD00";
											}
											return (
												<div className="item-smile" key={index}>
													<img
														style={{ backgroundColor: `${img_style}` }}
														height={40}
														width={40}
														alt="..."
														src={require(`assets/img/icons/${smile.file_name}`)}
													/>
													<div className="status" style={{ color: `${text_style}` }}>{smile.label}</div>
												</div>
											)
										})
									}
								</div>
								<div style={{ fontWeight: "600", fontSize: "15px", margin: "12px 0", color: "#3c3c3c", opacity: '0.9' }}>Staff professional and pay attention</div>
							</Col>
						</Row>
					)
				})}
			</Container>
		</>
	);
}
