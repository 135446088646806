import React, { useEffect, useState } from "react";
import useNewAptReducer from "./index.reducer";
import useFetchBookingData from "./index.data";
import shopSelectors from "store/shop/selectors";

const useNewAppointmentHook = () => {
  const [loading, setLoading] = useState(true);
  const reducer = useNewAptReducer();

  return ({
    context: { state: reducer.state, actions: reducer.actions },
    loading,
  })
};

export default useNewAppointmentHook;
