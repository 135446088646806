import numeral from 'numeral';

export const formatCurrency = (
  value,
  showMinus,
  showMoney = true
) => {
  const formatString = showMoney ? '$0,0.00' : '0,0.00';

  if (showMinus && Number(value) < 0) {
    return `- ${numeral(value || 0).format(formatString)}`;
  }

  return numeral(value || 0).format(formatString);
};

export const formatNumber = (value) => {
  return numeral(value || 0).format('0,0');
};
