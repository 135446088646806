import classnames from 'classnames';
import { getDataCookie } from 'common';
import Loader from 'components/Loader';
import Page from 'components/Page';
import { ANYONE_APPOINTMENT, NEW_APT_STEPS } from 'constant';
import Cookies from 'js-cookie';
import { sumBy } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import shopSelectors from 'store/shop/selectors';
import { makeAppointment } from '../hooks/index.data';
import CustomerTab from './CustomerTab';
import DateTimeTab from './DateTimeTab';
import Header from './Header';
import ReviewTab from './ReviewTab';
import ServicesTab from './ServicesTab';
import StaffTab from './StaffTab';
import SuccessTab from './SuccessTab';
import { toastRef } from 'components/Toast';
import ShopHeader from './ShopHeader';

const TABS = {
  CUSTOMER: 'CUSTOMER',
  SERVICES: 'SERVICES',
  STAFF: 'STAFF',
  TIME: 'TIME',
}

const SCREENS = {
  BOOK: 'BOOK',
  REVIEW: 'REVIEW',
  SUCCESS: 'SUCCESS',
};
const BookingPage = (props) => {

  const isManager = props.bookingOnline ? true : !!+Cookies.get('isManager');
  const _history = useHistory();
  const params = useMemo(() => {
    if (!window.location.search) return null;
    const data = new URLSearchParams(window.location.search);
    return ({
      time_slot: data.get('time_slot').replace('_', ' '),
      staff_id: data.get('staff_id'),
    })
  }, []);

  const staffList = useSelector(shopSelectors.staff);
  const customerTabRef = useRef(null);
  const reviewTabRef = useRef(null);

  const [activeTab, setActiveTab] = React.useState(TABS.CUSTOMER);
  const [screen, setScreen] = useState(SCREENS.BOOK);
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [coupon, setCoupon] = useState('');
  const [customer, setCustomer] = useState(null);
  const [services, setServices] = useState([]);
  const [date, setDate] = useState(new Date());
  const [timeSlot, setTimeSlot] = useState(params?.time_slot);
  const [_staff, setStaff] = useState(staffList.find(o => o.id === params?.staff_id));
  const [isRequestStaff, setRequestStaff] = useState(false);
  const [note, setNote] = useState('');
  const [group, setGroupValue] = useState(1);
  const [loading, setLoading] = useState(false);
  const isRollBackToReview = useRef(false);
  const validTab = useRef({
    customer: false,
    service: false,
    staff: !isManager ? true : !!_staff,
    time: !!timeSlot,
  });
  const validForm = Object.values(validTab.current).every(o => o);

  const makeNewApt = () => {
    setActiveTab(TABS.CUSTOMER);
    setScreen(SCREENS.BOOK);
    setPhone('');
    setName('');
    setCoupon('');
    setCustomer(null);
    setServices([]);
    setDate(new Date());
    setTimeSlot('');
    setStaff();
    setRequestStaff(false);
    setNote('');
    setGroupValue(1);
    setLoading(false);
    isRollBackToReview.current = false;
    validTab.current = {
      customer: false, service: false, staff: !isManager, time: false,
    };
    if (customerTabRef.current) customerTabRef.current.reset();
    if (reviewTabRef.current) reviewTabRef.current.reset();
  };

  const _customer = (() => ({
    phone,
    name,
    id: customer?.id || null,
  }))();

  const staff = useMemo(() => {
    if (!isManager) {
      const staff_info = JSON.parse(localStorage.getItem('infor_staff'));
      const staff = staffList?.find(o => o.id === staff_info?.id?.toString());
      return staff;
    }
    return _staff;
  }, [staffList, _staff]);


  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const onBook = async () => {
    const dateMoment = moment(date);
    const time = `${dateMoment.format("MM-DD-YYYY")} ${timeSlot}`;
    const timeMoment = moment(time, "MM-DD-YYYY hh:mm A");

    const totalDuration = sumBy(services, (o) => o.duration || 0);

    const startTime = timeMoment?.format("MM/DD/YYYY HH:mm") || "";
    const endTime =
      timeMoment
        ?.clone()
        .add(totalDuration, "minute")
        ?.format("MM/DD/YYYY HH:mm") || "";
    const payload = {
      customerId: _customer.id,
      customerName: _customer?.name || "",
      phone: _customer?.phone || "",
      bookingType: 1,
      serviceIds: services.map((o) => +o.id),
      staffId:
        staff.id === ANYONE_APPOINTMENT.id
          ? null
          : +(staff.id || ""),
      totalPeople: group,
      startTime,
      endTime,
      note,
      isAnybody: staff.id === ANYONE_APPOINTMENT.id ? 1 : 0,
      apiKey: getDataCookie(process.env.REACT_APP_API_X_KEY),
      requestStaff: !isManager ? true : isRequestStaff,
      couponCode: coupon?.couponCode || "",
      id: null,
    };
    setLoading(true);
    try {
      const res = await makeAppointment(payload);
      if (res.message) {
        if (toastRef.current) toastRef.current.show(res.message);
        return console.log('FAIL: ' + res.message);
      }
      setScreen(SCREENS.SUCCESS);
    } catch (error) { } finally {
      setLoading(false);
    }
  };

  const goBack = () => {
    const customPrevPath = _history?.location?.state?.path;
    if (customPrevPath) {
      _history.push(customPrevPath);
      return;
    }
    _history.push('/');
  };

  const rollbackTo = (step) => {
    isRollBackToReview.current = true;
    setScreen(SCREENS.BOOK);
    switch (step) {
      case NEW_APT_STEPS.SERVICE: return setActiveTab(TABS.SERVICES);
      case NEW_APT_STEPS.DATE_STAFF: return setActiveTab(TABS.STAFF);
      case NEW_APT_STEPS.TIME_SLOT: return setActiveTab(TABS.TIME);
      case NEW_APT_STEPS.INFORMATION: return setActiveTab(TABS.CUSTOMER);
      default: return '';
    }
  };

  useEffect(() => {
    if (screen === SCREENS.REVIEW) {
      isRollBackToReview.current = false;
    }
  }, [screen]);

  const disabled = (() => {
    switch (activeTab) {
      case TABS.CUSTOMER: return !phone || !name;
      case TABS.SERVICES: return !services?.length;
      case TABS.STAFF: return !staff?.id;
      case TABS.TIME: return !timeSlot;
      default:
        break;
    }
  })();

  const onNext = () => {
    if (isRollBackToReview.current) return setScreen(SCREENS.REVIEW);
    switch (activeTab) {
      case TABS.CUSTOMER: {
        if (!phone || !name) return;
        validTab.current.customer = true;
        setActiveTab(TABS.SERVICES);
        break;
      }
      case TABS.SERVICES: {
        if (!services?.length) return;
        validTab.current.service = true;
        if (!isManager) return setActiveTab(TABS.TIME);
        setActiveTab(TABS.STAFF);
        break;
      }
      case TABS.STAFF: {
        if (!staff?.id) return;
        validTab.current.staff = true;
        setActiveTab(TABS.TIME);
        break;
      }
      case TABS.TIME: {
        if (!timeSlot) return;
        validTab.current.time = true;
        setScreen(SCREENS.REVIEW);
        break;
      }
      default:
        break;
    }
  };

  const StickyHeader = () => {
    return (
      <div>
        {screen === SCREENS.BOOK && (
          validForm ?
            <Header onBack={goBack} onReview={() => setScreen(SCREENS.REVIEW)} /> :
            <Header disabled={disabled} onBack={goBack} onNext={onNext} />
        )}
        {screen === SCREENS.REVIEW && <Header onBack={() => setScreen(SCREENS.BOOK)} onBook={onBook} />}
        {screen === SCREENS.SUCCESS && (!props.bookingOnline ? <Header back={false} onDone={goBack} /> : <Header back={false} />)}
        {screen === SCREENS.BOOK && (
          <div>
            {!!props.bookingOnline && <ShopHeader />}
            <Nav tabs className="overview-custom-nav custom-nav-light">
              <NavItemLink
                label={'Customer'}
                active={activeTab === TABS.CUSTOMER}
                onClick={() => toggle(TABS.CUSTOMER)}
                success={validTab.current.customer}
                style={!isManager ? { flex: 1 } : { flex: 0.9 }}
              />
              <NavItemLink
                label={'Services'}
                active={activeTab === TABS.SERVICES}
                onClick={() => toggle(TABS.SERVICES)}
                success={validTab.current.service}
                style={!isManager ? { flex: 1 } : { flex: 0.9 }}
              />
              {isManager && (
                <NavItemLink
                  label={'Staff'}
                  active={activeTab === TABS.STAFF}
                  onClick={() => toggle(TABS.STAFF)}
                  success={validTab.current.staff}
                  style={{ flex: 0.7 }}
                />
              )}
              <NavItemLink
                label={'Time'}
                active={activeTab === TABS.TIME}
                onClick={() => toggle(TABS.TIME)}
                success={validTab.current.time}
                style={!isManager ? { flex: 1 } : { flex: 0.7 }}
              />
            </Nav>
          </div>
        )}
      </div>
    );
  };

  return (
    <Page containerClass={'new-appointment-wrapper new-appointment-wrapper-v2'} noneHeader Sticky={StickyHeader}>
      <TabContent activeTab={screen} >
        <TabPane tabId={SCREENS.BOOK}>
          <TabContent activeTab={activeTab} style={{ color: "#000000", backgroundColor: '#F3F3F3' }}>
            <TabPane tabId={TABS.CUSTOMER}>
              <CustomerTab
                ref={customerTabRef}
                phone={phone} setPhone={setPhone}
                name={name} setName={setName}
                coupon={coupon} setCoupon={setCoupon}
                customer={customer} setCustomer={setCustomer}
              />
            </TabPane>
            <TabPane tabId={TABS.SERVICES}>
              <ServicesTab
                services={services} setServices={setServices}
              />
            </TabPane>
            {isManager ? <TabPane tabId={TABS.STAFF}>
              <StaffTab
                staff={staff} setStaff={setStaff}
                isRequestStaff={isRequestStaff} setRequestStaff={setRequestStaff}
              />
            </TabPane> : null}
            <TabPane tabId={TABS.TIME}>
              <DateTimeTab
                staffId={staff?.id || ''}
                date={date} setDate={setDate}
                timeSlot={timeSlot} setTimeSlot={setTimeSlot}
              />
            </TabPane>
          </TabContent>
        </TabPane>
        <TabPane tabId={SCREENS.REVIEW}>
          <div className='container-box'>
            <div className="text-label mb-4">REVIEW</div>
            <ReviewTab
              ref={reviewTabRef}
              note={note} setNote={setNote}
              group={group} setGroupValue={setGroupValue}
              coupon={coupon}
              isRequestStaff={isRequestStaff}
              selectedServices={services}
              timeSlot={timeSlot}
              selectedDate={date}
              customer={_customer}
              selectedStaff={staff}
              rollbackTo={rollbackTo}
              isManager={isManager}
            />
          </div>
        </TabPane>
        <TabPane tabId={SCREENS.SUCCESS}>
          <SuccessTab
            selectedStaff={staff}
            selectedDate={date}
            timeSlot={timeSlot}
            selectedServices={services}
            isRequestStaff={isRequestStaff}
            note={note}
            makeNewApt={makeNewApt}
          />
        </TabPane>
      </TabContent>

      {loading && <Loader />}
    </Page>
  )
}

export const getBookingRoute = () => ({
  path: '/booking',
  name: 'New Appointment',
  component: BookingPage,
  layout: 'admin',
  isDisplayHeader: false,
  isDisplayNav: false,
  isManager: !!+Cookies.get('isManager'),
});

export default BookingPage;

const NavItemLink = ({ label, active, onClick, success, style }) => (
  <NavItem className="tab-item" style={style}>
    <NavLink
      className={classnames({ active, success })}
      onClick={onClick}
      disabled={!success}
    >
      {label}
      {success &&
        <div className='icon-tick-placeholder'>
          <div className="icon-tick"><IconCheck /></div>
        </div>}
    </NavLink>
  </NavItem >
);

const IconCheck = () => (<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M9.46669 18.4999C9.10981 18.4999 8.77078 18.3572 8.52097 18.1074L3.47111 13.0576C2.95364 12.5401 2.95364 11.6836 3.47111 11.1662C3.98858 10.6487 4.84508 10.6487 5.36254 11.1662L9.46669 15.2702L18.6384 6.09855C19.1558 5.58108 20.0123 5.58108 20.5298 6.09855C21.0473 6.61602 21.0473 7.47251 20.5298 7.98998L10.4124 18.1074C10.1626 18.3572 9.82356 18.4999 9.46669 18.4999Z" fill="#0085E7" /> </svg>);