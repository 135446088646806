import React, { useEffect, useState } from 'react';

// reactstrap components
// import { Container, Row, Col, Table, Progress } from 'reactstrap';
import classnames from 'classnames';
import { API } from 'common';
import MyDatePicker from 'components/DatePicker';
import MyDateRangePicker from 'components/DatePicker/Range';
import Loader from 'components/Loader';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import {
	Container,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane
} from 'reactstrap';

import Page from 'components/Page';
import { formatCurrency, formatNumber } from 'utils/formatCurrency';

const FORMAT_DATE = 'YYYY-MM-DD';

export default function SummaryReport() {
	const [activeTab, setActiveTab] = React.useState('DAY');
	const [dataList, setDataList] = useState([]);
	const [loading, setLoading] = useState(false);

	const toggle = (tab) => {
		let range_param = '';
		const now = new Date();
		switch (tab) {
			case 'WEEK': {
				const dateMoment = moment(now);
				const start_date = dateMoment.startOf('week').format(FORMAT_DATE);
				const end_date = dateMoment.endOf('week').format(FORMAT_DATE);
				range_param = `from=${start_date}&to=${end_date}`;
				break;
			}
			case 'MONTH': {
				const dateMoment = moment(now);
				const start_date = dateMoment.startOf('month').format(FORMAT_DATE);
				const end_date = dateMoment.endOf('month').format(FORMAT_DATE);
				range_param = `from=${start_date}&to=${end_date}`;
				break;
			}
			case 'DAY':
			default: {
				const dateMoment = moment(now).format(FORMAT_DATE);
				range_param = `from=${dateMoment}&to=${dateMoment}`;
				break;
			}
		}
		fetchData(range_param);
		if (activeTab !== tab) setActiveTab(tab);
	};

	const fetchData = async (range_param) => {
		setLoading(true);
		try {

			let response = await API.get(`report/reportShop?${range_param}`);

			if (response.status == 200) {
				let { payload } = response.data;
				if (payload) {
					setDataList(payload);
				}
			}
		} catch (error) {
			console.error('get_summary_report_data', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		toggle('DAY');
	}, [])

	const tab_pane_html = (
		<>
			<div className='summary_report_kit_info'>
				<div className='section_title'>SUMMARY</div>
				<div className="summary_report_kit_info_items">
					<InfoItem label={'Total tickets'} value={dataList?.totalTickets || 0} />
					<InfoItem label={'Services’ Sales'} value={formatCurrency(dataList.totalService)} />
					<InfoItem label={'Total Products'} value={formatCurrency(dataList?.totalProducts)} />
					<InfoItem label={'Total Gift Sold'} value={formatCurrency(dataList?.totalGiftSold)} />
					<InfoItem label={'Total Tips'} value={formatCurrency(dataList?.totalTip)} />
					<InfoItem label={'Owner’s Disc.'} value={formatCurrency(dataList?.totalDiscountOwner)} />
					<InfoItem label={'Employee’s Disc'} value={formatCurrency(dataList?.totalDiscountEmployee)} />
					<InfoItem label={'Total Refund / Voided'} value={formatCurrency((dataList?.totalRefund || 0) + (dataList?.totalVoided || 0))} />
					<InfoItem label={'Profit'} value={formatCurrency(dataList?.profit)} />
					<InfoItem label={'Loss'} value={formatCurrency(dataList?.loss)} />
				</div>
				<div className="section_divider" />
				<InfoItem label={'GROSS SALE'} className={'total section_spacing'} value={formatCurrency(dataList?.totalService)} />
				<div className='section_title'>PAYMENT TYPE</div>
				<div className="summary_report_kit_info_items section_spacing">
					<InfoItem label={'Credit Card Net Total'} value={formatCurrency(dataList?.creditCard)} />
					<InfoItem label={'Cash'} value={formatCurrency(dataList?.cash)} />
					<InfoItem label={'Gift Card'} value={formatCurrency(dataList.gitCard)} />
					<InfoItem label={'Check / Other'} value={formatCurrency(dataList.checkOther)} />
					<InfoItem label={'Reward'} value={formatNumber(dataList.rewardPoint)} />
				</div>

				<div className='section_title'>CREDIT CARD DETAIL</div>
				<div className="summary_report_kit_info_items section_spacing">
					<InfoItem label={'C.D Sales'} value={formatCurrency(dataList?.totalSalesCC)} />
					<InfoItem label={'Total Tips'} value={formatCurrency(dataList?.totalTip)} />
					<InfoItem label={'Total C.D Fees'} value={formatCurrency((dataList?.totalFees || 0) + (dataList?.totalCashDiscountTip || 0))} />
					<InfoItem label={'Total C.D Tip Fees (Owner)'} value={formatCurrency(dataList?.totalCashDiscountTip)} />
					<InfoItem label={'Refund / Voided'} value={formatCurrency(dataList?.totalRefund)} />
				</div>
				<div className="section_divider" />
				<InfoItem label={'TOTAL (NET)'} className={'total section_spacing'} value={formatCurrency(dataList?.total)} />
			</div>
		</>
	)

	const Header = () => (
		<Nav tabs className="overview-custom-nav custom-nav-light">
			<NavItem className="tab-item">
				<NavLink
					className={classnames({ active: activeTab === 'DAY' })}
					onClick={() => {
						toggle('DAY');
					}}
				>
					TODAY
				</NavLink>
			</NavItem>
			<NavItem className="tab-item">
				<NavLink
					className={classnames({ active: activeTab === 'WEEK' })}
					onClick={() => {
						toggle('WEEK');
					}}
				>
					WEEKLY
				</NavLink>
			</NavItem>
			<NavItem className="tab-item">
				<NavLink
					className={classnames({ active: activeTab === 'RANGE' })}
					onClick={() => {
						toggle('RANGE');
					}}
				>
					RANGE
				</NavLink>
			</NavItem>
		</Nav>
	);

	return (
		<>
			{loading && <Loader />}
			<Page menu title={'Summary Report'} Sticky={Header}>
				<Container className="overview m-0 p-0 summary_report_kit">
					<TabContent activeTab={activeTab} style={{ color: "#000000", backgroundColor: '#fff' }} className="pt-2">
						<TabPane tabId="DAY">
							<div className="summary_report_kit_container">
								<MyDatePicker
									daily
									unsetMaxDate
									stringData={fetchData}
									startFieldName='from'
									endFieldName='to'
									format={FORMAT_DATE}
								/>
								{tab_pane_html}
							</div>
						</TabPane>
						<TabPane tabId="WEEK">
							<div className="summary_report_kit_container">
								<MyDatePicker
									weekly
									unsetMaxDate
									stringData={fetchData}
									startFieldName='from'
									endFieldName='to'
									format={FORMAT_DATE}
								/>
								{tab_pane_html}
							</div>
						</TabPane>
						<TabPane tabId="RANGE">
							<div className="summary_report_kit_container">
								<MyDateRangePicker
									stringData={fetchData}
									startFieldName='from'
									endFieldName='to'
									format={FORMAT_DATE}
								/>
								{tab_pane_html}
							</div>
						</TabPane>
					</TabContent>
				</Container>
			</Page>
		</>
	);
}

const InfoItem = ({ label, value, className }) => {
	return (
		<div className={`summary_report_kit_info_item ${className}`}>
			<div className="summary_report_kit_info_item_label">{label}</div>
			<div className="summary_report_kit_info_item_divider" />
			<div className="summary_report_kit_info_item_value">{value}</div>
		</div>
	)
};