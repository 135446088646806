
export const NAME_REDUCER = 'shop';

export const PREFIX_ACTIONS = 'shop_feature_';


export const PATH_LOADING = {
  getData: `loading.${NAME_REDUCER}.getData`,
  staff: `loading.${NAME_REDUCER}.staff`,
  services: `loading.${NAME_REDUCER}.services`,
  shopLoading: `loading.${NAME_REDUCER}.shopLoading`,
};
