import React, { useCallback, useState } from 'react';
import debounce from 'lodash/debounce';

const useDebounce = (defaultValue = '', onDebounce, preventDebounce) => {
  const [text, setText] = useState(defaultValue);
  const _debounce = useCallback(debounce((_text) => {
    if (preventDebounce && preventDebounce(_text)) return;
    if (!onDebounce) return;
    onDebounce(_text);
  }, 200), []);
  const onChange = (e) => {
    const _text = e.target.value;
    setText(_text);
    _debounce(_text);
  };
  return [text, onChange, setText];
};

export default useDebounce;
