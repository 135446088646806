import Loader from 'components/Loader';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';
import { API } from 'common';
import MyDatePicker from 'components/DatePicker';
import MyDateRangePicker from 'components/DatePicker/Range';
import { useHistory } from 'react-router-dom';
import { formatCurrency } from 'utils/formatCurrency';
import { PrinterIcon, ProfitIcon, UsedIcon } from './icons';
import Page from 'components/Page';

const MarketingReport = () => {
  const _history = useHistory();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('DAY');

  const [dataList, setDataList] = useState(null);

  const toggle = (tab) => {
    let range_param = '';
    const now = new Date();
    switch (tab) {
      case 'WEEK': {
        const dateMoment = moment(now);
        const start_date = dateMoment.startOf('week').format('MM-DD-YYYY');
        const end_date = dateMoment.endOf('week').format('MM-DD-YYYY');
        range_param = `startDate=${start_date}&endDate=${end_date}`;
        break;
      }
      case 'MONTH': {
        const dateMoment = moment(now);
        const start_date = dateMoment.startOf('month').format('MM-DD-YYYY');
        const end_date = dateMoment.endOf('month').format('MM-DD-YYYY');
        range_param = `startDate=${start_date}&endDate=${end_date}`;
        break;
      }
      case 'DAY':
      default: {
        const dateMoment = moment(now).format('MM-DD-YYYY');
        range_param = `startDate=${dateMoment}&endDate=${dateMoment}`;
        break;
      }
    }
    fetchData(range_param);
    if (activeTab !== tab) setActiveTab(tab);
  };

  const fetchData = async (range_param) => {
    setLoading(true);
    try {
      let response = await API.get(`report/shop-day?type=FROM-TO&${range_param}`);
      if (response.status == 200) {
        let { payload } = response.data;
        if (payload) {
          setDataList(payload);
        }
      }
    } catch (error) {
      console.error('get_summary_report_data', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => toggle('DAY'), []);

  const navTo = (route) => {
    _history.push('/marketing-report' + route);
  };

  const ReviewSection = () => {
    return (
      <div className="marketing_report_kit__review_section">
        <div className="rating">
          <div className="d-flex align-items-center">
            <StarIcon />
            <span className="star_rating_label">4</span>
          </div>
          <span className="star_rating_counting">(100)</span>
          <div style={{ flex: 1 }} />
          <span className="review_label">Reviews & comments</span>
          <button type='button' onClick={() => navTo('/reviews-comments')} className='btn-nav'><ArrowIcon /></button>
        </div>
        <div className="summary_box_kit">
          <div className="summary_box_kit_info">
            <div className="section_title">GIFT CARD</div>
            <div className="summary_box_kit_info_items">
              <InfoItem label={'Gift Card sold'} value={formatCurrency(3000)} />
              <InfoItem label={'Gift Card redeem'} value={formatCurrency(1000)} />
              <InfoItem label={'Bought in store'} value={formatCurrency(1500)} />
              <InfoItem label={'Bought online'} value={formatCurrency(1500)} />
            </div>
          </div>
        </div>
        <div className="divider" />
        <div className="summary_box_kit">
          <div className="summary_box_kit_info">
            <div className="section_title">BOOKING</div>
            <div className="summary_box_kit_info_items">
              <InfoItem label={'Call-In'} value={100} />
              <InfoItem label={'Reminder booking'} value={formatCurrency(1000)} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  const Marketing = () => {
    return (
      <>
        <div className="section_title" style={{ marginBottom: '16px' }}>MARKETING</div>
        <div className="marketing_report_kit__sub_section_cards">
          <div className="marketing_report_kit__sub_section_cards">
            <Card label={'Coupon'} navTo={() => navTo('/coupon')} value={0} used={600} profit={100} />
            <Card label={'Email'} sms navTo={() => navTo('/email')} value={0} used={600} profit={100} />
            <Card label={'SMS'} sms navTo={() => navTo('/sms')} value={0} used={600} profit={100} />
          </div>
        </div>
      </>
    );
  };

  const Header = () => (
    <Nav tabs className="overview-custom-nav custom-nav-light">
      <NavItem className="tab-item">
        <NavLink
          className={classnames({ active: activeTab === 'DAY' })}
          onClick={() => {
            toggle('DAY');
          }}
        >
          TODAY
        </NavLink>
      </NavItem>
      <NavItem className="tab-item">
        <NavLink
          className={classnames({ active: activeTab === 'WEEK' })}
          onClick={() => {
            toggle('WEEK');
          }}
        >
          WEEKLY
        </NavLink>
      </NavItem>
      <NavItem className="tab-item">
        <NavLink
          className={classnames({ active: activeTab === 'MONTH' })}
          onClick={() => {
            toggle('MONTH');
          }}
        >
          MONTHLY
        </NavLink>
      </NavItem>
      <NavItem className="tab-item">
        <NavLink
          className={classnames({ active: activeTab === 'RANGE' })}
          onClick={() => {
            toggle('RANGE');
          }}
        >
          RANGE
        </NavLink>
      </NavItem>
    </Nav>
  );

  return (
    <>
      {loading && <Loader />}
      <Helmet title="Marketing Report" />
      <Page menu title="Marketing Report" Sticky={Header} >
      <Container className="overview m-0 p-0 marketing_report_kit">
        <TabContent activeTab={activeTab} style={{ color: "#000000", backgroundColor: '#F3F3F3', minHeight: '100vh' }} className="pt-2">
          <TabPane tabId="DAY">
            <div className="marketing_report_kit_container">
              <div className="marketing_report_kit_content_section">
                <MyDatePicker daily unsetMaxDate stringData={fetchData} />
                <div className="divider" />
                <ReviewSection />
              </div>
              <div className="marketing_report_kit_content_section">
                <Marketing />
              </div>
            </div>
          </TabPane>


          <TabPane tabId="WEEK">
            <div className="marketing_report_kit_container">
              <div className="marketing_report_kit_content_section">
                <MyDatePicker weekly unsetMaxDate stringData={fetchData} />
                <div className="divider" />
                <ReviewSection />
              </div>
              <div className="marketing_report_kit_content_section">
                <Marketing />
              </div>
            </div>
          </TabPane>


          <TabPane tabId="MONTH">
            <div className="marketing_report_kit_container">
              <div className="marketing_report_kit_content_section">
                <MyDatePicker monthly unsetMaxDate stringData={fetchData} />
                <div className="divider" />
                <ReviewSection />
              </div>
              <div className="marketing_report_kit_content_section">
                <Marketing />
              </div>
            </div>
          </TabPane>


          <TabPane tabId="RANGE">
            <div className="marketing_report_kit_container">
              <div className="marketing_report_kit_content_section">
                <MyDateRangePicker stringData={fetchData} />
                <div className="divider" />
                <ReviewSection />
              </div>
              <div className="marketing_report_kit_content_section">
                <Marketing />
              </div>
            </div>
          </TabPane>
        </TabContent>
      </Container>
      </Page>
    </>
  )
}

export default MarketingReport;

const InfoItem = ({ label, value, className }) => {
  return (
    <div className={`summary_box_kit_info_item ${className}`}>
      <div className="summary_box_kit_info_item_label">{label}</div>
      <div className="summary_box_kit_info_item_divider" />
      <div className="summary_box_kit_info_item_value">{value}</div>
    </div>
  )
};

const StarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M9.04894 2.92705C9.3483 2.00574 10.6517 2.00574 10.9511 2.92705L12.0206 6.21885C12.1545 6.63087 12.5385 6.90983 12.9717 6.90983H16.4329C17.4016 6.90983 17.8044 8.14945 17.0207 8.71885L14.2205 10.7533C13.87 11.0079 13.7234 11.4593 13.8572 11.8713L14.9268 15.1631C15.2261 16.0844 14.1717 16.8506 13.388 16.2812L10.5878 14.2467C10.2373 13.9921 9.7627 13.9921 9.41221 14.2467L6.61204 16.2812C5.82833 16.8506 4.77385 16.0844 5.0732 15.1631L6.14277 11.8713C6.27665 11.4593 6.12999 11.0079 5.7795 10.7533L2.97933 8.71885C2.19562 8.14945 2.59839 6.90983 3.56712 6.90983H7.02832C7.46154 6.90983 7.8455 6.63087 7.97937 6.21885L9.04894 2.92705Z" fill="#0C719C" />
  </svg>
);

const ArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.29289 18.7071C7.90237 18.3166 7.90237 17.6834 8.29289 17.2929L13.5858 12L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071Z" fill="#1D2129" />
  </svg>
);


const Card = ({ label, navTo, sms = false, value, used, profit }) => {
  return (
    <div className="marketing_report_kit__sub_section_card">
      <div className="card_header">
        <span className="card_title">{label}</span>
        <button type='button' onClick={navTo} className='btn-nav'><ArrowIcon /></button>
      </div>
      <div className="card_body">

        <div className="card_item">
          <div className="card_item_icon">
            {sms ? <img src={require('assets/svg/ic_sent.svg')} /> : <PrinterIcon />}
          </div>
          <div className="card_item_content">
            <div className="card_item_value">
              {value}
            </div>
            <div className="card_item_label">
              {sms ? 'Sent' : 'Printed'}
            </div>
          </div>
        </div>

        <div className="card_item_divider" />

        <div className="card_item">
          <div className="card_item_icon">
            <UsedIcon />
          </div>
          <div className="card_item_content">
            <div className="card_item_value">
              {used}
            </div>
            <div className="card_item_label">
              Used
            </div>
          </div>
        </div>

        <div className="card_item_divider" />

        <div className="card_item">
          <div className="card_item_icon">
            <ProfitIcon />
          </div>
          <div className="card_item_content">
            <div className="card_item_value">
              {formatCurrency(profit)}
            </div>
            <div className="card_item_label">
              Profit
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
