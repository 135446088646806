import React, { useEffect, useState } from 'react';

// reactstrap components
import classnames from 'classnames';
import {
	Container,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane
} from 'reactstrap';

import Income from 'components/TabPane/Income';
import StaffCalendar from 'views/calendar/StaffCalendar';



export default function TabNav() {
	const [activeTab, setActiveTab] = useState('1');
	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	const actvie_tab = localStorage.getItem('active_tab') ? localStorage.getItem('active_tab') : activeTab;

	const active_tab_handle = async () => {
		await setActiveTab(actvie_tab);
		await localStorage.removeItem('active_tab');
	}
	useEffect(() => {
		active_tab_handle();
	}, [actvie_tab])

	return (
		<>
			<Container className='staff-nav-tabs-container'>
				<Nav tabs className="custom-nav">
					<NavItem className="main-tab-card">
						<NavLink
							className={classnames({ active: activeTab === '1' })}
							onClick={() => {
								toggle('1');
							}}
						>
							Appointment
						</NavLink>
					</NavItem>
					<NavItem className="main-tab-card">
						<NavLink
							className={classnames({ active: activeTab === '2' })}
							onClick={() => {
								toggle('2');
							}}
						>
							Commission
						</NavLink>
					</NavItem>
				</Nav>
			</Container>
			<TabContent activeTab={activeTab} className="mt-2 staff-nav-tab-content">
				<TabPane tabId="1" className='appointment'>
					<StaffCalendar />
				</TabPane>
				<TabPane tabId="2">
					<Container>
						<Income />
					</Container>
				</TabPane>
			</TabContent>
		</>
	)
}