import BottomSheet, { useBottomSheetRef } from 'components/BottomSheet';
import React from 'react'

const DropDownTag = ({ blue = false, modalTitle = 'Sort by', value = '', options = [], onChange, maxHeightRatio, autoHeight }) => {
  const bsRef = useBottomSheetRef();

  const label = options.find(o => o.value === value)?.label || 'Label';

  const onChooseOption = (op) => () => {
    onChange(op.value);
    bsRef.current.close();
  };

  return (
    <div className='filter_option_kit drop_down_tag_kit'>
      <button type='button' className={`btn-dropdown ${blue ? 'blue' : ''}`} onClick={() => bsRef.current.open()}>
        <span>{label}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M3.52876 5.52892C3.78911 5.26857 4.21122 5.26857 4.47157 5.52892L8.00016 9.05752L11.5288 5.52892C11.7891 5.26857 12.2112 5.26857 12.4716 5.52892C12.7319 5.78927 12.7319 6.21138 12.4716 6.47173L8.47157 10.4717C8.21122 10.7321 7.78911 10.7321 7.52876 10.4717L3.52876 6.47173C3.26841 6.21138 3.26841 5.78927 3.52876 5.52892Z" fill={blue ? "white" : "#0C719C"} />
        </svg>
      </button>
      <BottomSheet
        ref={bsRef}
        autoHeight={autoHeight}
        maxHeightRatio={maxHeightRatio}
        className='filter_option_kit_container_bs'
        title={modalTitle}
      >
        <div className='filter_option_kit_container_wrap'>
          <div className='filter_option_kit_items'>
            {options.map(o => (
              <button key={o.value} className={`filter_option_kit_item ${value === o.value ? 'active' : ''}`} onClick={onChooseOption(o)}>
                <span>{o.label}</span>
                {value === o.value && <IconChecked />}
              </button>
            ))}
          </div>
        </div>
      </BottomSheet>
    </div>
  )
}

export default DropDownTag;

const IconChecked = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M9.72692 18C9.40676 18 9.10261 17.8661 8.8785 17.6317L4.34817 12.8936C3.88394 12.4081 3.88394 11.6045 4.34817 11.1189C4.8124 10.6334 5.58078 10.6334 6.04501 11.1189L9.72692 14.9697L17.955 6.36414C18.4192 5.87862 19.1876 5.87862 19.6518 6.36414C20.1161 6.84967 20.1161 7.6533 19.6518 8.13882L10.5753 17.6317C10.3512 17.8661 10.0471 18 9.72692 18Z" fill="#0C719C" />
  </svg>
);
