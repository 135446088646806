import moment from "moment";

const mappingBlockHour = (blockHourItems) => {
  const data = blockHourItems.map(o => {
    const id = o.id?.toString();
    const staffId = o.staffId?.toString();
    const startTime = moment(o.startTime, 'MM/DD/YYYY HH:mm A').format('MM-DD-YYYY HH:mm:ss');
    const endTime = moment(o.endTime, 'MM/DD/YYYY HH:mm A').format('MM-DD-YYYY HH:mm:ss');
    return ({
      id,
      type: 'block-hour',
      data: {
        id,
        staffId, startTime, endTime,
        note: o.reason || '',
      },
      staffId, startTime, endTime,
    });
  });
  return data;
};

export default mappingBlockHour;
