import React from 'react';
import { Link } from 'react-router-dom';
import { logOut, isAuthenticated } from 'common';
// reactstrap components
import {
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	Navbar,
	Nav,
	Container,
	Media,
} from 'reactstrap';

export default function AdminNavbar({isDisplayNav = true, ...props}) {
	const redirectLogin = () => {
		if (!isAuthenticated()) props.history.push('/login');
	};
	if (!isDisplayNav) return null;
	const { userInfo } = props;
	return (
		<Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
			<Container fluid>
				<Link
					className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
					to="/"
				>
					{props.brandText}
				</Link>
				<Nav className="align-items-center d-none d-md-flex" navbar>
					<UncontrolledDropdown nav>
						<DropdownToggle className="pr-0" nav>
							<Media className="align-items-center">
								<span className="avatar avatar-sm rounded-circle">
									<img
										alt="..."
										src={require('assets/img/theme/team-4-800x800.jpg')}
									/>
								</span>
								<Media className="ml-2 d-none d-lg-block">
									<span className="mb-0 text-sm font-weight-bold">
										{userInfo.name || ''}
									</span>
								</Media>
							</Media>
						</DropdownToggle>
						<DropdownMenu className="dropdown-menu-arrow" right>
							<DropdownItem
								className="noti-title"
								header
								tag="div"
							>
								<h6 className="text-overflow m-0">
									Welcome {userInfo.name || ''}!
								</h6>
							</DropdownItem>
							<DropdownItem to="/admin/user-profile" tag={Link}>
								<i className="ni ni-single-02" />
								<span>Profile</span>
							</DropdownItem>
							<DropdownItem to="/admin/user-profile" tag={Link}>
								<i className="ni ni-settings-gear-65" />
								<span>Thiết lập</span>
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem onClick={() => logOut(redirectLogin)}>
								<i className="ni ni-user-run" />
								<span>Đăng xuất</span>
							</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>
				</Nav>
			</Container>
		</Navbar>
	);
}
