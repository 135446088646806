import moment from 'moment';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import getTimeLines, { setTime } from './helpers/getTimeLines';
import { minBy } from 'lodash';
import { useAppSelector } from 'store/hooks';
import shopSelectors from 'store/shop/selectors';
import aptSelectors from 'store/appt/selectors';
function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}
export const tableCalendar = React.createRef();
const CALENDAR_TIME_SET = {
  open: moment().set({ hour: 9, minute: 0, second: 0 }),
  close: moment().set({ hour: 23, minute: 0, second: 0 }),
};
const schema = {
  timeHourEnd: 21,
  timeHourStart: 9,
  timeMinuteEnd: 0,
  timeMinuteStart: 0,
  distanceTimeline: 15,
};
const timelines = getTimeLines(setTime(schema.timeHourStart, schema.timeMinuteStart), setTime(schema.timeHourEnd, schema.timeMinuteEnd), schema.distanceTimeline);

const CalendarRealTimeLine = () => {
  const staffList = useAppSelector(shopSelectors.staff);
  const appointments = useAppSelector(aptSelectors.appointments);
  const [currentTime, setCurrentTime] = React.useState(moment());
  const [pixel, setPixel] = useState(0);
  const [right, setRight] = useState(0);

  useEffect(() => {
    const time = setInterval(() => {
      const { close, open } = CALENDAR_TIME_SET;
      const now = moment().set({ second: 0 });
      if (now.isBetween(open, close)) {
        setCurrentTime(now);
      } else {
        clearInterval(time);
      }
    }, 30000);
    return () => {
      clearInterval(time);
    };
  }, []);

  const calcTime = () => {
    const isIOS = iOS();
    const targetTime = minBy(timelines.filter(o => o.value >= currentTime), o => o.value);
    if (!targetTime) return setPixel(0);
    const timeId = `to_${targetTime?.value?.format('hh-mm-A')}`;
    const timeDOM = document.getElementById(timeId);
    if (!timeDOM) return setPixel(0);
    const diff = currentTime?.diff(targetTime?.value, 'minute');
    const extendPixel = (60 / 15) * diff;
    const extendOS = isIOS ? 65 : 0;
    setPixel(timeDOM.offsetTop + extendPixel + extendOS);
    setRight((timeDOM.scrollWidth || 0) - (timeDOM.offsetParent?.offsetWidth || 0));
  };

  useLayoutEffect(() => {
    calcTime();
  }, [currentTime, staffList, appointments]);

  if (!pixel) return null;

  return <div className='calendar-row-time' style={{ top: `${pixel}px`, right: `-${right}px` }}>
    <div className="box-time">
      <div className="text-time">{currentTime.format('hh:mm A')}</div>
    </div>
    <svg className='circle' xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
      <circle cx="3.5" cy="3.5" r="3.5" fill="#F53F3F" />
    </svg>
    <div className="time-line"></div></div>;
}

export default CalendarRealTimeLine;
