import React, { useMemo } from 'react'
import { momentTimezone } from 'utils/time';
import requestStaff from './icons/requestStaff.svg';
import requestDisabled from './icons/requestDisabled.svg';
import online from './icons/online.svg';
import onlineDisabled from './icons/onlineDisabled.svg';
import calling from './icons/calling.svg';
import callingDisabled from './icons/callingDisabled.svg';
import confirm from './icons/confirm.svg';
import confirmDisabled from './icons/confirmDisabled.svg';
import plus from './icons/plus.svg';
import minus from './icons/minus.svg';

export const APPOINTMENT_SOURCE_TYPE = {
  WEBSITE: 1,
  CALl_IN: 0
};
const AppointmentItem = ({ data, single, disabled, onViewDetail = () => undefined }) => {
  const startTime = useMemo(() => momentTimezone(data.startTime), [data]);
  const endTime = useMemo(() => momentTimezone(data.endTime), [data]);
  const extraData = useMemo(() => data.data, [data]);
  const customerName = (extraData.customerName?.replace('WALL-IN', 'Walk-In') || 'Walk-In');

  const onDetail = () => onViewDetail({ id: data.id, disabled });
  const onMinus = () => {
    console.log('AppointmentItem data MINUS', data.id);
  }
  const onPlus = () => {
    console.log('AppointmentItem data PLUS', data.id);
  }

  return (
    <div onClick={onDetail} className={`appointment-item-container ${disabled ? 'disabled' : ''}`}>
      <div className="appointment-item-container-box">
        <div onClick={onDetail} className="top-view-container">
          <div className="time-text">
            <span>{startTime.format('hh:mm A')} - {endTime.format('hh:mm A')}</span>
          </div>
          {disabled
            ?
            <div className="booking-act">
              {extraData.confirm && <img src={confirmDisabled} alt="confirmDisabled" className='icon' />}
              {extraData.requestStaff && <img src={requestDisabled} alt="requestDisabled" className='icon' />}
              {extraData.sourceType !== APPOINTMENT_SOURCE_TYPE.CALl_IN ?
                <img src={onlineDisabled} alt="onlineDisabled" className='icon' /> :
                <img src={callingDisabled} alt="callingDisabled" className='icon' />
              }
            </div> :
            <div className="booking-act">
              {extraData.confirm && <img src={confirm} alt="confirm" className='icon' />}
              {extraData.requestStaff && <img src={requestStaff} alt="requestStaff" className='icon' />}
              {extraData.sourceType !== APPOINTMENT_SOURCE_TYPE.CALl_IN ?
                <img src={online} alt="online" className='icon' /> :
                <img src={calling} alt="calling" className='icon' />
              }
            </div>
          }

        </div>
        <div onClick={onDetail} className="appointment-customer-name">{customerName}</div>
        <div onClick={onDetail} className="appointment-services">
          <b>Sers:</b> {extraData.services.map(o => o.serviceName || '').join(',')}
        </div>
        <div className="appointment-note">Note: {extraData.note || '--'}</div>
      </div>
      {/* <div className="appointment-item-duration">
        <button className="btn-duration" onClick={onMinus}>
          <img src={minus} alt="minus" className='duration-icon' />
        </button>
        <button className="btn-duration" onClick={onPlus}>
          <img src={plus} alt="plus" className='duration-icon' />
        </button>
      </div> */}
    </div>
  )
}
export const AppointmentItemMulti = ({ data, disabled, onViewDetail = () => undefined }) => {
  const startTime = useMemo(() => momentTimezone(data.startTime), [data]);
  const endTime = useMemo(() => momentTimezone(data.endTime), [data]);
  const extraData = useMemo(() => data.data, [data]);
  const customerName = (extraData.customerName?.replace('WALL-IN', 'Walk-In') || 'Walk-In');
  const onDetail = () => onViewDetail({ id: data.id, disabled });

  return (
    <div onClick={onDetail} className={`appointment-item-container ${disabled ? 'disabled' : ''}`}>
      <div className="appointment-item-container-box">
        <div onClick={onDetail} className="top-view-container">
          <div className='appointment-customer-time'>
            <div className="time-text">
              <span>{startTime.format('hh:mm A')} - {endTime.format('hh:mm A')}</span>
            </div>
          </div>
          {disabled
            ?
            <div className="booking-act">
              {extraData.confirm && <img src={confirmDisabled} alt="confirmDisabled" className='icon' />}
              {extraData.requestStaff && <img src={requestDisabled} alt="requestDisabled" className='icon' />}
              {extraData.sourceType !== APPOINTMENT_SOURCE_TYPE.CALl_IN ?
                <img src={onlineDisabled} alt="onlineDisabled" className='icon' /> :
                <img src={callingDisabled} alt="callingDisabled" className='icon' />
              }
            </div> :
            <div className="booking-act">
              {extraData.confirm && <img src={confirm} alt="confirm" className='icon' />}
              {extraData.requestStaff && <img src={requestStaff} alt="requestStaff" className='icon' />}
              {extraData.sourceType !== APPOINTMENT_SOURCE_TYPE.CALl_IN ?
                <img src={online} alt="online" className='icon' /> :
                <img src={calling} alt="calling" className='icon' />
              }
            </div>
          }

        </div>
        <div onClick={onDetail} className="appointment-customer-name">{customerName}</div>
        <div onClick={onDetail} className="appointment-services">
          <b>Sers:</b> {extraData.services.map(o => o.serviceName || '').join(',')}
        </div>
        {!!extraData.note && <div className="appointment-note">Note: {extraData.note || '--'}</div>}
      </div>
    </div>
  )
}

export default AppointmentItem;
