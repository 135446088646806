import { useCallback, useMemo } from "react";
import { useAppSelector } from "store/hooks";
import shopSelectors from "store/shop/selectors";
import { ANYONE } from "./anyone";

const useCalendarSchema = ({ headers: staffHeaders = [], dateFilter, staffView = false }) => {
  const _staffListStore = useAppSelector(shopSelectors.staff);
  const staffList = useMemo(() => _staffListStore?.filter(o => staffHeaders.find(s => s.toString() === o.id.toString())), [_staffListStore]);
  const headers = useMemo(() => {
    let _headers = [
      { id: ANYONE.id, value: ANYONE.id, label: ANYONE.name, type: 'staff', url: ANYONE.avatar, typeColor: 0 }
    ];
    if (staffView) {
      _headers = [];
    }
    let i = 1;
    staffList.forEach((item) => {
      const result = ({
        id: item.id,
        value: item.id,
        label: item.name,
        type: 'staff',
        url: item.avatar,
        typeColor: i,
      });
      i++;
      if (i > 6) {
        i = 1;
      }
      _headers.push(result);
    });

    return _headers;
  }, [staffList, _staffListStore, staffView]);

  const filterAppointments = useCallback((order, _col) => {
    const staffId = order?.staffId;
    return staffId?.toString() === _col.id?.toString();
  }, []);

  return ({
    dateFilter,
    headers,
    filterAppointments,
    timeHourEnd: 21,
    timeHourStart: 9,
    timeMinuteEnd: 0,
    timeMinuteStart: 0,
    distanceTimeline: 15,
  })
};

export default useCalendarSchema;
