import React from 'react'

const ContentContainer = ({ children, noPadding }) => {
  return (
    <div className="new_appointment__container">
      <div className="new_appointment__container_box" style={noPadding && { paddingRight: 0, paddingLeft: 0 }}>
        {children}
      </div>
    </div>
  )
}

export default ContentContainer;
