import React from 'react'
import { useAppSelector } from 'store/hooks'
import shopSelectors from 'store/shop/selectors'
import { formatPhone } from 'utils/formatPhone';

const ShopHeader = () => {
  const shopInfo = useAppSelector(shopSelectors.shopInfo);
  if (!shopInfo) return null;

  return (
    <div className='shop_info_header_kit'>
      <div className='shop_name'>{shopInfo.companyName || '--'}</div>
      <div className='address'>
        {shopInfo.address}, {shopInfo.city}
      </div>
      <div className='address'>
        Phone: {formatPhone(shopInfo.phone?.replace('+1', ''))}
      </div>
    </div>
  )
}

export default ShopHeader;
