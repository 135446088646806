import { NEW_APT_STEPS } from 'constant';
import React from 'react'

const Title = ({ step }) => {
  const info = (() => {
    let stepNumber = 1;
    let title = 'Your Information';
    switch (step) {
      case NEW_APT_STEPS.SERVICE: {
        stepNumber = 2;
        title = 'Select Services';
        break;
      }
      case NEW_APT_STEPS.DATE_STAFF: {
        stepNumber = 3;
        title = 'Select Date & Staff';
        break;
      }
      case NEW_APT_STEPS.TIME_SLOT: {
        stepNumber = 4;
        title = 'Select Time';
        break;
      }
      case NEW_APT_STEPS.REVIEW: {
        stepNumber = 5;
        title = 'REVIEW';
        break;
      }
      case NEW_APT_STEPS.INFORMATION:
      default: {
        stepNumber = 1;
        title = 'Your Information';
      }

    }
    return ({ step: stepNumber, title });
  })();


  return (
    <div className='new_appointment__title'>
      <p className="step_count">Step {info.step} of 5</p>
      <p className="step_label">{info.title}</p>
    </div>
  )
}

export default Title;
