import classnames from 'classnames';
import { API, getDataCookie } from 'common';
import Loader from 'components/Loader';
import Page from 'components/Page';
import OverviewPicker from 'components/overview/OverviewPicker';
import OverviewRangePicker from 'components/overview/OverviewRangePicker';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import StarRatings from 'react-star-ratings';
import {
	Container,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane
} from 'reactstrap';
import { formatCurrency } from 'utils/formatCurrency';
import { formatPhone } from 'utils/formatPhone';

export default function Overview() {
	const [activeTab, setActiveTab] = React.useState('DAY');
	const [overviewData, setOverviewData] = useState([]);
	const staffId = getDataCookie(process.env.REACT_APP_COOKIE_KEY);
	const info_salon = JSON.parse(localStorage.getItem('info_salon'));
	const [loading, setLoading] = useState(false);

	const toggle = (tab) => {
		let range_param = '';
		const now = new Date();
		switch (tab) {
			case 'WEEK': {
				const dateMoment = moment(now);
				const start_date = dateMoment.startOf('week').format('MM-DD-YYYY');
				const end_date = dateMoment.endOf('week').format('MM-DD-YYYY');
				range_param = `startDate=${start_date}&endDate=${end_date}`;
				break;
			}
			case 'MONTH': {
				const dateMoment = moment(now);
				const start_date = dateMoment.startOf('month').format('MM-DD-YYYY');
				const end_date = dateMoment.endOf('month').format('MM-DD-YYYY');
				range_param = `startDate=${start_date}&endDate=${end_date}`;
				break;
			}
			case 'DAY':
			default: {
				const dateMoment = moment(now).format('MM-DD-YYYY');
				range_param = `startDate=${dateMoment}&endDate=${dateMoment}`;
				break;
			}
		}
		fetchData(range_param);
		if (activeTab !== tab) setActiveTab(tab);
	};

	useEffect(()=>{
		toggle('DAY');
	},[]);

	const fetchData = async (range_param) => {
		setLoading(true);
		try {
			let response = await API.get(`admin/overview?staffId=${staffId}&type=FROM-TO&${range_param}`);
			if (response.status == 200) {
				let { payload } = response.data;
				if (payload) {
					setOverviewData(payload);
				}
			}
		} catch (error) {
			console.error('get_overview_data', error);
		} finally {
			setLoading(false);
		}
	};

	const OverviewSummary = () => {
		return <p className='overview_kit__summary_summary_result'>
			Total customer: <span>{overviewData.totalCustomer && overviewData.totalCustomer}</span><br />
			Total appointment: <span>{overviewData.totalAppointment && overviewData.totalAppointment}</span><br />
			Total new customer: <span>{overviewData.totalNewCustomer && overviewData.totalNewCustomer}</span>
		</p>;
	}

	const Dashboard = () => {
		return (
			<>
				<div className="m-0 overview_kit__gross-sale">
					<div className='title-gross-sale'>Gross sale</div>
					<div className="group-gross-sale-summary">
						<div>{formatCurrency(overviewData.grossSale)}</div>
						<BalancePercentText className='hight-light' value={overviewData.percent} />
					</div>
					<div className='group-gross-sale-detail'>
						<div className="group-gross-sale-detail-item">
							<span className='title'>Appointment:</span>
							<span className='value'>
								{formatCurrency(overviewData.appointment)}
								<BalancePercentText value={overviewData.percentAppointment} />
							</span>
						</div>
						<div className="group-gross-sale-detail-item">
							<span className='title'>Walk-In:</span>
							<span className='value'>
								{formatCurrency(overviewData.walkIn)}
								<BalancePercentText value={overviewData.percentWalkIn} />
							</span>
						</div>
					</div>
					<div className='group-gross-sale-detail'>
						<div className="group-gross-sale-detail-item">
							<span className='title'>Services:</span>
							<span className='value'>
								{formatCurrency(overviewData.services)}
								<BalancePercentText value={overviewData.percentServices} />
							</span>
						</div>
						<div className="group-gross-sale-detail-item">
							<span className='title'>Gift Card:</span>
							<span className='value'>
								{formatCurrency(overviewData.giftCard)}
								<BalancePercentText value={overviewData.percentGiftCard} />
							</span>
						</div>
						<div className="group-gross-sale-detail-item">
							<span className='title'>Products:</span>
							<span className='value'>
								{formatCurrency(overviewData.products)}
								<BalancePercentText value={overviewData.percentProducts} />
							</span>
						</div>
					</div>

					{overviewData?.serviceDetails?.length ?
						<div className='group-gross-sale-detail service-detail'>
							<div className="group-gross-sale-detail_title">
								Services Detail
							</div>
							{overviewData?.serviceDetails?.map((item, index) => {
								return (
									<div key={index} className="group-gross-sale-detail-item">
										<span className='title'>{item.serviceName}</span>
										<span className='value'>
											{formatCurrency(item.totalAmount)}
											<BalancePercentText value={item.percent} />
										</span>
									</div>
								)
							})}
						</div> : null}
				</div >
			</>
		)
	};

	return (
		<>
			{loading && <Loader />}
			<Page menu title="Overview">
			<Container className="overview overview_kit m-0 p-0">
				<div className='shop_info_kit'>
					<p className="shop_info_kit__shop_name">{info_salon?.companyName || ''}</p>
					<div className='shop_info_kit__shop_info'>
						<p className="shop_info_kit__text">{info_salon?.address || ''}, {info_salon?.city || ''}</p>
						<div className="shop_info_kit__text">Phone: {formatPhone(info_salon?.phone)}</div>
					</div>
					<div className="shop_info_kit__ratings">
						<StarRatings
							rating={info_salon?.star || 0}
							starRatedColor="#FFC31F"
							numberOfStars={5}
							starDimension={'24px'}
							starSpacing='0px'
							name='rating'
						/>
					</div>
				</div>
				<Nav tabs className="overview-custom-nav">
					<NavItem className="tab-item">
						<NavLink
							className={classnames({ active: activeTab === 'DAY' })}
							onClick={() => toggle('DAY')}>
							TODAY
						</NavLink>
					</NavItem>
					<NavItem className="tab-item">
						<NavLink
							className={classnames({ active: activeTab === 'WEEK' })}
							onClick={() => toggle('WEEK')}>
							WEEKLY
						</NavLink>
					</NavItem>
					<NavItem className="tab-item">
						<NavLink
							className={classnames({ active: activeTab === 'MONTH' })}
							onClick={() => toggle('MONTH')}>
							MONTHLY
						</NavLink>
					</NavItem>
					<NavItem className="tab-item">
						<NavLink
							className={classnames({ active: activeTab === 'RANGE' })}
							onClick={() => toggle('RANGE')}>
							RANGE
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={activeTab} style={{ color: "#000000", backgroundColor: 'rgba(70, 146, 244, 0.1)' }} className="pt-2">
					<TabPane tabId="DAY">
						<div className='overview_kit__summary'>
							<OverviewPicker type={'DAY'} stringData={fetchData} />
							<OverviewSummary />
						</div>
						<Dashboard />
					</TabPane>
					<TabPane tabId="WEEK">
						<div className='overview_kit__summary'>
							<OverviewPicker type={'WEEK'} stringData={fetchData} />
							<OverviewSummary />
						</div>
						<Dashboard />
					</TabPane>
					<TabPane tabId="MONTH">
						<div className='overview_kit__summary'>
							<OverviewPicker type={'MONTH'} stringData={fetchData} />
							<OverviewSummary />
						</div>
						<Dashboard />
					</TabPane>
					<TabPane tabId="RANGE">
						<div className='overview_kit__summary'>
							<OverviewRangePicker stringData={fetchData} />
							<OverviewSummary />
						</div>
						<Dashboard />
					</TabPane>
				</TabContent>
			</Container>
			</Page>
		</>
	)
};

const BalancePercentText = ({ className, value }) => {
	return (
		<div className={className} style={{ display: 'inline-flex', color: `${(value < 0) ? '#FF2B38' : '#00B52B'}` }}>{value ? (value >= 0) ? `+ ${value}` : value : ` +0`}%</div>
	)
};

