import Loader from 'components/Loader';
import Coupon from 'components/new-appointment/Coupon';
import InputPhone from 'components/new-appointment/InputPhone';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import useDebounce from 'utils/useDebounce';
import { getCouponByQRcode, getCustomerInfoByPhone } from '../hooks/index.data';

const CustomerTab = forwardRef(({
  phone, setPhone,
  name, setName,
  coupon, setCoupon,
  customer, setCustomer
}, ref) => {
  const inputNameRef = useRef(null);
  const inputPhoneRef = useRef(null);
  const inputCouponRef = useRef(null);
  const [loading, setLoading] = React.useState(false);
  const onPhoneDebounce = async (_phone) => {
    if (!inputNameRef.current) return;
    setLoading(true);
    setPhone(_phone);
    try {
      const customerRes = await getCustomerInfoByPhone(_phone?.replace(/[^0-9]/g, ''));
      if (customerRes) {
        setCustomer(customerRes);
        const newName = customerRes.name;
        inputNameRef.current.setText(newName);
        setName(newName);
      } else {
        throw 'fail';
      }
    } catch (error) {
      setCustomer(null);
      inputNameRef.current.setText('');
      setName('');
      inputNameRef.current.focus();
    }
    finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    reset: () => {
      if (inputPhoneRef.current) inputPhoneRef.current.reset();
      if (inputNameRef.current) inputNameRef.current.reset();
      if (inputCouponRef.current) inputCouponRef.current.reset();
    },
  }))

  return (
    <div className='container-box'>
      <div className="text-label">CUSTOMER Information</div>
      <InputPhoneDebounce ref={inputPhoneRef} defaultValue={phone} onDebounce={onPhoneDebounce} />
      {loading && <Loader />}
      <InputNameDebounce
        ref={inputNameRef}
        defaultValue={name}
        onDebounce={setName}
        disabled={!!customer?.id}
      />
      <InputCoupon ref={inputCouponRef} defaultCoupon={coupon} setCouponActive={setCoupon} />
    </div>
  )
})

export default CustomerTab;

const InputCoupon = forwardRef(({ defaultCoupon, setCouponActive }, ref) => {
  const [active, setActive] = useState(!!defaultCoupon?.couponCode);
  const [coupon, setCoupon] = useState(defaultCoupon);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const handleDebounce = async (code) => {
    setCoupon(null);
    setCouponActive(null);
    setMsg('');
    setActive(false);
    setLoading(true);
    try {
      const data = await getCouponByQRcode(code);
      if (data) setCoupon(data);
      else throw 'fail';
    } catch (error) {
      setMsg('Coupon does not exit');
    }
    finally {
      setLoading(false);
    }
  };

  const handleActive = () => {
    const nextValue = !active;
    if (nextValue) setCouponActive(coupon);
    else setCouponActive(null);
    setActive(nextValue);
  };

  const preventDebounce = (code) => {
    if (code?.length < 6) return true;
    return false;
  };
  const [text, onChange, setText] = useDebounce(defaultCoupon?.couponCode || '', handleDebounce, preventDebounce);

  const handleChange = (e) => {
    if (msg) setMsg('');
    onChange(e);
  };

  useImperativeHandle(ref, () => ({
    reset: () => {
      setActive(false);
      setCoupon('');
      setLoading(false);
      setMsg('');
      setText('');
    }
  }))

  return (
    <div className='new_appointment__coupon'>
      <div className="input_kit">
        <label>Coupon</label>
        <input type="text" value={text} onChange={handleChange} />
      </div>
      {loading && <Loader />}
      {msg && <p>{msg}</p>}
      {coupon && <button
        className={`box-coupon ${active ? 'active' : ''}`}
        onClick={handleActive}>
        <Coupon data={coupon} />
        <div className="absolute-icon">
          {active ? <TickIcon /> : <div className='tick-icon' />}
        </div>
      </button>}
    </div>
  )
});

const InputNameDebounce = forwardRef(({ defaultValue, onDebounce, disabled }, ref) => {
  const inputRef = useRef(null);
  const [text, onChange, setText] = useDebounce(defaultValue, onDebounce);

  useImperativeHandle(ref, () => ({
    setText: (_text) => setText(_text),
    reset: () => setText(''),
    focus: () => inputRef.current && inputRef.current.focus(),
  }))

  return (
    <div className="input_kit">
      <label>Customer Name <label>(*)</label></label>
      <input className='none-disabled-style' ref={inputRef} type="text" value={text} onChange={onChange} disabled={disabled} />
    </div>
  )
});

const InputPhoneDebounce = forwardRef(({ onDebounce, defaultValue }, ref) => {
  const preventDebounce = (phone) => {
    const _phone = phone?.replace(/[^0-9]/g, '');
    if (_phone?.length < 10) return true;
    return false;
  };

  const [text, onChange, setText] = useDebounce(defaultValue, onDebounce, preventDebounce);

  useImperativeHandle(ref, () => ({
    reset: () => setText(''),
  }));

  return (
    <div className="input_kit">
      <div>
        <label>Phone Number <label>(*)</label></label>
      </div>
      <InputPhone value={text} onChange={onChange} autoFocus />
    </div>
  )
});
const TickIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <rect y="0.478149" width="24" height="24" rx="2" fill="#0085E7" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.7972 6.6193C16.5901 6.41289 16.2509 6.42738 16.0622 6.65071L10.4254 13.3223L8.21646 11.0155C8.02249 10.813 7.69987 10.8095 7.50164 11.0079L6.36993 12.1406C6.17778 12.3329 6.17449 12.6435 6.3625 12.8398L10.081 16.7229C10.2196 16.8677 10.4241 16.9107 10.6018 16.851C10.7447 16.8521 10.8876 16.7924 10.9879 16.6737L11.7295 15.796L12.2732 15.2518L12.2286 15.2052L17.9578 8.4243C18.1257 8.22555 18.1131 7.93122 17.9288 7.74751L16.7972 6.6193Z" fill="white" />
  </svg>
);