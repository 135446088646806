import React, { useMemo, useState } from 'react'
import aptSelectors from 'store/appt/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useHistory } from 'react-router-dom';
import aptActions from 'store/appt/actions';
import Loader from 'components/Loader';
import CalendarUI from './CalendarUI';
import shopSelectors from 'store/shop/selectors';
import moment from 'moment';
import { aptUIActions } from 'store/appt/reducers';
import MyDatePicker from 'components/DatePicker';
import calendarIcon from './CalendarUI/icons/calendar.svg';
import { Button } from 'reactstrap';
import addIcon from './CalendarUI/icons/add.svg';
import backPageIcon from './CalendarUI/icons/backpage.svg';
import refreshIcon from './CalendarUI/icons/change.svg';

const OwnerCalendar = () => {
  const dispatch = useAppDispatch();
  const _history = useHistory();
  const loading = useAppSelector(aptSelectors.loading);
  const appointmentList = useAppSelector(aptSelectors.appointments);
  const blockHours = useAppSelector(aptSelectors.blockHours);
  const dateStr = useAppSelector(aptSelectors.date);
  const staffList = useAppSelector(shopSelectors.staff);
  const date = useMemo(() => moment(dateStr), [dateStr]);
  const setDate = (value) => dispatch(aptUIActions.setDate(value));

  const fetchDataApt = async (range_param) => {
    dispatch(aptActions.getAptData.fetch(range_param));
  };

  React.useEffect(() => {
    const dateMoment = date.format('YYYY-MM-DD');
    const range_param = `start_time=${dateMoment}&end_time=${dateMoment}`;
    fetchDataApt(range_param);
  }, []);

  const viewAptDetail = ({ id }) => {
    _history.push('/appointment/' + id);
  };

  const calendarHeaders = useMemo(() => staffList.map(o => o.id), [staffList]);
  const calendarDataSources = useMemo(() => appointmentList.concat(blockHours), [appointmentList, blockHours]);
  const goBack = () => {
    _history.push('/appointments');
  };

  const makeAppointment = () => _history.push('/booking', { path: '/calendar' });
  const onRefresh = () => {
    const dateMoment = date.format('YYYY-MM-DD');
    const range_param = `start_time=${dateMoment}&end_time=${dateMoment}`;
    fetchDataApt(range_param);
  }

  return (
    <>
      {loading && <Loader />}
      <div className='owner-calendar-kit'>
        <CalendarUI
          datePicker={<MyDatePicker
            daily
            stringData={fetchDataApt}
            unsetMaxDate
            startFieldName='start_time'
            endFieldName='end_time'
            format='YYYY-MM-DD'
            style={{ flex: 1 }}
            onChange={setDate}
            value={date}
            customRender={({ date }) => {
              return (
                <div className='date_picker_appointment'>
                  <img src={calendarIcon} className="date_picker_appointment-icon" />
                  <div className="date_picker_appointment-label">{date?.format("MM-DD")}</div>
                </div>)
            }}
          />}
          dateFilter={date}
          headers={calendarHeaders}
          dataSource={calendarDataSources}
          viewAptDetail={viewAptDetail}
        />
        <OwnerFAB onBack={goBack} onNewApt={makeAppointment} onRefresh={onRefresh} />
      </div>
    </>
  )
}

export default OwnerCalendar;

const OwnerFAB = ({ onBack, onNewApt, onRefresh }) => {
  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);
  const handleClick = (type) => () => {
    setShow(false);
    switch (type) {
      case 'refresh': return onRefresh();
      case 'new': return onNewApt();
      case 'back': return onBack();
      default:
        break;
    }
  }
  return <>
    <Button color={!show ? 'primary' : undefined} className={`fab-hamburger ${show ? 'show' : ''}`} onClick={toggle}>
      <div className="wrapper">
        <span class="icon-bar" id="one"></span>
        <span class="icon-bar" id="two"></span>
        <span class="icon-bar" id="thr"></span>
      </div>
    </Button>
    <Button onClick={handleClick('back')} className={`fab-nav back-page ${show ? 'show' : ''}`}>
      <img src={backPageIcon} alt="backPageIcon" className='icon' />
    </Button>
    <Button onClick={handleClick('refresh')} className={`fab-nav refresh-page ${show ? 'show' : ''}`}>
      <img src={refreshIcon} alt="refreshIcon" className='icon' />
    </Button>
    <Button color='primary' onClick={handleClick('new')} className={`fab-nav add-new ${show ? 'show' : ''}`}>
      <img src={addIcon} alt="addIcon" className='icon' />
    </Button>
    <div className={`fab-overlay ${show ? 'show' : ''}`}></div>
  </>

}