import React, { Fragment, useCallback } from 'react'
import useCalendarSchema from './useCalendarSchema';
import useMappingCalendarData from './useMappingCalendar';
import { FORMAT_TIME, TIME_VALUE_FORMAT } from './helpers/format';
import { useAppSelector } from 'store/hooks';
import shopSelectors from 'store/shop/selectors';
import moment from 'moment';
import { get } from 'lodash';
import { ANYONE_ID } from './anyone';
import CalendarItem from './CalendarItem';
import CalendarRealTimeLine, { tableCalendar } from './CalendarRealTimeLine';
/**
 * inputs:  
 *  staffView: single UI for staff only
 *  headers: list staffIds 
 *    ex: [123,345, 546]
 *  dataSource: data showing calendar
 *   model data
 *      {
 *          id: string;
 *          startTime: MM-DD-YYYY HH:mm:ss,
            endTime: MM-DD-YYYY HH:mm:ss,
            staffId: string;
            extraData: appointment data | block-time data;
            type: APPOINTMENT | BLOCK-HOUR
 *      }
 * 
 * 
 * @returns UI Calendar
 */
const CalendarUI = (props) => {
  const schema = useCalendarSchema(props);
  const body = useMappingCalendarData(schema, props.dataSource);
  const staffView = props.staffView;
  const scheduleBooking = useAppSelector(shopSelectors.scheduleBooking);

  const onViewAppointmentDetail = (apt) => {
    if (props.viewAptDetail)
      props.viewAptDetail(apt);
  };

  const renderColumn = (data) => (col) => {
    const { rowTime, colData } = data;
    const dropId = data.id + '/' + col.id;
    const dropData = { rowId: data.id, colData: col, rowTime: data.rowTime };

    const scheduleBookingDate = get(scheduleBooking, [col.rowTime.format('ddd')], null) || null;
    let disable = false;
    if (scheduleBookingDate) {
      const colTime = moment(col.rowTime.format(TIME_VALUE_FORMAT), TIME_VALUE_FORMAT);
      const startWorkingDate = moment(scheduleBookingDate?.startTime, 'HH:mm');

      const isBetweenWorkingHour = colTime.isSame(startWorkingDate) || colTime.isBetween(
        startWorkingDate,
        moment(scheduleBookingDate?.endTime, 'HH:mm')
      );
      disable = !scheduleBookingDate?.enable || !isBetweenWorkingHour;
    }

    const isFuture = col.rowTime.isSameOrAfter(moment());

    if (!isFuture) {
      disable = true;
    }

    const orderGroup = body.rowsSpanInfo.find(it =>
      rowTime.isSameOrBefore(it?.rowEnd.value) &&
      it.colId === col.id &&
      col.data.some(i => it.orderIds.includes(i?.id?.toString())),
    );

    const isExact = !!orderGroup && orderGroup.rowStart.id === data.id;
    if (isExact) {
      return (
        <td anyone={col.id === ANYONE_ID} key={col.id + 'col'} rowSpan={orderGroup?.rowSpan || 1}
        >
          <div className='box-td' onDoubleClick={e => e.stopPropagation()}>
            <CalendarItem onViewAppointmentDetail={onViewAppointmentDetail} disabled={disable} data={col.data} headerData={col.headerData} />
          </div>
        </td>
      );
    }
    const isBefore = !!orderGroup;

    if (isBefore) return <td id={dropId} key={col.id + 'col'}  ><div onDoubleClick={e => e.stopPropagation()} className='box-td' /></td>;

    return (
      <td anyone={col.id === ANYONE_ID} id={dropId} key={col.id}><div onDoubleClick={e => e.stopPropagation()} className='box-td'></div></td>
    );
  };

  return (
    <div className={`calendar-ui-kit ${staffView ? 'staffView' : 'adminView'}`}>
      <table ref={tableCalendar} >
        {!staffView && <thead>
          <tr>
            <th className="fixed-time-td">{props.datePicker}</th>
            {schema.headers.map(o =>
              <th key={o.id}>
                <div className="staff-view-header">
                  <img src={o.url} alt="" className="avatar" />
                  <span className="staff-name">{o.label}</span>
                </div>
              </th>
            )}
          </tr>
        </thead>}
        <tbody>
          {body.rows.map((row, index) => {
            const time = {
              label: row.rowTime.format(FORMAT_TIME),
              labelSort: row.rowTime.format('mm'),
              strong: row.rowTime.format('mm') === '00',
              value: `to_${row.rowTime.format('hh-mm-A')}`,
            };

            return (
              <tr key={row.id} id={`${time.value}`} >
                <td className={`fixed-time-td ${time.value}_time`}>
                  <div className="box-time" style={{ textAlign: 'right' }}>
                    <div className={`text-time ${time.strong ? 'strong' : ''}`}>{time.strong ? time.label : time.labelSort}</div>
                  </div>
                </td>
                {row.colData.length > 0 ? row.colData.map(renderColumn(row)) : <td className='first-col' />}
              </tr>
            )
          })}
        </tbody>
      </table>
      <CalendarRealTimeLine />
    </div>
  )
}

export default CalendarUI;
