import { getDataCookie } from 'common';
import MyDatePicker from 'components/DatePicker';
import Loader from 'components/Loader';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import aptActions from 'store/appt/actions';
import { aptUIActions } from 'store/appt/reducers';
import aptSelectors from 'store/appt/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import CalendarUI from './CalendarUI';
import refreshIcon from './CalendarUI/icons/change.svg';
import addIcon from './CalendarUI/icons/add.svg';

const FORMAT_DATE = 'MM-DD-YYYY';

const StaffCalendar = () => {
  const dispatch = useAppDispatch();
  const _history = useHistory();
  const staffId = getDataCookie(process.env.REACT_APP_COOKIE_KEY);

  const loading = useAppSelector(aptSelectors.loading);
  const appointmentList = useAppSelector(aptSelectors.appointments);
  const blockHours = useAppSelector(aptSelectors.blockHours);
  const dateStr = useAppSelector(aptSelectors.date);
  const date = useMemo(() => moment(dateStr), [dateStr]);
  const setDate = (value) => dispatch(aptUIActions.setDate(value));

  const fetchDataApt = async (range_param) => {
    let query = range_param;
    if (staffId) {
      query += '&staffId=' + staffId;
    }
    dispatch(aptActions.getAptData.fetch(query));
  };

  React.useEffect(() => {
    const dateMoment = date.format('YYYY-MM-DD');
    const range_param = `start_time=${dateMoment}&end_time=${dateMoment}`;
    fetchDataApt(range_param);
  }, []);

  const calendarHeaders = useMemo(() => [staffId], [staffId]);
  const calendarDataSources = useMemo(() => appointmentList.concat(blockHours), [appointmentList, blockHours]);

  const viewAptDetail = ({ id, disabled }) => {
    _history.push('/appointment-staff/' + id, { disabled });
  };
  const makeAppointment = () => {
    _history.push('/booking');
  }

  const onRefresh = () => {
    const dateMoment = date.format('YYYY-MM-DD');
    const range_param = `start_time=${dateMoment}&end_time=${dateMoment}`;
    fetchDataApt(range_param);
  }

  return (
    <>
      {loading && <Loader />}
      <div className='staff-appointment-calendar-date-picker'>
        <div className="d-flex align-items-center" style={{ gap: '8px', paddingRight: '8px' }}>
          <MyDatePicker
            daily
            stringData={fetchDataApt}
            unsetMaxDate
            startFieldName='start_time'
            endFieldName='end_time'
            format='YYYY-MM-DD'
            style={{ flex: 1 }}
            onChange={setDate}
            value={date}
            customRender={({ icon, date }) => {
              return (
                <div className='date_picker_appointment' >
                  <div className="date_picker_appointment-label">{date?.format("ddd")} {date?.format(FORMAT_DATE)}</div>
                  <div className="date_picker_appointment-icon">
                    {icon}
                  </div>
                </div>)
            }}
          />
        </div>
      </div>
      <CalendarUI
        dateFilter={date}
        staffView
        headers={calendarHeaders}
        dataSource={calendarDataSources}
        viewAptDetail={viewAptDetail}
      />
      <StaffFAB onNewApt={makeAppointment} onRefresh={onRefresh} />
    </>
  )
}

export default StaffCalendar;

const StaffFAB = ({ onBack, onNewApt, onRefresh }) => {
  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);
  const handleClick = (type) => () => {
    setShow(false);
    switch (type) {
      case 'refresh': return onRefresh();
      case 'new': return onNewApt();
      case 'back': return onBack();
      default:
        break;
    }
  }
  return <div className='owner-calendar-kit calendar-staff-FAB'>
    <Button color={!show ? 'primary' : undefined} className={`fab-hamburger ${show ? 'show' : ''}`} onClick={toggle}>
      <div className="wrapper">
        <span class="icon-bar" id="one"></span>
        <span class="icon-bar" id="two"></span>
        <span class="icon-bar" id="thr"></span>
      </div>
    </Button>
    <Button onClick={handleClick('refresh')} className={`fab-nav refresh-page ${show ? 'show' : ''}`}>
      <img src={refreshIcon} alt="refreshIcon" className='icon' />
    </Button>
    <Button color='primary' onClick={handleClick('new')} className={`fab-nav add-new ${show ? 'show' : ''}`}>
      <img src={addIcon} alt="addIcon" className='icon' />
    </Button>
    <div className={`fab-overlay ${show ? 'show' : ''}`}></div>
  </div>
}
