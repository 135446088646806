import React, { useReducer } from 'react';
import actions, { REDUCER_NEW_APT_ACTIONS } from './index.actions';

export const initialReducerData = {
  customer: {
    phone: '',
    name: '',
    id: null,
  },
  coupon: null,
  selectedServices: [],
  selectedStaff: null,
  isRequestStaff: false,
  timeSlot: null,
  selectedDate: new Date(),
  note: '',
  group: 1,
};

function reducer(state, action) {
  if (action.type === REDUCER_NEW_APT_ACTIONS.SET_CUSTOMER_INFO) {
    return actions.setCustomerInfo(state, action.payload);
  }
  if (action.type === REDUCER_NEW_APT_ACTIONS.SET_SERVICES) {
    return actions.setServices(state, action.payload);
  }
  if (action.type === REDUCER_NEW_APT_ACTIONS.SET_STAFF_DATE) {
    return actions.setStaffDate(state, action.payload);
  }
  if (action.type === REDUCER_NEW_APT_ACTIONS.SET_TIME_SLOT) {
    return actions.setTimeSlot(state, action.payload);
  }
  if (action.type === REDUCER_NEW_APT_ACTIONS.SET_NOTE) {
    return actions.setNote(state, action.payload);
  }
  if (action.type === REDUCER_NEW_APT_ACTIONS.SET_GROUP_VALUE) {
    return actions.setGroupValue(state, action.payload);
  }
  if (action.type === REDUCER_NEW_APT_ACTIONS.RESET) {
    return actions.reset(state);
  }
  throw Error('Unknown action.');
}

const useNewAptReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialReducerData);
  const actions = {
    setCustomerInfo: (payload) => dispatch({ type: REDUCER_NEW_APT_ACTIONS.SET_CUSTOMER_INFO, payload }),
    setServices: (payload) => dispatch({ type: REDUCER_NEW_APT_ACTIONS.SET_SERVICES, payload }),
    setStaffDate: (payload) => dispatch({ type: REDUCER_NEW_APT_ACTIONS.SET_STAFF_DATE, payload }),
    setTimeSlot: (payload) => dispatch({ type: REDUCER_NEW_APT_ACTIONS.SET_TIME_SLOT, payload }),
    setNote: (payload) => dispatch({ type: REDUCER_NEW_APT_ACTIONS.SET_NOTE, payload }),
    setGroupValue: (payload) => dispatch({ type: REDUCER_NEW_APT_ACTIONS.SET_GROUP_VALUE, payload }),
    reset: () => dispatch({ type: REDUCER_NEW_APT_ACTIONS.RESET }),
  };
  return ({
    state, actions,
  })
}

export default useNewAptReducer;
