import { all, call, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { API } from "common";
import mappingBlockHour from "views/calendar/CalendarUI/mappingBlockHour";
import mappingAptCalendarData from "views/calendar/mappingApt";

const getAptList = function* (params) {
  try {
    const response = yield call(API.get, `appointment/listAppointmentRealtime?${params}`);
    if (response.status == 200) {
      const { payload } = response.data;
      yield put(actions.getAptData.success(mappingAptCalendarData(payload.data || [])));
    }
  } catch (error) {
    console.log('getAptList error', error);

    yield put(actions.getAptData.fail([]));
  }
}

const getBlockHourList = function* (params) {
  try {
    const response = yield call(API.get, `appointment/blockHours?${params?.replace('start_time', 'startTime')?.replace('end_time', 'endTime')}`);
    if (response.status == 200) {
      const { payload } = response.data;
      yield put(actions.getBlockHourData.success(mappingBlockHour(payload || [])));
    }
  } catch (error) {
    console.log('getBlockHourList error', error);
    yield put(actions.getBlockHourData.fail([]));
  }
}

const getAptData = function* ({ payload }) {
  yield all([
    getAptList(payload),
    getBlockHourList(payload),
  ])
}

export default function* aptServiceSagas() {
  yield takeLatest(actions.getAptData.fetch, getAptData);
}
