import { createSlice } from '@reduxjs/toolkit';
import { NAME_REDUCER } from './constants';
import actions from './actions';
import moment from 'moment';

const initialState = {
  params: '',
  date: moment().format(),
  appointments: [],
  blockHours: [],
  loading: false,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {
    setDate: (state, { payload }) => {
      state.date = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(actions.getAptData.fetch, (state, { payload }) => {
        state.params = payload;
        state.loading = true;
      })
      .addCase(actions.getAptData.fail, (state) => {
        state.loading = false;
      })
      .addCase(actions.getAptData.success, (state, { payload }) => {
        state.appointments = payload;
        state.loading = false;
      })
      .addCase(actions.getBlockHourData.success, (state, { payload }) => {
        state.blockHours = payload;
      })
  },
});
export const aptUIActions = Slice.actions;
const aptServiceReducer = Slice.reducer;
export default aptServiceReducer;
