import React, { useEffect } from 'react'
import BookingPage from '.';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import shopActions from 'store/shop/actions';
import shopSelectors from 'store/shop/selectors';
import Loader from 'components/Loader';

const OnlineBooking = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(shopSelectors.shopLoading);

  useEffect(() => {
    if (!params.id) return;
    dispatch(shopActions.initWithApiKey(params.id));
  }, []);

  if (loading) return <Loader />;

  return (
    <BookingPage bookingOnline />
  )
}

export default OnlineBooking;
