import BottomSheet, { useBottomSheetRef } from 'components/BottomSheet';
import { first, remove } from 'lodash';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import shopSelectors from 'store/shop/selectors';
import { Category, Service } from 'views/new-appointment/booking/ServicesTab';
import Empty from "components/new-appointment/Empty";

const ServicePicker = forwardRef(({ }, ref) => {
  const bsRef = useBottomSheetRef();
  const callBackRef = useRef(() => undefined);
  const [services, setServices] = React.useState([]);

  const categories = useSelector(shopSelectors.categoriesAll);
  const [activeCategory, setActiveCategory] = useState(null);

  const handleChooseService = (ser) => () => {
    const temp = [...services];
    const exist = services.findIndex((o) => o.id?.toString() === ser.id?.toString());
    if (exist !== -1) {
      remove(temp, (o) => o.id?.toString() === ser.id?.toString());
    } else {
      temp.push(ser);
    }
    setServices(temp);
  };

  const handleDone = () => {
    callBackRef.current(services);
  }

  useImperativeHandle(ref, () => ({
    open: (_services = [], cb) => {
      bsRef.current.open();
      callBackRef.current = cb;
      setServices(_services);
      setActiveCategory(first(categories) || null);
    }
  }));

  return (
    <BottomSheet ref={bsRef} title={'Services'} maxHeightRatio={0.9}
      onOK={handleDone}
      className="new-appointment-wrapper new-appointment-wrapper-v2"
      header={
        <div
          className="new_appointment__categories container-box"
          id="scroll-container__categories"
          style={{ margin: '0 -16px', marginTop: '8px' }}
        >
          {categories?.map((o) => (
            <button
              key={o.id}
              id={o.id}
              type="button"
              className="button_category"
              onClick={() => {
                setActiveCategory(o);
              }}
            >
              <Category label={o.name} active={activeCategory?.id === o.id} />
            </button>
          ))}
        </div>
      }
    >
      {activeCategory?.services?.length ?
        <div className="new_appointment__services container-box">
          {activeCategory?.services?.map((o) => (
            <button
              key={o.id}
              type="button"
              className="button_service"
              onClick={handleChooseService(o)}
            >
              <Service
                data={o}
                active={!!services?.find((ser) => ser.id?.toString() === o.id?.toString())}
              />
            </button>
          ))}
        </div> : <Empty />}
      <div style={{ height: '10vh', width: '100%' }}></div>
    </BottomSheet >
  )
});
ServicePicker.displayName = 'ServicePicker';

export default ServicePicker;
