import { NEW_APT_STEPS } from "constant";
import moment from "moment";
import { reset } from "numeral";
import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { formatCurrency } from "utils/formatCurrency";
import useDebounce from "utils/useDebounce";

const ReviewTab = forwardRef(({
  note, setNote,
  group, setGroupValue,
  isRequestStaff,
  selectedServices,
  timeSlot,
  selectedDate,
  customer,
  selectedStaff,
  rollbackTo,
  isManager,
}, ref) => {
  const inputNoteRef = useRef(null);
  const groupRef = useRef(null);
  const dateMoment = moment(selectedDate || new Date());
  const onChangeNote = (text) => setNote(text);
  const onChangeGroup = (val) => setGroupValue(val);

  useImperativeHandle(ref, () => ({
    reset: () => {
      if (inputNoteRef.current) inputNoteRef.current.reset();
      if (groupRef.current) groupRef.current.reset();
    }
  }))

  return (
    <>
      <RadioGroups ref={groupRef} value={group} onChange={onChangeGroup} />
      <InputNote ref={inputNoteRef} defaultValue={note} onDebounce={onChangeNote} />
      <BoxContent title={"Date and Time"}
        icon={<IconStaffEdit />}
        onEdit={() => rollbackTo(NEW_APT_STEPS.TIME_SLOT)}>
        <p className="time">
          {dateMoment.format("ddd, MMM DD, YYYY")} {timeSlot}
        </p>
      </BoxContent>

      <div className="new_appointment__review_box_rows">
        <BoxContent
          title={"Customer Info"}
          icon={<IconCustomerEdit />}
          onEdit={() => rollbackTo(NEW_APT_STEPS.INFORMATION)}
        >
          <p>{customer?.name ? customer.name : 'Unknown'}</p>
          <p>{customer.phone}</p>
        </BoxContent>

        <BoxContent
          title={
            <div className="d-flex">
              Staff
              {isRequestStaff ? (
                <div className="request-staff-box">
                  <p>request</p>
                </div>
              ) : null}
            </div>
          }
          icon={isManager ? <IconStaffEdit /> : undefined}
          onEdit={isManager ? (() => rollbackTo(NEW_APT_STEPS.DATE_STAFF)) : undefined}
        >
          <div className="staff-box">
            {/* {selectedStaff?.avatar ? <img src={selectedStaff.avatar} alt={selectedStaff.avatar} className="staff-avatar" /> : <span/>} */}
            <img src={selectedStaff?.avatar} alt={selectedStaff?.avatar} className="staff-avatar" />
            <p>{selectedStaff?.name}</p>
          </div>
        </BoxContent>
      </div>

      <BoxContent
        title={"Services"}
        icon={<IconStaffEdit />}
        onEdit={() => rollbackTo(NEW_APT_STEPS.SERVICE)}
      >
        <div className="services-box">
          {selectedServices.map((o) => (
            <button className="service" key={o.id}>
              <p className="name">{o.name}</p>
              <p className="price">{formatCurrency(o.price)}</p>
            </button>
          ))}
        </div>
      </BoxContent>
    </>
  );
});

export default ReviewTab;

const BoxContent = ({ title, children, icon, onEdit }) => {
  return (
    <div className="new_appointment__review_box">
      <div className="new_appointment__review_box_top">
        {typeof title === "string" ? <p className="title">{title}</p> : title}
        {icon && <button onClick={onEdit}>{icon}</button>}
      </div>
      <div className="new_appointment__review_box_content">{children}</div>
    </div>
  );
};

const IconCustomerEdit = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.2421 9.21213C7.98492 9.21213 7.73827 9.31429 7.55642 9.49615C7.37457 9.678 7.2724 9.92465 7.2724 10.1818V23.7576C7.2724 24.0148 7.37457 24.2614 7.55642 24.4433C7.73827 24.6251 7.98492 24.7273 8.2421 24.7273H21.8179C22.075 24.7273 22.3217 24.6251 22.5035 24.4433C22.6854 24.2614 22.7875 24.0148 22.7875 23.7576V19.8788C22.7875 19.3432 23.2217 18.9091 23.7572 18.9091C24.2928 18.9091 24.7269 19.3432 24.7269 19.8788V23.7576C24.7269 24.5291 24.4205 25.2691 23.8749 25.8146C23.3293 26.3602 22.5894 26.6667 21.8179 26.6667H8.2421C7.47056 26.6667 6.73062 26.3602 6.18506 25.8146C5.6395 25.2691 5.33301 24.5291 5.33301 23.7576V10.1818C5.33301 9.41029 5.6395 8.67035 6.18506 8.12479C6.73062 7.57923 7.47056 7.27274 8.2421 7.27274H12.1209C12.6564 7.27274 13.0906 7.70688 13.0906 8.24243C13.0906 8.77798 12.6564 9.21213 12.1209 9.21213H8.2421Z"
      fill="#0085E7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9384 5.61736C21.3171 5.23867 21.9311 5.23867 22.3098 5.61736L26.3825 9.69009C26.7598 10.0674 26.7614 10.6786 26.3861 11.0578L17.174 20.3669C17.0023 20.5404 16.7716 20.6428 16.5278 20.6536L12.1641 20.8475C11.8885 20.8598 11.6207 20.7541 11.4278 20.5568C11.2349 20.3596 11.1352 20.0896 11.1535 19.8143L11.4444 15.4507C11.4601 15.2163 11.5602 14.9956 11.7263 14.8295L20.9384 5.61736ZM21.6241 7.6744L13.3553 15.9432L13.1607 18.8619L16.0624 18.733L24.329 10.3793L21.6241 7.6744Z"
      fill="#0085E7"
    />
  </svg>
);
const IconStaffEdit = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0455 9.94551C11.3015 8.66882 12.8524 7.88098 14.4746 7.57889L14.0699 5.33334C12.0137 5.71624 10.0459 6.71658 8.45749 8.33125C4.29151 12.566 4.29151 19.432 8.45749 23.6667L6.54056 25.6153H11.9625V20.1039L10.0455 22.0525C6.7566 18.7092 6.7566 13.2888 10.0455 9.94551ZM25.4588 6.38468H20.0369V11.8961L21.9538 9.94753C25.2428 13.2908 25.2427 18.7113 21.9538 22.0545C20.6979 23.3312 19.147 24.119 17.5247 24.4211L17.9294 26.6667C19.9856 26.2838 21.9534 25.2834 23.5419 23.6688C27.7078 19.434 27.7078 12.5681 23.5419 8.33327L25.4588 6.38468Z"
      fill="#0085E7"
    />
  </svg>
);

const InputNote = forwardRef(({ defaultValue, onDebounce }, ref) => {
  const [text, onChange, setText] = useDebounce(defaultValue, onDebounce);
  useImperativeHandle(ref, () => ({
    reset: () => {
      setText('');
    }
  }));

  return (
    <div className="input_kit note">
      <label>Appointment Notes</label>
      <textarea
        rows="3"
        value={text}
        onChange={onChange}
        placeholder="Please enter ..."
      />
    </div>
  );
});

const RadioGroups = forwardRef(({ value, onChange, minValue = 2, maxValue = 7 }, ref) => {
  const [isGroup, setIsGroup] = useState(false);

  const decrease = () => {
    const nextValue = value - 1;
    if (nextValue < minValue) return;
    onChange(nextValue);
  };
  const increase = () => {
    const nextValue = value + 1;
    if (maxValue && nextValue > maxValue) return;
    onChange(nextValue);
  };

  const suggestionClick = (_value) => (e) => {
    e.preventDefault();
    onChange(_value);
  };

  const handleChangeIsGroup = () => {
    const nextValue = !isGroup;
    onChange(nextValue ? 2 : 1);
    setIsGroup(nextValue);
  };

  useImperativeHandle(ref, () => ({
    reset: () => {
      setIsGroup(false);
    }
  }));

  return (
    <div className="new_appointment__group">
      <div>
        <div className="group-configure">
          <div>
            <button
              className="new_appointment__group_button"
              onClick={handleChangeIsGroup}
            >
              {isGroup ? <Checked /> : <Check />}
              <p>Group?</p>
            </button>
            {isGroup && (
              <p mt={4} variant="SUB_TEXT_SECTION">
                Enter amount or select
              </p>
            )}
          </div>
          {isGroup && (
            <div className="group-actions">
              <button onClick={(e) => [e.preventDefault(), decrease()]}>
                <IconMinus />
              </button>
              <p className="count" style={{ width: "24px" }}>
                {value}
              </p>
              <button onClick={(e) => [e.preventDefault(), increase()]}>
                <IconPlus />
              </button>
            </div>
          )}
        </div>
        {isGroup && (
          <div className="group-number-rows">
            <button onClick={suggestionClick(2)}>
              <NumberBox value={2} selected={value === 2} />
            </button>
            <button onClick={suggestionClick(3)}>
              <NumberBox value={3} selected={value === 3} />
            </button>
            <button onClick={suggestionClick(4)}>
              <NumberBox value={4} selected={value === 4} />
            </button>
            <button onClick={suggestionClick(5)}>
              <NumberBox value={5} selected={value === 5} />
            </button>
            <button onClick={suggestionClick(6)}>
              <NumberBox value={6} selected={value === 6} />
            </button>
            <button onClick={suggestionClick(7)}>
              <NumberBox value={7} selected={value === 7} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
});

const NumberBox = ({ value, selected }) => {
  return (
    <div className={`number-box ${selected ? "active" : ""}`}>
      <p className="text">{value}</p>
    </div>
  );
};

const Checked = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="5" fill="#0085E7" />
    <path
      d="M9.72692 17.4737C9.40676 17.4737 9.10261 17.3456 8.8785 17.1215L4.34817 12.5913C3.88394 12.127 3.88394 11.3587 4.34817 10.8944C4.8124 10.4302 5.58078 10.4302 6.04501 10.8944L9.72692 14.5762L17.955 6.34817C18.4192 5.88394 19.1876 5.88394 19.6518 6.34817C20.1161 6.8124 20.1161 7.58078 19.6518 8.04501L10.5753 17.1215C10.3512 17.3456 10.0471 17.4737 9.72692 17.4737Z"
      fill="white"
    />
  </svg>
);

const Check = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="24"
      height="24"
      rx="5"
      fill="#C9CDD4"
      stroke="#BEBFC3"
      strokeWidth={2}
    />
  </svg>
);

const IconMinus = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="minus">
        <rect
          id="Rectangle 34624222"
          x="0.5"
          y="1"
          width="31"
          height="31"
          rx="4.5"
          fill="white"
          stroke="#C9CDD4"
        />
        <path id="-" d="M22 14.5V17.3029H10V14.5H22Z" fill="#686868" />
      </g>
    </svg>
  );
};

const IconPlus = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Plus">
        <rect
          id="Rectangle 34624223"
          x="0.5"
          y="1"
          width="31"
          height="31"
          rx="4.5"
          fill="white"
          stroke="#C9CDD4"
        />
        <path
          id="+"
          d="M22 17.6294H17.2471V22.5H14.7529V17.6294H10V15.3706H14.7529V10.5H17.2471V15.3706H22V17.6294Z"
          fill="#686868"
        />
      </g>
    </svg>
  );
};
