import anyoneAvatar from 'assets/img/icons/anyone.png';
const SLIDE_DURATION = 4;
const MASK_PHONE_INPUT = "(999) 999-9999"
const TYPE_INCOME = ['DAY', 'WEEK', 'BI-WEEK']
const BTN_LOGIN_LIST = ['Sign-In', 'Get OTP', 'Verify OTP']
const REASONS_LIST = [
	{
		label: 'Sick',
		value: 'SICK'
	},
	{
		label: 'Family',
		value: 'FAMILY'
	},
	{
		label: 'Accident',
		value: 'ACCIDENT'
	},
	{
		label: 'Traffic',
		value: 'TRAFFIC'
	},
	{
		label: 'Other',
		value: 'OTHER'
	}
]
const SMILE_LIST = [
	{
		label: 'Terrible',
		file_name: 'tired-regular.svg',
		file_name_active: 'tired-regular-active.svg',
	},
	{
		label: 'Bad',
		file_name: 'frown-regular.svg',
		file_name_active: 'frown-regular-active.svg',
	},
	{
		label: 'Okay',
		file_name: 'meh-regular.svg',
		file_name_active: 'meh-regular-active.svg',
	},
	{
		label: 'Good',
		file_name: 'smile-regular.svg',
		file_name_active: 'smile-regular-active.svg',
	},
	{
		label: 'Great',
		file_name: 'grin-regular.svg',
		file_name_active: 'grin-regular-active.svg',
	}
]
const BOOKING_URL = 'https://booking.toshopo.com';
const SOCKET_URL = 'https://api.ontiloo.com/api/v1/gs-guide-websocket';

export const NEW_APT_STEPS = {
	INFORMATION: 'INFORMATION',
	SERVICE: 'SERVICE',
	DATE_STAFF: 'DATE_STAFF',
	TIME_SLOT: 'TIME_SLOT',
	REVIEW: 'REVIEW',
	SUCCESS: 'SUCCESS',
}
export const ANYONE_APPOINTMENT = {
	id: 'ANYONE_APPOINTMENT',
	name: 'Anyone',
	avatar: anyoneAvatar,
};

export default {
	SLIDE_DURATION,
	MASK_PHONE_INPUT,
	BTN_LOGIN_LIST,
	TYPE_INCOME,
	REASONS_LIST,
	SMILE_LIST,
	BOOKING_URL,
	SOCKET_URL,
	NEW_APT_STEPS,
};
