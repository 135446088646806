import React, { useState, useEffect } from 'react';

// reactstrap components
import {
	Container,
	Row,
	Col,
	Card,
	CardImg,
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
	Dropdown,
	DropdownMenu,
	DropdownToggle,
	DropdownItem,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import StarRatings from 'react-star-ratings';

import { API, getDataCookie, logOut, isAuthenticated } from 'common';

import TabNav from '../components/Navbars/TabNav';

export default function Index() {
	const [dropdownOpen, setDropdownOpen] = useState(false);

	// dropdown
	const toggle_dropdown = () => setDropdownOpen((prevState) => !prevState);

	let history = useHistory();
	const redirectLogin = () => {
		if (!isAuthenticated()) {
			history.push('/login');
		}
	};
	const staff_info = JSON.parse(localStorage.getItem('infor_staff'));
	return (
		<>
			<Helmet title="Home" />
			<div className='staff-home-dashboard-kit'>
				<div className="bg-header-home" />
				<div className='staff-header'>
					<div className="staff-name">
						<span>{staff_info && ("Hi " + staff_info.firstName + " " + staff_info.lastName)},</span>
					</div>
					<div className="text-right">
						<Dropdown isOpen={dropdownOpen} toggle={toggle_dropdown}>
							<DropdownToggle nav>
								<div className="float-left d-flex align-items-center">
									<img
										height={45}
										width={45}
										className="rounded-circle float-left mr-1"
										alt="..."
										src={staff_info && staff_info.urlImage ? staff_info.urlImage : require('assets/img/theme/team-4-800x800.jpg')}
									/>
									<StarRatings
										rating={staff_info?.star || 0}
										starRatedColor="#FFC31F"
										numberOfStars={5}
										starDimension={'20px'}
										starSpacing='0px'
										name='rating'
									/>
								</div>
							</DropdownToggle>
							<div
								style={{
									top: '32px',
									position: 'relative',
								}}
							>
								<DropdownMenu
									className="dropdown-menu-arrow"
									right
								>
									<DropdownItem
										onClick={() =>
											logOut(redirectLogin)
										}
									>
										<i
											className="fa fa-sign-out"
											aria-hidden="true"
										/>
										<span>Logout</span>
									</DropdownItem>
								</DropdownMenu>
							</div>
						</Dropdown>
					</div>
				</div>
				<TabNav />
			</div>
		</>
	);
};
