import React from 'react'
import { AppointmentItemMulti } from './AppointmentItem';
import { momentTimezone } from 'utils/time';
import { sortBy } from 'lodash';

const MultiAppointment = ({ data, onViewAppointmentDetail }) => {
  const appointments = React.useMemo(() => sortBy(data, o => momentTimezone(o.startTime)), [data]);
  return (
    <div className='multiple-appointment-item-container'>
      {appointments.map(o => (
        <AppointmentItemMulti key={o.id} data={o} multiple onViewDetail={onViewAppointmentDetail} />
      ))}
    </div>
  )
}

export default MultiAppointment;
