import React, { useContext } from "react";
import { initialReducerData } from "./index.reducer";

const AptContext = React.createContext({
  state: initialReducerData,
  actions: {
    setCustomerInfo: () => undefined,
    setServices: () => undefined,
    setStaffDate: () => undefined,
    setTimeSlot: () => undefined,
    setNote: () => undefined,
    setGroupValue: () => undefined,
    reset: () => undefined,
  }
});

export const useAptContext = () => useContext(AptContext);

export default AptContext;
