import BottomSheet, { useBottomSheetRef } from 'components/BottomSheet';
import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { EmptyBox, TimeSlotsBlock } from 'views/new-appointment/booking/DateTimeTab';
import { getTimeSlots } from 'views/new-appointment/hooks/index.data';
import Calendar from "components/new-appointment/Calendar";

const TimeSlotPicker = forwardRef(({ }, ref) => {
  const bsRef = useBottomSheetRef();
  const callBackRef = useRef(() => undefined);
  const [date, setDate] = useState(new Date());
  const [staffId, setStaffId] = useState('');
  const [loading, setLoading] = useState(false);
  const [timeSlots, setTimeSlots] = React.useState([]);
  const [timeSlot, setTimeSlot] = React.useState('');


  const handleChooseTimeSlot = (value) => {
    const newValue = moment(value, 'hh:mm A');
    const newDate = moment(date).set({
      hour: newValue.get('hour'),
      minute: newValue.get('minute'),
    });
    callBackRef.current(newDate.format('MM-DD-YYYY hh:mm A'));
    bsRef.current.close();
  };
  const handleChooseDate = (value) => {
    setDate(value);
    fetchTimeSlots(value, staffId);
  };

  const fetchTimeSlots = async (_date, _staffId) => {
    const dateStr = moment(_date).format("YYYY-MM-DD");
    setLoading(true);
    try {
      const _timeSlots = await getTimeSlots(
        _staffId == 0 ? null : _staffId,
        dateStr
      );
      if (_timeSlots) {
        setTimeSlots(_timeSlots || []);
      }
    } finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    open: (dateMoment, _staffId, cb) => {
      bsRef.current.open();
      setStaffId(_staffId);
      setDate(dateMoment.toDate());
      setTimeSlot(dateMoment.format('hh:mm A'));
      fetchTimeSlots(dateMoment.toDate(), _staffId);
      callBackRef.current = cb;
    }
  }));

  const TimeBox = () => {
    if (loading)
      return (
        <div className="d-flex align-items-center justify-content-center pt-4"><div className="spinner small-size"></div></div>
      );

    if (!timeSlots?.length) return <EmptyBox />;

    return (
      <div className='group-time-slots'>
        <TimeSlotsBlock
          label={"Morning"}
          selected={timeSlot}
          data={timeSlots.filter((o) => o.endsWith("AM"))}
          onChooseTimeSlot={handleChooseTimeSlot}
        />
        <TimeSlotsBlock
          selected={timeSlot}
          label={"Afternoon"}
          data={timeSlots.filter((o) => o.endsWith("PM"))}
          onChooseTimeSlot={handleChooseTimeSlot}
        />
      </div>
    )
  }
  return (
    <BottomSheet ref={bsRef} title={'Change Time slot'} maxHeightRatio={0.9}
      header={<div style={{ background: '#F6F7FC', height: '1px', margin: '0 -16px', marginTop: '8px' }}></div>}
    >
      <div className="new-appointment-wrapper new-appointment-wrapper-v2 check-staff-available-wrapper" style={{ height: 'auto', background: '#fff' }}>
        <div className="check-staff-available-wrapper__container">
          <Calendar value={date} onChange={handleChooseDate} />
          <div style={{ marginBottom: '100px' }}>
            <TimeBox />
          </div>
        </div>
      </div>
    </BottomSheet>
  )
});
TimeSlotPicker.displayName = 'TimeSlotPicker';

export default TimeSlotPicker;
