import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import { isAuthenticated } from 'common';

// reactstrap components
import { Card, CardBody, Col } from 'reactstrap';
import SignIn from 'components/Modal/SignIn';
import { Helmet } from 'react-helmet';

export default function Login({ location }) {
	const [loginState, setLogin] = useState(true);
	const { from } = location.state || { from: { pathname: '/' } };

	if (isAuthenticated()) {
		return <Redirect to={from} />;
	} else {
		return (
			<Col lg="5" md="7">
				<Helmet title="Sign in" />
				<Card className="border-0 fixed-bottom bg-transparent">
					<CardBody className="px-lg-5 py-lg-5 m-b">
						<SignIn loginState={loginState} setLogin={setLogin} />
					</CardBody>
				</Card>
			</Col>
		);
	}
}
