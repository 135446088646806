import React, { createRef, useImperativeHandle, useState } from 'react';

export const toastRef = createRef();

const ToastContainer = React.forwardRef(({ }, ref) => {
  const [visible, show] = useState(false);
  const [msg, setMsg] = useState('');
  const [type, setType] = useState('WARNING');

  useImperativeHandle(ref, () => ({
    show: (msg, timer = 3000) => {
      show(true);
      setMsg(msg);
      setType('WARNING');
      setTimeout(function () {
        show(false);
      }, timer);
    },
    success: (msg) => {
      show(true);
      setMsg(msg);
      setType('SUCCESS');
      setTimeout(function () {
        show(false);
      }, 3000);
    },
  }));

  return (
    <div id="snackbar" className={`${visible ? 'show' : ''} ${type}`}>{msg}</div>
  )
})

export default ToastContainer;
