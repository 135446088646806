import { createAction as toolkitCreateAction } from '@reduxjs/toolkit';

export const createAsyncAction = (type) => {
  return {
    fetch: toolkitCreateAction(type),
    fetchPaging: toolkitCreateAction(type + '_PAGING'),
    success: toolkitCreateAction(type + '_SUCCESS'),
    fail: toolkitCreateAction(type + '_FAIL'),
  };
};
export const createAction = toolkitCreateAction;
