import React from "react";
import { API } from "common";
import first from 'lodash/first';

const fetchCategories = async () => {
  try {
    const response = await API.get(`service/category/list`);
    if (response?.data?.payload) {
      return response.data.payload;
    }
    throw 'fail';
  } catch (error) {
    return null;
  }
};
const fetchServices = async () => {
  try {
    const response = await API.get(`service/list`);
    if (response?.data?.payload) {
      return response.data.payload;
    }
    throw 'fail';
  } catch (error) {
    return null;
  }
};

const fetchStaffs = async () => {
  try {
    const response = await API.get(`staff/list`);
    if (response?.data?.payload) {
      return response.data.payload;
    }
    throw 'fail';
  } catch (error) {
    return null;
  }
};

export const getCustomerInfoByPhone = async (phone) => {
  try {
    const response = await API.get('customer/getAllCustomerAutoSearchPage?keyWord=' + phone);
    if (response?.data?.payload?.customers?.length > 0) {
      return first(response.data.payload?.customers);
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const getCouponByQRcode = async (code) => {
  try {
    const response = await API.get('coupon/getCouponByQrcode?qrCode=' + code);
    if (response.data.payload) {
      return response.data.payload;
    }
    return null;
  } catch (error) {
    return null;
  }
}

export const getTimeSlots = async (staffId, currentDate) => {
  const params = { currentDate, staffId };
  if (!params.staffId) {
    delete params.staffId;
  }
  try {
    const response = await API.get('appointment/getAvailableTimeSlotByStaff', { params })
    if (response.data?.payload?.timeSlots) {
      return response.data?.payload?.timeSlots;
    }
    return null;
  } catch (error) {
    return null;
  }
}

export const makeAppointment = async (payload) => {
  const body = {
    type: 'FEEDBACK/DASHBOARD/ON_CREATE_APPOITMENT',
    payload
  }

  try {
    const response = await API.post('appointment/v2/bookingAppointment', body);
    if (response.data.payload) {
      return response.data.payload;
    }
    return null;
  } catch (error) {
    return error?.response?.data;
  }
}

const useFetchBookingData = () => {
  return async () => {
    return await Promise.all([fetchStaffs(), fetchCategories(), fetchServices()]);
  };
};

export default useFetchBookingData;
