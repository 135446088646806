import React, { useState, useEffect, useMemo } from 'react';
import constant from 'constant';
import { API, getDataCookie } from 'common';
// reactstrap components
import {
	FormGroup,
	Form,
	Modal,
	ModalBody,
	Label,
	Input
} from 'reactstrap';
import { toastRef } from 'components/Toast';
import { useAppSelector } from 'store/hooks';
import shopSelectors from 'store/shop/selectors';

export default function AssignAppointment({ setLoading = () => undefined, isOpenAssign, setIsOpenAssign, appointmentId, setAssignToSuccess }) {
	const [openTextArea, setoOenTextArea] = useState(false);
	const staffId = getDataCookie(process.env.REACT_APP_COOKIE_KEY);
	const staffs = useAppSelector(shopSelectors.staff);
	const staffsList = useMemo(() => staffs.map(o => o.extraData).filter(o => o.positionName === 'Admin'), [staffs]);

	const onSubmit = async (e) => {
		e.preventDefault();
		onClose();
		setLoading(true);
		try {
			let staff_id_to = e.target.staff.value;
			let note = '';
			let reason = e.target.reasons.value;
			if (reason == constant.REASONS_LIST[4].value) {
				note = e.target.reason_other.value;
			}

			let response = await API.post('appointment/assignToStaff', {
				payload: {
					appointmentId: appointmentId,
					staffIdFrom: staffId,
					staffIdTo: staff_id_to,
					type: reason,
					note: note
				}
			});

			if (response.status == 200) {
				if (toastRef.current) toastRef.current.success('Success');
			}

		} catch (error) {
			console.error('AssignTo Submit', error);
		} finally {
			setLoading(false);
		}
	};

	const onClose = () => setIsOpenAssign(false);

	const close_text_area_handle = (e) => {
		if (e.target.value == 'OTHER') {
			setoOenTextArea(true);
		}
		else {
			setoOenTextArea(false);
		}
	}
	return (
		<>
			<Modal
				className="fixed-bottom m-0"
				size="lg"
				isOpen={isOpenAssign}
				toggle={onClose}
			>
				<ModalBody>
					<h2 style={{}}>Ticket:<span style={{ color: '#3778EC' }}>{`#${appointmentId && appointmentId}`}</span></h2>
					<Form role="form" onSubmit={onSubmit} className="mt-2" >
						<FormGroup>
							<Label for="assign_user_select" className="label-assign-appoint">Assign to:</Label>
							<Input type="select" name="staff" id="assign_user_select" style={{ color: '#2C2C2C' }}>
								{
									staffsList.map((staff, index) => (
										<option key={index} value={staff.id}>{staff.firstName + staff.lastName}</option>
									))
								}
							</Input>
						</FormGroup>
						<FormGroup>
							<Label for="reasons_select" className="label-assign-appoint">Reasons</Label>
							<Input type="select" name="reasons" id="reasons_select" style={{ color: '#2C2C2C' }} onChange={close_text_area_handle}>
								{
									constant.REASONS_LIST.map((item, index) => (
										<option key={index} value={item.value}>{item.label}</option>
									))
								}
							</Input>
						</FormGroup>
						{
							openTextArea ? (
								<FormGroup>
									<Input type="textarea" name="text" id="reason_other" style={{ color: '#2C2C2C' }} />
								</FormGroup>
							)
								: ''
						}
						<div className="text-center">
							<button type="submit" class="btn btn-block btn btn-primary" style={{ fontSize: '16px', fontWeight: '600', backgroundColor: '#3778EC' }}>Submit</button>
						</div>
					</Form>
				</ModalBody>
			</Modal>
		</>
	);
}
