import React from 'react'

const Container = ({ children }) => (
  <div className='new_appointment__header' style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.15)' }}>
    <p className="title">BOOKING</p>
    <div className='actions'>{children}</div>
  </div>
);
const Header = ({ back = true, onBack, onNext, onBook, onDone, disabled = false, onReview }) => {
  const BackButton = () => <button className="button btn-back" type='button' onClick={onBack}>BACK</button>;
  const NextButton = () => onNext ? <button disabled={disabled} className="button btn-next" type='button' onClick={onNext}>NEXT</button> : null;
  const BookButton = () => onBook ? <button disabled={disabled} className="button btn-next btn-book" type='button' onClick={onBook}>BOOK</button> : null;
  const DoneButton = () => onDone ? <button disabled={disabled} className="button btn-next btn-done" type='button' onClick={onDone}>DONE</button> : null;
  const ReviewButton = () => onReview ? <button disabled={disabled} className="button btn-next btn-done" type='button' onClick={onReview} style={{ margin: 0 }}>Review</button> : null;
  return (
    <Container>
      {back && <BackButton />}
      <NextButton />
      <BookButton />
      <DoneButton />
      <ReviewButton />
    </Container>
  )
}

export default Header;
