import moment, { Moment } from 'moment';

export const setTime = (hour, minute, second) => moment().set({ hour, minute: minute || 0, second: second || 0 });
export const setTimeWithDate = (dateStr = moment().format(), hour, minute) => moment(dateStr).set({ hour, minute: minute || 0, second: 0 });

const getTimeLines = (startTime, endTime, distance = 15) => {
  const _distance = distance || 30;
  const times = [];
  let time = startTime;

  while (time <= endTime) {
    times.push({
      id: time.format('HH:mm'),
      value: time,
    });
    time = time.clone().add(_distance, 'minute');
  }
  return times;
};

export default getTimeLines;
