import { API } from 'common';
import Loader from 'components/Loader';
import Page from 'components/Page';
import { toastRef } from 'components/Toast';
import { sumBy } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Button, Input } from 'reactstrap';
import { formatCurrency } from 'utils/formatCurrency';
import { formatPhone, maskPhone } from 'utils/formatPhone';
import { uuid } from 'uuidv4';
import ModalConfirm from 'widgets/ModalConfirm';
import ModalReason from 'widgets/ModalReason';
import ServicePicker from 'widgets/ServicePicker';
import { StaffBSPicker } from 'widgets/StafFilterInput';
import TimeSlotPicker from 'widgets/TimeSlotPicker';
import anyoneIcon from './Icon/anyone.svg';
import closeIcon from './Icon/close.svg';
import editIcon from './Icon/edit.svg';
import AssignAppointment from 'components/Modal/AssignAppointment';

const AppointmentStatus = {
  NEW: '0',
  CONFIRMED: '1',
  CHECK_IN: '2',
  FINISH: '3',
  CANCEL: '4',
  DELETED: '5',
}
const DetailAppointmentV2 = ({ staffUsed }) => {
  const _history = useHistory();
  const modalConfirm = React.useRef(null);
  const staffPicker = useRef(null);
  const timePicker = useRef(null);
  const servicePicker = useRef(null);
  const reasonRef = useRef(null);
  const goBack = () => staffUsed ? _history.goBack() : _history.push('/appointments');
  const [isOpenAssign, setIsOpenAssign] = useState(false);

  const { id } = useParams();
  const [detail, setDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [note, setNote] = useState('');
  const readOnly = staffUsed || detail?.status === AppointmentStatus.CANCEL;
  const [services, setServices] = useState([]);
  const [staff, setStaff] = useState(null);
  const [strStartDate, setStrStartDate] = useState('');

  const get_detail_appointment = async (hideLoading) => {
    if (!hideLoading)
      setLoadingPage(true);
    try {
      let response = await API.get(`appointment/getAppointmentById/${id}`);
      if (response.status == 200) {
        let { payload } = response.data;
        setDetail(payload || null);
        setNote(payload.note || '');
        setServices((payload.services || []).map(o => ({
          ...o,
          uuid: uuid(),
        })));
        setStaff({ id: payload.staffId, name: payload.name, avatar: payload.avatar });
        setStrStartDate(payload.strStartDate || '');
      }
    } catch (error) {
      console.error('get_detail_appointment');
    } finally {
      if (!hideLoading)
        setLoadingPage(false);
    }
  }
  useEffect(() => {
    get_detail_appointment()
  }, []);

  const handleChangeStaff = () => {
    if (!staffPicker.current) return;
    staffPicker.current.open(staff.id, (_staff) => {
      setStaff({ id: _staff.id, name: _staff.name, avatar: _staff.avatar });
      const dateMoment = moment(strStartDate, 'MM-DD-YYYY hh:mm A');
      timePicker.current.open(dateMoment, _staff.staffId, setStrStartDate);
    });
  };
  const handleChangeTime = () => {
    if (!timePicker.current) return;
    const dateMoment = moment(strStartDate, 'MM-DD-YYYY hh:mm A');
    timePicker.current.open(dateMoment, staff?.id, setStrStartDate);
  };

  const handleAddService = () => {
    if (!servicePicker.current) return;
    servicePicker.current.open(services.map(o => ({
      duration: o.duration,
      id: o.id?.toString(),
      price: o.price,
      name: o.serviceName,
    })), (sers = []) => {
      const temp = [];
      sers.map(ser => {
        temp.push({
          duration: ser.duration,
          id: +ser.id,
          price: ser.price,
          serviceName: ser.name,
          uuid: uuid(),
        });
      });
      setServices(temp);
    })
  };

  const handleRemoveService = (o) => () => {
    const temp = [...services];
    setServices(temp.filter(ser => ser.uuid !== o.uuid));
  }

  const confirmApt = async () => {
    setLoading(true);
    try {
      let res = await API.put(`appointment/update-status`, { appointmentId: id, status: 1 });
      if (res.status === 200) {
        if (toastRef.current) toastRef.current.success('Updated!');
        goBack();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleProlongedTime = (status) => async () => {
    setLoading(true);
    try {
      let res = await API.post(`appointment/prolongedTime`, { payload: { appointmentId: id, status } });
      if (res.status === 200) {
        if (toastRef.current) toastRef.current.success('Updated!');
        await get_detail_appointment(true);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const deleteApt = async () => {
    if (!modalConfirm.current) return;
    modalConfirm.current.open({
      title: 'DELETE APPOINTMENT',
      message: 'You are about to delete the appointment?',
    }, async () => {
      setLoading(true);
      try {
        let res = await API.delete(`appointment/deleteAppointment/${id}`);
        if (res.status === 200) {
          goBack();
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    })
  };

  const cancelApt = async () => {
    if (!reasonRef.current) return;
    reasonRef.current.open(async (reason) => {
      setLoading(true);
      try {
        let res = await API.put(`appointment/update-status`, { appointmentId: id, status: 4, reason });
        if (res.status === 200) {
          if (toastRef.current) toastRef.current.success('Updated!');
          goBack();
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    });
  };

  const handleUpdate = async () => {
    const totalDuration = sumBy(services.map(o => o.duration || 0));

    const startTime = moment(strStartDate, 'MM-DD-YYYY hh:mm A');
    const endTime = startTime.clone().add(totalDuration, 'minute');

    const _startTimeStr = startTime.format("MM/DD/YYYY HH:mm");
    let _endTimeStr = endTime.format('MM/DD/YYYY HH:mm');

    if (_endTimeStr === _startTimeStr) {
      _endTimeStr = endTime.add(15, 'minute').format('MM/DD/YYYY HH:mm');
    }

    const payload = {
      id,
      serviceIds: services.map(o => o.id),
      staffId: staff?.id || 0,
      startTime: _startTimeStr,
      endTime: _endTimeStr,
      note,
      isAnybody: !staff?.id ? 1 : 0,
      apiKey: detail.shop.apiKey,
    };
    setLoading(true);
    try {
      let res = await API.put(`appointment/updateAppointment`, {
        payload,
        type: "FEEDBACK/DASHBOARD/ON_EDIT_APPOITMENT",
      });
      if (res.status === 200) {
        if (toastRef.current) toastRef.current.success('Updated!');
        goBack();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const StatusBox = useMemo(() => {
    switch (detail?.status) {
      case AppointmentStatus.NEW:
        return <Button className="status-box" color="primary" ><span>NEW</span></Button>;
      case AppointmentStatus.CANCEL:
        return <Button className="status-box cancel"><span>CANCELED</span></Button>;
      case AppointmentStatus.DELETED:
        return <Button className="status-box cancel"><span>DELETED</span></Button>;
      case AppointmentStatus.CONFIRMED:
        return <Button className="status-box confirm" color="success"><span>CONFIRMED</span></Button>;
      case AppointmentStatus.FINISH:
        return <Button className="status-box confirm" color="primary" outline><span>FINISHED</span></Button>;
      default:
        return null;
    }
  }, [detail]);

  const StickFooter = () => {
    const disabledStaff = _history.location?.state?.disabled;
    const CancelButton = <Button onClick={cancelApt} type='button' className="btn btn-cancel" color='secondary'>Cancel</Button>;
    const CancelButtonDisabled = <Button disabled type='button' className="btn btn-cancel" color='secondary'>Canceled</Button>;
    const ConfirmButton = <Button onClick={confirmApt} type='button' className="btn btn-confirm" color="success" >Confirm</Button>;
    const DeleteButton = <Button onClick={deleteApt} type='button' className="btn btn-delete">Delete</Button>;
    const UpdateButton = <Button onClick={handleUpdate} type='button' className="btn btn-update" color="primary" >Update</Button>;
    const MinusButton = <Button disabled={detail.duration <= 15} onClick={handleProlongedTime('MINUS')} type='button' className="btn btn-cancel" color='secondary'>- 15 minutes</Button>;
    const AddButton = <Button onClick={handleProlongedTime('ADD')} type='button' className="btn btn-update" color="primary">+ 15 minutes</Button>;

    if (staffUsed) {
      // if (disabledStaff) return null;
      return <>
        <div className='sticky-footer-container add-minus-container' style={{ borderBottom: 'none' }}>
          {MinusButton} <span className='duration-label'>({detail.duration}m)</span> {AddButton}
        </div>
        <div className='sticky-footer-container add-minus-container'>
          <Button onClick={() => setIsOpenAssign(true)} type='button' className="btn btn-cancel" color="secondary">Assign To</Button>
        </div>
      </>
    }

    switch (detail?.status) {
      case AppointmentStatus.CONFIRMED:
        return (
          <div className='sticky-footer-container'>
            {DeleteButton} {UpdateButton}
          </div>
        );
      case AppointmentStatus.NEW:
        return (
          <div className='sticky-footer-container'>
            {CancelButton} {ConfirmButton} {DeleteButton} {UpdateButton}
          </div>
        );
      case AppointmentStatus.CANCEL:
        return (
          <div className='sticky-footer-container'>
            {DeleteButton} {CancelButtonDisabled}
          </div>
        );
      case AppointmentStatus.FINISH:
      case AppointmentStatus.DELETED:
      case AppointmentStatus.CHECK_IN:
      default:
        return null;
    }
  };

  if (loadingPage) return <Loader />;
  if (!detail) return <Page title={'Not found'}>
    <p>404 Not Found</p>
  </Page>;

  return (
    <Page
      title={'Detail Appointment'}
      containerClass={'detail-appointment-wrapper'}
      StickFooter={StickFooter}
    >
      {loading && <Loader />}
      <div className="detail-appointment-content-container">
        <div className="status-container">
          {StatusBox}
        </div>
        <div className="detail-appointment-content-group">
          <div className="section-label"><span>Customer</span></div>
          <div className="customer-container">
            <div className="customer-name">{detail.customerName}</div>
            <div className='customer-phone'>{staffUsed ? maskPhone(detail.customerPhone) : formatPhone(detail.customerPhone)}</div>
          </div>
        </div>

        <div className="detail-appointment-content-group">
          <div className="section-label"><span>Booking Information</span></div>
          <div className="booking-information-container">
            <div className="information-row-content">
              <span className='label'>Staff</span>
              <div className="staff-container">
                <img className="staff-avatar" src={staff?.avatar || anyoneIcon} alt={'staff-avatar'} />
                <span className="value">{staff?.name || 'Anyone'}</span>
              </div>
              {!readOnly && <Button onClick={handleChangeStaff} className='btn-icon'>{iconEdit}</Button>}
            </div>

            <div className="line" />
            <div className="information-row-content">
              <span className='label'>Date</span>
              <span className='value'>{strStartDate || '--'}</span>
              {!readOnly && <Button onClick={handleChangeTime} className='btn-icon'>{iconEdit}</Button>}
            </div>
            <div className="line" />
            <div className="note-container">
              <div className="information-row-content">
                <span className='label'>{detail?.status === AppointmentStatus.CANCEL ? 'Reason' : 'Note'}</span>
                {readOnly && <span className='value'>{note || '--'}</span>}
              </div>
              {!readOnly && <Input type='textarea' value={note} onChange={e => setNote(e.target.value)} />}
            </div>
          </div>
        </div>

        <div className="detail-appointment-content-group" style={{ borderBottom: 'none' }}>
          <div className="service-top-action-container">
            <div className="section-label"><span>Services</span></div>
            {!readOnly && <div className="btn-actions">
              <Button onClick={handleAddService} className='btn-icon'><span style={{ color: '#0085E7', fontSize: 26, fontWeight: '300' }}>+</span></Button>
            </div>}
          </div>
          <div className="service-group-container">
            {services?.map((o) => (
              <div key={o.uuid} className='service-item-container'>
                <div className='service-box'>
                  <p className="name">{o.serviceName}</p>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: readOnly ? 0 : 16 }}>
                    <p className="price">{formatCurrency(o.price)}</p>
                    <p className="price">({o.duration}m)</p>
                  </div>
                </div>
                {!readOnly && <Button onClick={handleRemoveService(o)} className='btn-icon'>{iconClose}</Button>}
              </div>
            ))}
          </div>
        </div>
      </div>
      <StaffBSPicker ref={staffPicker} />
      <TimeSlotPicker ref={timePicker} staffId={staff?.id} />
      <ServicePicker ref={servicePicker} />
      <ModalReason ref={reasonRef} />
      <ModalConfirm ref={modalConfirm} />
      <AssignAppointment
        isOpenAssign={isOpenAssign}
        setIsOpenAssign={setIsOpenAssign}
        appointmentId={id}
        setLoading={setLoading}
      />
    </Page>
  )
}
export const DetailAppointmentForStaff = () => <DetailAppointmentV2 staffUsed />;
export default DetailAppointmentV2;

const iconEdit = <img src={editIcon} alt='editIcon' className={'icon-edit'} />
const iconClose = <img src={closeIcon} alt='closeIcon' className={'icon-close'} />