import Calendar from "components/new-appointment/Calendar";
import Page from 'components/Page';
import { ANYONE_APPOINTMENT } from 'constant';
import { first } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Input, } from 'reactstrap';
import shopSelectors from 'store/shop/selectors';
import { EmptyBox, TimeSlotsBlock } from '../booking/DateTimeTab';
import { getTimeSlots } from '../hooks/index.data';
const CheckAvailableStaff = () => {
  const _history = useHistory();
  const [staffId, setStaffId] = useState();
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [timeSlots, setTimeSlots] = React.useState([]);
  const staffs = useSelector(shopSelectors.staff);

  useEffect(() => {
    setStaffId(first(staffs)?.id || '');
  }, [staffs]);

  const handleChooseTimeSlot = (value) => {
    _history.push(`/booking?staff_id=${staffId}&time_slot=${value.replace(' ', '_')}`);
  };

  const fetchTimeSlots = async (_date, _staffId) => {
    const dateStr = moment(_date).format("YYYY-MM-DD");
    setLoading(true);
    try {
      const _timeSlots = await getTimeSlots(
        _staffId == ANYONE_APPOINTMENT.id ? null : _staffId,
        dateStr
      );
      if (_timeSlots) {
        setTimeSlots(_timeSlots || []);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { fetchTimeSlots(date, staffId); }, [date, staffId]);

  const TimeBox = () => {
    if (loading)
      return (
        <div className="d-flex align-items-center justify-content-center pt-4"><div className="spinner small-size"></div></div>
      );

    if (!timeSlots?.length) return <EmptyBox />;

    return (
      <div className='group-time-slots'>
        <TimeSlotsBlock
          label={"Morning"}
          data={timeSlots.filter((o) => o.endsWith("AM"))}
          onChooseTimeSlot={handleChooseTimeSlot}
        />
        <TimeSlotsBlock
          label={"Afternoon"}
          data={timeSlots.filter((o) => o.endsWith("PM"))}
          onChooseTimeSlot={handleChooseTimeSlot}
        />
      </div>
    )
  }

  return (
    <Page title={'Check Available'} containerClass={'new-appointment-wrapper new-appointment-wrapper-v2 check-staff-available-wrapper'} >
      <div className="check-staff-available-wrapper__container">
        <Calendar value={date} onChange={setDate} />
        <div>
          <div className="text-label">Staff</div>
          <Input value={staffId} onChange={e => setStaffId(e.target.value)} type="select" style={{ color: '#2C2C2C' }}>
            {staffs.map((staff, index) => (
              <option key={index} value={staff.id}>{staff.name}</option>
            ))}
          </Input>
        </div>
        <div>
          <TimeBox />
        </div>
      </div>
    </Page>
  )
}

export default CheckAvailableStaff;
