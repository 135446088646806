import { flatten, get, uniq } from "lodash";
import React, { useMemo } from "react";
import { momentTimezone } from "utils/time";
import getTimeLines, { setTime, setTimeWithDate } from "./helpers/getTimeLines";
import moment from "moment";
import { useAppSelector } from "store/hooks";
import aptSelectors from "store/appt/selectors";

const useMappingCalendarData = (schema, data = []) => {
  const date = useAppSelector(aptSelectors.date);
  const rowTimesMaster = React.useMemo(() => {
    return getTimeLines(setTimeWithDate(date, schema.timeHourStart, schema.timeMinuteStart), setTimeWithDate(date, schema.timeHourEnd, schema.timeMinuteEnd), schema.distanceTimeline);
  }, [date]);
  
  const rowsSpanInfo = useMemo(() => {
    const rowsSpan = schema.headers.map(col => {
      const listFilter = data.filter(order => {
        if (momentTimezone(order.startTime)?.format('MM-DD-YYYY') !== schema.dateFilter?.format('MM-DD-YYYY')) return false;
        return schema.filterAppointments(order, col);
      });

      if (listFilter.length === 0) return;
      const mapping = rowTimesMaster.map(rowTime => {
        const obSetTime = { hour: rowTime.value.get('hour'), minute: rowTime.value.get('minute'), second: 0 };
        const orderList = listFilter.filter(order => {
          const orderTimeStart = momentTimezone(order.startTime);
          const orderTimeFinish = momentTimezone(order.endTime);
          const time = orderTimeStart.clone().set(obSetTime);
          const currentNextTime = time.clone().add(schema.distanceTimeline, 'minute');
          const isBetween = orderTimeStart.isBetween(time, currentNextTime);
          const isOrderTimeFinishValid = time.isSameOrAfter(orderTimeStart) && orderTimeFinish.subtract(1, 'minute').isSameOrAfter(time);
          return isBetween || isOrderTimeFinishValid;
        }).map(o => ({
          id: o.id,
          rowTimeId: rowTime.value.format('HH:mm'),
          timeStart: momentTimezone(o.startTime),
          timeStartLabel: momentTimezone(o.startTime).format('HH:mm'),
          timeEnd: momentTimezone(o.endTime),
          timeEndLabel: momentTimezone(o.endTime).format('HH:mm'),
        }));

        return ({
          orderList: orderList.map(o => o.id.toString()),
          rowId: rowTime.id,
          rowValue: rowTime.value,
        });
      });

      const spam = [];
      mapping.forEach((data, index) => {
        const prevOrders = get(mapping, [index - 1, 'orderList'], []);
        const orderList = data.orderList ?? [];
        if (orderList.length === 0) return;
        const isContinue = orderList.some(o => prevOrders.includes(o));
        if (!isContinue) {
          spam.push({
            rowStart: { id: data.rowId, value: data.rowValue.clone() },
            rowEnd: { id: data.rowId, value: data.rowValue.clone() },
            orderIds: [...data.orderList],
            rowId: data.rowId,
            colId: col.id,
            rowSpan: 1,
          });
        } else {
          const current = spam.find(o => !!orderList.find(k => o.orderIds.includes(k)));
          if (current) {
            current.orderIds = uniq([...current.orderIds, ...orderList]);
            current.rowEnd = { id: data.rowId, value: data.rowValue.clone() };
          }
        }
      });
      return spam;
    }).filter(o => !!o);

    const rowsSpanInfoTemp = flatten(rowsSpan);
    const rowsSpanInfo = rowsSpanInfoTemp.map(o => {

      return ({
        ...o,
        rowSpan: (o.rowEnd.value.diff(o.rowStart.value, 'minutes') / schema.distanceTimeline) + 1
      });
    });

    return rowsSpanInfo;
  }, [rowTimesMaster, schema.headers, data, schema.dateFilter]);

  const rows = useMemo(() => {
    return rowTimesMaster.map((rowTime) => {
      const colData = schema.headers.map((col) => {
        const listFilter = data.filter(order => {
          return schema.filterAppointments(order, col);
        });
        const rowInfo = rowsSpanInfo.find(o => rowTime.id === o.rowId && o.colId === col.id);
        const bookingDataFilter = listFilter.filter(o => rowInfo?.orderIds.includes(o.id.toString()));
        const obSetTime = { hour: rowTime.value.get('hour'), minute: rowTime.value.get('minute'), second: 0 };
        let rowTimeValue = moment(date).set(obSetTime);
        
        return ({
          id: col.id,
          data: bookingDataFilter,
          rowTime: rowTimeValue,
          nextRowTime: rowTimeValue.clone().add(schema.distanceTimeline, 'minute').format('hh-mm-A'),
          headerData: col,
        });
      }).filter((col) => {
        const rowInfo = rowsSpanInfo.filter(o => o.colId === col.id);
        return !rowInfo.some(o => o.rowSpan > 1 && (
          rowTime.value.isBetween(o.rowStart.value, o.rowEnd.value) ||
          rowTime.value.isSame(o.rowEnd.value)
        ));
      });
      
      return {
        id: rowTime.id,
        rowTime: rowTime.value,
        nextRowTime: rowTime.value.clone().add(schema.distanceTimeline, 'minute').format('hh-mm-A'),
        colData,
      };
    });
  }, [rowTimesMaster, rowsSpanInfo, schema.headers, date, schema.filterAppointments, data]);

  return ({
    rows,
    rowsSpanInfo,
    data,
  });
};

export default useMappingCalendarData;
