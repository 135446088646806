import React from 'react';
import { Route, Switch } from 'react-router-dom';
// reactstrap components
import { Container, Row } from 'reactstrap';
import { Helmet } from 'react-helmet';
import routes from 'routes.js';
import constant from 'constant.js';
import BackgroundSlider from 'react-background-slider';
function importAll(r) {
	return r.keys().map(r);
}

const imagesSlide = importAll(
	require.context('assets/img/slide', false, /\.(png|jpe?g)$/)
);
class Auth extends React.Component {
	componentDidMount() {
		document.body.classList.add('bg-cover');
	}
	getRoutes = (routes) =>
		routes.map(
			(prop, key) =>
				prop.layout === 'auth' && (
					<Route
						path={prop.path}
						component={prop.component}
						key={key}
					/>
				)
		);
	render() {
		return (
			<>
				<Helmet titleTemplate={process.env.REACT_APP_NAME + ' | %s'} />
				{/* <div className="bg-login"></div> */}
				<h1 id="title-login"><span style={{ color: '#FF8300'}}>iStaff</span> <span style={{ color: '#FFF'}}>Management</span></h1>
				<BackgroundSlider
					images={imagesSlide}
					duration={99999999}
					transition={0}
				/>
				<div className="main-content">
					{/* Page content */}
					<Container>
						<Row className="justify-content-center">
							<Switch>{this.getRoutes(routes)}</Switch>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

export default Auth;
