
const getCurrentState = (state) => state['apt'];

const aptSelectors = {
  appointments: (state) => getCurrentState(state).appointments,
  blockHours: (state) => getCurrentState(state).blockHours,
  params: (state) => getCurrentState(state).params,
  date: (state) => getCurrentState(state).date,
  loading: (state) => getCurrentState(state).loading,
};

export default aptSelectors;
