import React, { useCallback, useMemo } from 'react'
import moment from 'moment';
import CouponContainer from './CouponContainer';
import Percent from './Percent';
import Money from './Money';
import QRCode from "react-qr-code";
const DATE_FORMAT = 'MM-DD-YYYY'

const Coupon = ({ data, active }) => {
  const isLarge = useMemo(() => {
    if (data.discountValue > 9) return true;
    return false;
  }, [data]);

  const BottomContent = useCallback(() => {
    return (
      <div className='bottom-content-styled'>
        <div className='d-flex align-items-center justify-content-between' >
          <div className='list-description'>
            <ul>
              {
                data.description.split('+')?.map((text, i) => (
                  <li key={i} >{text?.trim()}</li>
                ))
              }
            </ul>
          </div>
          <div className='d-flex justify-content-center' style={{ width: '85px' }}>
            <QRCode color='black' bgColor='#fff' size={50} value={data?.couponCode} />
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-between expire-group'>
          <p className='expire_date'>Expires Until:  <b>{data.endDate ? moment(data.endDate).format(DATE_FORMAT) : '--'}</b></p>
          <div className='couponCode'>{data.couponCode}</div>
        </div>
      </div>
    );
  }, [data]);

  if (data.discountType === 1) {
    return (
      <div className='coupon_kit'>
        <CouponContainer active={active}>
          <div className={`coupon-container-wrap-styled ${isLarge ? 'isLarge' : ''}`}>
            <div className='coupon-ui-styled'>
              <Percent data={data} isLarge={isLarge} />
              <div className='title-styled'>{data.couponName}</div>
            </div>
            <BottomContent />
          </div>
        </CouponContainer>
      </div>
    );
  }

  return (
    <div className='coupon_kit'>
      <CouponContainer active={active}>
        <div className={`coupon-container-wrap-styled ${isLarge ? 'isLarge' : ''}`}>
          <div className='coupon-ui-styled'>
            <Money data={data} isLarge={isLarge} />
            <div className='title-styled'>{data.couponName}</div>
          </div>
          <BottomContent />
        </div>
      </CouponContainer>
    </div>
  )
}

export default Coupon;
