import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Button } from 'reactstrap';

const ModalConfirm = forwardRef(({ }, ref) => {
  const [open, setOpen] = React.useState(false);
  const [configs, setConfigs] = React.useState({
    title: 'Are you sure?',
    message: 'You are about to delete the appointment',
  });
  const callBackRef = useRef(() => undefined);

  const handleClose = () => setOpen(false);
  const handleDone = () => {
    callBackRef.current();
    setOpen(false);
  }

  useImperativeHandle(ref, () => ({
    open: (_configs, cb) => {
      setConfigs(_configs);
      callBackRef.current = cb;
      setOpen(true);
    }
  }));

  if (!open) return null;
  return (
    <div className='modal-confirm-kit'>
      <div className="modal-confirm-box">
        <div className="modal-confirm-box-header">
          <span>{configs.title}</span>
        </div>
        <div className="modal-confirm-box-body">
          <span>{configs.message}</span>
        </div>
        <div className="modal-confirm-box-footer">
          <Button outline className='outline' onClick={handleClose}>Close</Button>
          <Button color='primary' onClick={handleDone}>Yes</Button>
        </div>
      </div>
    </div>
  )
});
ModalConfirm.displayName = 'ModalConfirm';

export default ModalConfirm;
