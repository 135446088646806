import { NEW_APT_STEPS } from 'constant';
import { useHistory } from 'react-router-dom';
import DateStaff from './date-staff';
import useNewAppointmentHook from './hooks/index.hook';
import Information from './information';
import Review from './review';
import Service from './service';
import Success from './success';
import TimeSlot from './time-slot';
import React, { createRef, useEffect } from 'react';
import AptContext from './hooks/index.context';
import Cookies from 'js-cookie';
export let stateNewAppointmentRef = createRef();
export let appointmentPath = createRef('/new-appointment/');

export const NewAppointmentContext = ({ children, isAdmin = false }) => {
  const hook = useNewAppointmentHook();

  useEffect(() => {
    stateNewAppointmentRef.current = {
      isBackToReview: false,
    }
  }, []);

  return (
    <AptContext.Provider value={hook.context}>
      {children}
    </AptContext.Provider>
  )
};

const NewAppointmentContainer = ({ View }) => {
  const _history = useHistory();
  const getPath = (step) => {
    switch (step) {
      case NEW_APT_STEPS.SUCCESS: return 'success';
      case NEW_APT_STEPS.SERVICE: return 'service';
      case NEW_APT_STEPS.DATE_STAFF: return 'date-staff';
      case NEW_APT_STEPS.TIME_SLOT: return 'time-slot';
      case NEW_APT_STEPS.REVIEW: return 'review';
      case NEW_APT_STEPS.INFORMATION:
      default: return '';
    }
  };

  const go = (step) => {
    let path = getPath(step);
    if (stateNewAppointmentRef.current)
      stateNewAppointmentRef.current.isBackToReview = false;
    _history.push('/new-appointment/' + path, undefined);
  };

  const rollbackTo = (step) => {
    let path = getPath(step);
    if (stateNewAppointmentRef.current)
      stateNewAppointmentRef.current.isBackToReview = true;
    _history.push('/new-appointment/' + path);
  };
  const back = () => _history.goBack();

  const home = () => {
    if (!!+Cookies.get('isManager')) {
      return _history.push('/appointments');
    }
    return _history.push('/');
  };

  return (
    <div className='new-appointment-wrapper'>
      <View
        go={go}
        rollbackTo={rollbackTo}
        back={back}
        home={home}
      />
    </div>
  );
};

const getNewAptRoute = (step, admin) => {
  let View = () => null;
  let path = '';
  switch (step) {
    case NEW_APT_STEPS.SUCCESS: {
      View = Success;
      path = 'success';
      break;
    }
    case NEW_APT_STEPS.SERVICE: {
      View = Service;
      path = 'service';
      break;
    }
    case NEW_APT_STEPS.DATE_STAFF: {
      View = DateStaff;
      path = 'date-staff';
      break;
    }
    case NEW_APT_STEPS.TIME_SLOT: {
      View = TimeSlot;
      path = 'time-slot';
      break;
    }
    case NEW_APT_STEPS.REVIEW: {
      View = Review;
      path = 'review';
      break;
    }
    case NEW_APT_STEPS.INFORMATION:
    default:
      View = Information;
      path = '';
  }

  const component = () => <NewAppointmentContainer View={View} />;
  return ({
    path: '/new-appointment/' + path,
    name: 'New Appointment',
    component,
    layout: 'admin',
    isDisplayHeader: false,
    isDisplayNav: false,
    isManager: !!+Cookies.get('isManager'),
  })
}

export default getNewAptRoute;
