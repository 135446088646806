import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import { API, getDataCookie } from 'common';
import MyDatePicker from 'components/DatePicker';
import MyDateRangePicker from 'components/DatePicker/Range';
import Loader from 'components/Loader';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';
import {
	Button,
	Col,
	Container,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane
} from 'reactstrap';
import StaffFilterInput from 'widgets/StafFilterInput';
import FilterOptions from 'components/FilterOptions';
import Empty from 'components/new-appointment/Empty';
import { momentTimezone } from 'utils/time';
import { useHistory } from 'react-router-dom';
import { formatPhone } from 'utils/formatPhone';
import Page from 'components/Page';
import calendarIcon from 'views/calendar/CalendarUI/icons/grid.svg';

const FILTER_OPT = {
	ALL: 'ALL',
	NON_REQUEST_STAFF: 'NON_REQUEST_STAFF',
	REQUEST_STAFF: 'REQUEST_STAFF',
}
const getTime = (date) => date ? date.slice(date.length - 8, date.length) : '--:--';
const FORMAT_DATE = 'MM-DD-YYYY';
const datePrefixLabel = {
	[moment().format(FORMAT_DATE)]: 'Today, ',
	[moment().subtract(1, 'day').format(FORMAT_DATE)]: 'Yesterday, ',
	[moment().add(1, 'day').format(FORMAT_DATE)]: 'Tomorrow, ',
};

export default function Appointments() {
	const _history = useHistory();
	const tab_appointment_info = JSON.parse(localStorage.getItem('manager_active_tab_appointment'));

	const range_paramRef = useRef('');
	const staff_idRef = useRef('');

	const [filter, setFilter] = useState(FILTER_OPT.ALL);
	const [appointments, setAppointmentsList] = useState([]);
	const [activeTab, setActiveTab] = useState((tab_appointment_info && tab_appointment_info.type) ? tab_appointment_info.type : 'DAY');
	const [loading, setLoading] = useState(false);

	const toggle = (tab) => {
		let range_param = '';
		const now = new Date();
		switch (tab) {
			case 'WEEK': {
				const dateMoment = moment(now);
				const start_date = dateMoment.startOf('week').format('MM-DD-YYYY');
				const end_date = dateMoment.endOf('week').format('MM-DD-YYYY');
				range_param = `startDate=${start_date}&endDate=${end_date}`;
				break;
			}
			case 'MONTH': {
				const dateMoment = moment(now);
				const start_date = dateMoment.startOf('month').format('MM-DD-YYYY');
				const end_date = dateMoment.endOf('month').format('MM-DD-YYYY');
				range_param = `startDate=${start_date}&endDate=${end_date}`;
				break;
			}
			case 'DAY':
			default: {
				const dateMoment = moment(now).format('MM-DD-YYYY');
				range_param = `startDate=${dateMoment}&endDate=${dateMoment}`;
				break;
			}
		}
		fetchDataApt(range_param);
		if (activeTab !== tab) setActiveTab(tab);
	};


	const fetchDataApt = async (range_param, staff_id) => {

		setLoading(true);
		try {
			staff_idRef.current = '';
			range_paramRef.current = range_param;
			let query = range_param;
			if (staff_id) {
				query += '&staffId=' + staff_id;
				staff_idRef.current = staff_id;
			}

			let response = await API.get(`admin/appointments?type=FROM-TO&${query}`);
			if (response.status == 200) {
				let { payload } = response.data;
				if (payload && payload.appointments) {
					setAppointmentsList(payload.appointments);
				}
			}
		} catch (error) {
			console.error('get_appointments_list', error);
			setAppointmentsList([]);
		} finally {
			setLoading(false);
		}
	};

	const onFilterWithStaff = (staff) => {
		fetchDataApt(range_paramRef.current, staff ? staff.id : null);
	};

	const handleRefresh = () => {
		fetchDataApt(range_paramRef.current, staff_idRef.current);
	}

	useEffect(() => {
		toggle('DAY');
	}, []);

	const appointmentsList = useMemo(() => {
		return appointments?.filter(o => {
			if (filter === FILTER_OPT.REQUEST_STAFF) return !!o.requestStaff;
			if (filter === FILTER_OPT.NON_REQUEST_STAFF) return !o.requestStaff;
			return true;
		}) || [];
	}, [appointments, filter]);

	const mappingData = useMemo(() => {
		const obj = {};
		appointmentsList.map(o => {
			const date = o.dateAppointment?.split(' ')?.[0];
			if (date) {
				const arr = obj[date];
				if (arr) {
					arr.push(o);
				} else {
					obj[date] = [o];
				}
			}
		});

		return Object.entries(obj).map(([key, value]) => ({
			group_id: key,
			data: value,
			dateStr: moment(key, 'MM-DD-YYYY').format('ddd, MM-DD-YYYY'),
		}));
	}, [appointmentsList]);

	const makeAppointment = (e) => {
		e.preventDefault();
		_history.push('/booking');
	}

	const checkAvailable = (e) => {
		e.preventDefault();
		_history.push('/check-available-booking');
	}


	const aptOptions = useMemo(() => {
		const total = appointments?.length || 0;
		const totalRequestStaff = appointments.filter(o => !!o.requestStaff)?.length || 0;
		const rest = total - totalRequestStaff;

		return ([
			{ label: `All Appointment (${total})`, value: FILTER_OPT.ALL },
			{ label: `Appointment (${rest})`, value: FILTER_OPT.NON_REQUEST_STAFF },
			{ label: `Appointment Request (${totalRequestStaff})`, value: FILTER_OPT.REQUEST_STAFF },
		])
	}, [appointments]);

	const Appointments = () => {
		if (!appointmentsList?.length) return <Empty message={'no data'} />;

		return (
			<div className="appointments_groups">
				{mappingData.map(o => (
					<div key={o.group_id} className='appointments_group'>
						<p className="date_label">{o.dateStr}</p>
						<div className="appointments_list">
							{o.data.map((item) => {
								let color = {
									background: '#F6F7FC',
									status: '#0085e7'
								};
								if (item.status === 'CONFIRMED') {
									color.background = '#bec2001a';
									color.status = '#bec200';
								}
								else if (item.status === 'CANCEL') {
									color.background = '#de13201a';
									color.status = '#de1320';
								}

								return (
									<Fragment key={item.appointmentId}>
										<Link to={`/appointment/${item.appointmentId}`}>
											<div className='appointment_item' style={{ backgroundColor: `${color.background}` }}>
												<div className="w-100  align-items-center">
													<div className="appointment_item_time d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
														<span>{item.start_time?.slice(0, 10)?.replaceAll('/', '-') || '--'}</span>  <span>{getTime(item.start_time)} - {getTime(item.end_time)}</span>
													</div>
													<div className="d-flex align-items-center" style={{ gap: '8px' }}>
														<div style={{ flex: 1 }}>
															<b style={{ color: `${color.status}` }}>{item.status}</b>
														</div>
														<div className="appointment_item_ticket_number">
															#{item.appointmentId}
														</div>
													</div>
												</div>

												<div className="d-flex w-100 align-items-center appointment_item_staff_box">
													<div className="staff_name">{item.staffName?.toLowerCase() || 'Anyone'}</div>
													{!!item.requestStaff && <div className="staff_request"><span>Request</span></div>}
												</div>

												<div className="d-flex w-100 align-items-center appointment_item_customer">
													<div className="customer_name text-ellipsis">{item.customerName || 'Unknown Customer'} - </div>
													<div className='customer_phone' style={{ whiteSpace: 'nowrap' }}>{formatPhone(item.phone)}</div>
												</div>

												<div className="w-100">
													{item.services?.map(o => (
														<div key={o?.id} className="d-flex align-items-center w-100 justify-content-between appointment_item_service_item">
															<div>{o?.serviceName || '--'}</div>
															<div>({o?.duration || 0}m)</div>
														</div>
													))}
												</div>
											</div>
										</Link>
									</Fragment>
								)
							})}
						</div>
					</div>
				))}
			</div>
		);
	}

	const Header = () => (
		<Nav tabs className="overview-custom-nav custom-nav-light">
			<NavItem className="tab-item">
				<NavLink
					className={classnames({ active: activeTab === 'DAY' })}
					onClick={() => toggle('DAY')}
				>
					TODAY
				</NavLink>
			</NavItem>
			<NavItem className="tab-item">
				<NavLink
					className={classnames({ active: activeTab === 'WEEK' })}
					onClick={() => toggle('WEEK')}
				>
					WEEKLY
				</NavLink>
			</NavItem>
			<NavItem className="tab-item">
				<NavLink
					className={classnames({ active: activeTab === 'RANGE' })}
					onClick={() => toggle('RANGE')}
				>
					RANGE
				</NavLink>
			</NavItem>
		</Nav>
	);

	const StickFooter = () => (
		<Row style={{ padding: '4px 15px', background: '#f0f0f0c4', paddingBottom: '8px' }}>
			<div style={{ margin: '0 15px', width: '100%', display: 'flex', gap: 8 }}>
				<Button className="w-100" color="primary" style={{ fontSize: 14, margin: 0, padding: '0.625rem 0rem' }} outline onClick={checkAvailable}>Check Available</Button>
				<Button className="w-100" color="primary" style={{ fontSize: 14, margin: 0, padding: '0.625rem 0rem' }} onClick={makeAppointment}>Book Appointment</Button>
			</div>
		</Row>
	);

	const rightButton = {
		icon: calendarIcon,
		onClick: () => _history.push('/calendar'),
	}
	return (
		<>
			{loading && <Loader />}
			<Page menu title="Appointments" Sticky={Header} StickFooter={StickFooter} rightButton={rightButton}>
				<Container className="overview m-0 px-0 pt-0" style={{ paddingBottom: '60px' }}>
					<TabContent activeTab={activeTab} style={{ color: "#000000", backgroundColor: '#F3F3F3' }} className="pt-2">
						<TabPane tabId="DAY">
							<div style={{ padding: '16px', paddingTop: 8 }}>
								<div className="d-flex align-items-center" style={{ gap: '24px' }}>
									<MyDatePicker
										daily
										stringData={fetchDataApt}
										unsetMaxDate
										style={{ flex: 1 }}
										customRender={({ icon, date }) => {
											return (
												<div className='date_picker_appointment' >
													<span>{datePrefixLabel?.[date?.format(FORMAT_DATE)] || `${date?.format("ddd")}, `}{date?.format(FORMAT_DATE)}</span>
													{icon}
												</div>)
										}}
									/>
									<FilterOptions active={filter !== FILTER_OPT.ALL} modalTitle='Appointment' value={filter} onChange={setFilter} options={aptOptions} />
								</div>
								<div style={{ marginBottom: '12px' }} />
								<StaffFilterInput onFilterWith={onFilterWithStaff} />
							</div>
							<div className='appointments_container'>
								<div className="d-flex align-items-center justify-content-between" style={{ marginBottom: 0 }}>
									<p className="counting_label" style={{ margin: 0 }}>{aptOptions.find(o => o.value === filter)?.label || 'ALL Appointment'}</p>
									<Button size='sm' onClick={handleRefresh}>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path fill-rule="evenodd" clip-rule="evenodd" d="M6.41799 6.32391C7.59544 5.12701 9.04939 4.38841 10.5702 4.1052L10.1908 2C8.26318 2.35897 6.41836 3.29678 4.92921 4.81053C1.0236 8.78064 1.0236 15.2175 4.92921 19.1876L3.13208 21.0144H8.21511V15.8474L6.41799 17.6742C3.33462 14.5399 3.33462 9.4582 6.41799 6.32391ZM20.8679 2.98563H15.7849V8.1526L17.582 6.3258C20.6654 9.4601 20.6654 14.5418 17.582 17.6761C16.4046 18.873 14.9506 19.6116 13.4298 19.8948L13.8092 22C15.7368 21.641 17.5816 20.7032 19.0708 19.1895C22.9764 15.2194 22.9764 8.78254 19.0708 4.81243L20.8679 2.98563Z" fill="#2D3043" />
										</svg>
									</Button>
								</div>
								<Appointments key={'DAY'} />
							</div>
						</TabPane>
						<TabPane tabId="WEEK" >
							<div style={{ padding: '16px', paddingTop: 8 }}>
								<div className="d-flex" style={{ gap: '24px' }}>
									<MyDatePicker
										weekly
										style={{ flex: 1 }}
										stringData={fetchDataApt}
										customRender={({ icon, start, end }) => {
											return (
												<div className='date_picker_appointment week' >
													<div className="d-flex align-items-center mb-1">
														<div className="label">Appointment week</div>
														{icon}
													</div>
													<div className="from_to_date">
														From: <span>{start?.format(FORMAT_DATE)}</span> - To: <span>{end.format(FORMAT_DATE)}</span>
													</div>
												</div>)
										}}
									/>
									<FilterOptions active={filter !== FILTER_OPT.ALL} modalTitle='Appointment' value={filter} onChange={setFilter} options={aptOptions} />
								</div>
								<div style={{ marginBottom: '12px' }} />
								<StaffFilterInput onFilterWith={onFilterWithStaff} />
							</div>
							<div className='appointments_container'>
								<div className="d-flex align-items-center justify-content-between" style={{ marginBottom: 0 }}>
									<p className="counting_label" style={{ margin: 0 }}>{aptOptions.find(o => o.value === filter)?.label || 'ALL Appointment'}</p>
									<Button size='sm' onClick={handleRefresh}>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path fill-rule="evenodd" clip-rule="evenodd" d="M6.41799 6.32391C7.59544 5.12701 9.04939 4.38841 10.5702 4.1052L10.1908 2C8.26318 2.35897 6.41836 3.29678 4.92921 4.81053C1.0236 8.78064 1.0236 15.2175 4.92921 19.1876L3.13208 21.0144H8.21511V15.8474L6.41799 17.6742C3.33462 14.5399 3.33462 9.4582 6.41799 6.32391ZM20.8679 2.98563H15.7849V8.1526L17.582 6.3258C20.6654 9.4601 20.6654 14.5418 17.582 17.6761C16.4046 18.873 14.9506 19.6116 13.4298 19.8948L13.8092 22C15.7368 21.641 17.5816 20.7032 19.0708 19.1895C22.9764 15.2194 22.9764 8.78254 19.0708 4.81243L20.8679 2.98563Z" fill="#2D3043" />
										</svg>
									</Button>
								</div>
								<Appointments key={'WEEK'} />
							</div>
						</TabPane>

						<TabPane tabId="RANGE">
							<div style={{ padding: '16px', paddingTop: 8 }}>
								<MyDateRangePicker
									className="appointment_range_date_picker"
									title='Appointment range'
									stringData={fetchDataApt}
									rightButton={<FilterOptions active={filter !== FILTER_OPT.ALL} modalTitle='Appointment' value={filter} onChange={setFilter} options={aptOptions} />}
								/>
								<div style={{ marginBottom: '12px' }} />
								<StaffFilterInput onFilterWith={onFilterWithStaff} />
							</div>
							<div className='appointments_container'>
								<div className="d-flex align-items-center justify-content-between" style={{ marginBottom: 0 }}>
									<p className="counting_label" style={{ margin: 0 }}>{aptOptions.find(o => o.value === filter)?.label || 'ALL Appointment'}</p>
									<Button size='sm' onClick={handleRefresh}>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path fill-rule="evenodd" clip-rule="evenodd" d="M6.41799 6.32391C7.59544 5.12701 9.04939 4.38841 10.5702 4.1052L10.1908 2C8.26318 2.35897 6.41836 3.29678 4.92921 4.81053C1.0236 8.78064 1.0236 15.2175 4.92921 19.1876L3.13208 21.0144H8.21511V15.8474L6.41799 17.6742C3.33462 14.5399 3.33462 9.4582 6.41799 6.32391ZM20.8679 2.98563H15.7849V8.1526L17.582 6.3258C20.6654 9.4601 20.6654 14.5418 17.582 17.6761C16.4046 18.873 14.9506 19.6116 13.4298 19.8948L13.8092 22C15.7368 21.641 17.5816 20.7032 19.0708 19.1895C22.9764 15.2194 22.9764 8.78254 19.0708 4.81243L20.8679 2.98563Z" fill="#2D3043" />
										</svg>
									</Button>
								</div>
								<Appointments key={'RANGE'} />
							</div>
						</TabPane>
					</TabContent>
				</Container>
			</Page>
		</>
	);
}
