import React from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
const isManager = Cookies.get(process.env.REACT_APP_COOKIE_KEY_ISMANAGER);

export default function ErrorPage() {
	return (
		<div className="page-wrap d-flex flex-row align-items-center">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-12 text-center">
						<span className="display-1 d-block">404</span>
						<div className="mb-4 lead">
							The page you are looking for was not found.
						</div>
						<Link to={(isManager != undefined && isManager == 1) ? '/overview' : '/'} className="btn btn-link">
							Back to Home
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
