
export const REDUCER_NEW_APT_ACTIONS = {
  INIT_SHOP_DATA: 'INIT_SHOP_DATA',
  SET_CUSTOMER_INFO: 'SET_CUSTOMER_INFO',
  SET_SERVICES: 'SET_SERVICES',
  SET_STAFF_DATE: 'SET_STAFF_DATE',
  SET_TIME_SLOT: 'SET_TIME_SLOT',
  SET_NOTE: 'SET_NOTE',
  SET_GROUP_VALUE: 'SET_GROUP_VALUE',
  RESET: 'RESET',
};
 
const setCustomerInfo = (state, payload) => ({
  ...state,
  customer: {
    phone: payload?.phone,
    name: payload?.name,
    id: payload?.id,
  },
  coupon: payload?.coupon || null,
});

const setServices = (state, payload) => ({
  ...state,
  selectedServices: payload,
});

const setStaffDate = (state, payload) => ({
  ...state,
  selectedStaff: payload.staff,
  selectedDate: payload.date,
  isRequestStaff: payload.isRequestStaff,
});

const setTimeSlot = (state, payload) => ({
  ...state,
  timeSlot: payload,
});

const setNote = (state, payload) => ({
  ...state,
  note: payload,
});

const setGroupValue = (state, payload) => ({
  ...state,
  group: payload,
});

const reset = (state) => ({
  ...state,
  customer: {
    phone: '',
    name: '',
    id: null,
  },
  coupon: null,
  selectedServices: [],
  selectedStaff: null,
  isRequestStaff: false,
  timeSlot: null,
  selectedDate: new Date(),
  note: '',
  group: 1,
})

const actions = { setCustomerInfo, setServices, setStaffDate, setTimeSlot, setNote, setGroupValue, reset };

export default actions;
