import React from 'react';
const Money = ({ isLarge, data }) => {
  return (
    <div className='top-money-styled' >
      <div className={`dollar-box ${isLarge ? 'isLarge' : ''}`}>$</div>
      <div className={`money-box ${isLarge ? 'isLarge' : ''}`}>{data.discountValue}</div>
      <div className={`money-content ${isLarge ? 'isLarge' : ''}`}>
        <div className="top">
          <span className='money-suffix'>00</span>
          <span className='money-off'>OFF</span>
        </div>
        <div className="coupon-name">{data.couponName}</div>
      </div>
    </div>
  );
};

export default Money;
