import { first, last } from 'lodash';
import moment from 'moment';
import DatePicker from "react-datepicker";
import React, { useState } from 'react';
import selectWeek from 'utils/getDaysOfWeek';

const OverviewPicker = ({ type, stringData }) => {
  const calendarRef = React.createRef();
  const [today, setToday] = useState(new Date());
  const [weekDate, setWeekDate] = useState(new Date());
  const [monthDate, setMonthDate] = useState(new Date());

  const props = (() => {
    if (type === 'MONTH') {
      return ({
        maxDate: new Date(),
        popperPlacement: "top-end",
        selected: monthDate,
        onChange: async (_date) => {
          setMonthDate(_date);
          const dateMoment = moment(_date);
          const start_date = dateMoment.startOf('month').format('MM-DD-YYYY');
          const end_date = dateMoment.endOf('month').format('MM-DD-YYYY')
          const range_param = `startDate=${start_date}&endDate=${end_date}`;
          await stringData(range_param);
        },
        withPortal: true,
        showMonthYearPicker: true,
        customInput:
          <div className='overview_kit__summary_report_date' onClick={() => { calendarRef.current.setOpen(true) }}>
            Report month {moment(monthDate).format('MMMM')}
            <CalendarIcon />
          </div>
      });
    }

    if (type === 'WEEK') {
      const arrayDate = selectWeek(weekDate);
      return ({
        calendarClassName: 'week_picker_kit',
        withPortal: true,
        showWeekNumbers: true,
        showWeekPicker: true,
        selected: weekDate,
        onChange: async (_date) => {
          setWeekDate(_date);
          const dateMoment = moment(_date);
          const start_date = dateMoment.startOf('week').format('MM-DD-YYYY');
          const end_date = dateMoment.endOf('week').format('MM-DD-YYYY')
          const range_param = `startDate=${start_date}&endDate=${end_date}`;
          await stringData(range_param);
        },
        highlightDates: arrayDate,
        customInput:
          <div className='overview_kit__summary_report_date week' onClick={() => { calendarRef.current.setOpen(true) }}>
            <p className='title'>Report week</p>
            <div className="picker">
              From: {moment(first(arrayDate)).format('MM-DD-YYYY')} - To: {moment(last(arrayDate)).format('MM-DD-YYYY')}
              <CalendarIcon />
            </div>
          </div>
      });
    }

    if (type === 'DAY') {
      return ({
        maxDate: new Date(),
        popperPlacement: "top-end",
        selected: today,
        onChange: async (_date) => {
          setToday(_date);
          const dateMoment = moment(_date).format('MM-DD-YYYY');
          const range_param = `startDate=${dateMoment}&endDate=${dateMoment}`;
          await stringData(range_param);
        },
        withPortal: true,
        customInput:
          <div className='overview_kit__summary_report_date' onClick={() => { calendarRef.current.setOpen(true) }}>
            Report date {moment(today).format('MM-DD-YYYY')}
            <CalendarIcon />
          </div>
      });
    }
    return {};
  })();

  return (
    <div className="d-flex align-items-center justify-content-center" style={{ marginBottom: '8px' }}>
      <DatePicker
        ref={calendarRef}
        {...props}
      />
    </div>
  )
};

export default OverviewPicker;

export const CalendarIcon = () => (
  <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="calendar">
      <path id="Vector" d="M17 3H3C1.89543 3 1 3.89543 1 5V19C1 20.1046 1.89543 21 3 21H17C18.1046 21 19 20.1046 19 19V5C19 3.89543 18.1046 3 17 3Z" stroke="#686868" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path id="Vector_2" d="M14 1V5" stroke="#686868" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path id="Vector_3" d="M6 1V5" stroke="#686868" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path id="Vector_4" d="M1 9H19" stroke="#686868" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </svg>
);
