import Header from "components/new-appointment/Header";
import { NEW_APT_STEPS } from "constant";
import React, { useEffect, useState } from "react";
import { useAptContext } from "./hooks/index.context";
import Title from "components/new-appointment/Title";
import ContentContainer from "components/new-appointment/ContentContainer";
import first from "lodash/first";
import remove from "lodash/remove";
import { formatCurrency } from "utils/formatCurrency";
import Empty from "components/new-appointment/Empty";
import shopSelectors from "store/shop/selectors";
import { useSelector } from "react-redux";

const ServiceAppointment = (props) => {
  const context = useAptContext();
  const categories = useSelector(shopSelectors.categories);
  const [services, setServices] = useState(context.state.selectedServices);

  const [activeCategory, setActiveCategory] = useState(null);

  useEffect(() => {
    const firstCate = first(categories);
    if (firstCate) setActiveCategory(firstCate);
  }, [categories]);

  useEffect(() => {
    const container = document.getElementById("scroll-container__categories");

    function scrollToItem(item) {
      item.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start'
      });
    }

    const items = container.querySelectorAll(".button_category");
    items.forEach(function (item) {
      item.addEventListener("click", function () {
        scrollToItem(item);
      });
    });
  }, []);

  const handleChooseService = (ser) => () => {
    const temp = [...services];
    const exist = services.findIndex((o) => o.id === ser.id);
    if (exist !== -1) {
      remove(temp, (o) => o.id === ser.id);
    } else {
      temp.push(ser);
    }
    setServices(temp);
  };

  const onNext = () => {
    context.actions.setServices(services);
    props.go(NEW_APT_STEPS.DATE_STAFF);
  };

  return (
    <>
      <Header disabled={!services?.length} onNext={onNext} />
      <ContentContainer noPadding>
        <Title step={NEW_APT_STEPS.SERVICE} />
        <div
          className="new_appointment__categories container-box"
          id="scroll-container__categories"
        >
          {categories?.map((o) => (
            <button
              key={o.id}
              id={o.id}
              type="button"
              className="button_category"
              onClick={() => {
                setActiveCategory(o);
              }}
            >
              <Category label={o.name} active={activeCategory?.id === o.id} />
            </button>
          ))}
        </div>
        {activeCategory?.services?.length ?
          <div className="new_appointment__services container-box">
            {activeCategory?.services?.map((o) => (
              <button
                key={o.id}
                type="button"
                className="button_service"
                onClick={handleChooseService(o)}
              >
                <Service
                  data={o}
                  active={!!services.find((ser) => ser.id === o.id)}
                />
              </button>
            ))}
          </div> : <Empty />}
      </ContentContainer>
    </>
  );
};

export default ServiceAppointment;

const Category = ({ label, active }) => {
  return (
    <div className={`new_appointment__category ${active ? "active" : ""}`}>
      <div>{label}</div>
    </div>
  );
};

const Service = ({ data, active }) => {
  return (
    <div className={`new_appointment__service ${active ? "active" : ""}`}>
      <img className="serviceImage" src={data.image} alt={data.name} />
      <div className="flex-column ">
        <div className="service-row-content">
          <div className="serviceTitle">{data.name}</div>
          {active && (
            <div className="checked">
              <SelectedSvg />
            </div>
          )}
        </div>
        <div className="servicePrice">{formatCurrency(data.price)}</div>
      </div>
    </div>
  );
};

const SelectedSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="5" fill="#0085E7" />
    <path
      d="M9.72692 17.4737C9.40676 17.4737 9.10261 17.3456 8.8785 17.1215L4.34817 12.5913C3.88394 12.127 3.88394 11.3587 4.34817 10.8944C4.8124 10.4302 5.58078 10.4302 6.04501 10.8944L9.72692 14.5762L17.955 6.34817C18.4192 5.88394 19.1876 5.88394 19.6518 6.34817C20.1161 6.8124 20.1161 7.58078 19.6518 8.04501L10.5753 17.1215C10.3512 17.3456 10.0471 17.4737 9.72692 17.4737Z"
      fill="white"
    />
  </svg>
);

