import { createAsyncAction } from 'store/actionConfigs';
import { PREFIX_ACTIONS } from './constants';

const getAptData = createAsyncAction(PREFIX_ACTIONS + 'getAptData');
const getBlockHourData = createAsyncAction(PREFIX_ACTIONS + 'getBlockHourData');

const aptActions = {
  getAptData,
  getBlockHourData,
};

export default aptActions;

