import { first, last } from 'lodash';
import moment from 'moment';
import DatePickerKit from "react-datepicker";
import React, { useState } from 'react';
import selectWeek from 'utils/getDaysOfWeek';

const MyDatePicker = ({
  daily = true, weekly = false, monthly = false,
  stringData, customRender, unsetMaxDate, style,
  startFieldName = 'startDate',
  endFieldName = 'endDate',
  format = 'MM-DD-YYYY',
  yesterday = false,
  onChange = () => undefined,
  value,
}) => {
  const calendarRef = React.createRef();
  const [today, setToday] = useState(value ? value.toDate() : (yesterday ? moment().subtract(1, 'day').toDate() : new Date()));
  const [weekDate, setWeekDate] = useState(new Date());
  const [monthDate, setMonthDate] = useState(new Date());

  const props = (() => {
    if (monthly) {
      return ({
        maxDate: new Date(),
        popperPlacement: "top-end",
        selected: monthDate,
        onChange: async (_date) => {
          setMonthDate(_date);
          const dateMoment = moment(_date);
          const start_date = dateMoment.startOf('month').format(format);
          const end_date = dateMoment.endOf('month').format(format)
          const range_param = `${startFieldName}=${start_date}&${endFieldName}=${end_date}`;
          await stringData(range_param);
        },
        withPortal: true,
        showMonthYearPicker: true,
        customInput:
          customRender ?
            customRender({ onClick: () => { calendarRef.current.setOpen(true) }, date: moment(monthDate), icon: <CalendarIcon /> })
            :
            <div className='date_picker_kit_report_date' onClick={() => { calendarRef.current.setOpen(true) }}>
              Report month {moment(monthDate).format('MMMM')}
              <CalendarIcon />
            </div>
      });
    }

    if (weekly) {
      const arrayDate = selectWeek(weekDate);
      return ({
        calendarClassName: 'week_picker_kit',
        withPortal: true,
        showWeekNumbers: true,
        showWeekPicker: true,
        selected: weekDate,
        onChange: async (_date) => {
          setWeekDate(_date);
          const dateMoment = moment(_date);
          const start_date = dateMoment.startOf('week').format(format);
          const end_date = dateMoment.endOf('week').format(format)
          const range_param = `${startFieldName}=${start_date}&${endFieldName}=${end_date}`;
          await stringData(range_param);
        },
        highlightDates: arrayDate,
        customInput:
          customRender ?
            customRender({ onClick: () => { calendarRef.current.setOpen(true) }, start: moment(first(arrayDate)), end: moment(last(arrayDate)), date: moment(weekDate), icon: <CalendarIcon /> })
            :
            <div className='date_picker_kit_report_date week' onClick={() => { calendarRef.current.setOpen(true) }}>
              <p className='title'>Report week</p>
              <div className="picker">
                From: {moment(first(arrayDate)).format('MM-DD-YYYY')} - To: {moment(last(arrayDate)).format('MM-DD-YYYY')}
                <CalendarIcon />
              </div>
            </div>
      });
    }

    if (daily) {
      return ({
        maxDate: !unsetMaxDate ? new Date() : undefined,
        popperPlacement: "top-end",
        selected: today,
        onChange: async (_date) => {
          setToday(_date);
          const dateMomentData = moment(_date);
          const dateMoment = dateMomentData.format(format);
          const range_param = `${startFieldName}=${dateMoment}&${endFieldName}=${dateMoment}`;
          await stringData(range_param);
          onChange(dateMomentData);
        },
        withPortal: true,
        customInput:
          customRender ?
            <div className='date_picker_kit_report_date'>
              {customRender({ onClick: () => { calendarRef.current.setOpen(true) }, date: moment(today), icon: <CalendarIcon /> })}
            </div>
            :
            <div className='date_picker_kit_report_date' onClick={() => { calendarRef.current.setOpen(true) }}>
              Report date {moment(today).format('MM-DD-YYYY')}
              <CalendarIcon />
            </div>
      });
    }
    return {};
  })();

  return (
    <div className={`${!customRender ? 'd-flex align-items-center justify-content-center ' : ''} date_picker_kit`} style={style}>
      <DatePickerKit
        ref={calendarRef}
        {...props}
      />
    </div>
  )
};

export default MyDatePicker;

export const CalendarIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z" stroke="#686868" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16 2V6" stroke="#686868" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 2V6" stroke="#686868" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 10H21" stroke="#686868" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
