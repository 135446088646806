import ContentContainer from "components/new-appointment/ContentContainer";
import Header from "components/new-appointment/Header";
import Title from "components/new-appointment/Title";
import { ANYONE_APPOINTMENT, NEW_APT_STEPS } from "constant";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import Calendar from "components/new-appointment/Calendar";
import useDebounce from "utils/useDebounce";
import { useAptContext } from "./hooks/index.context";
import shopSelectors from "store/shop/selectors";
import { useSelector } from "react-redux";

const DateStaff = (props) => {
  const context = useAptContext();
  const staffListRef = useRef();

  const staffs = useSelector(shopSelectors.staff);

  const [staff, setStaff] = useState(context.state.selectedStaff);
  const [date, setDate] = useState(context.state.selectedDate);
  const [isRequestStaff, setRequestStaff] = useState(
    context.state.isRequestStaff
  );

  const onNext = () => {
    if (!staff || !date) return;
    context.actions.setStaffDate({ staff, date, isRequestStaff });
    props.go(NEW_APT_STEPS.TIME_SLOT);
  };

  const handleChooseStaff = (_staff) => {
    setStaff(_staff);
    context.actions.setStaffDate({ staff: _staff, date, isRequestStaff });
  };

  const handleSearch = (text) => {
    if (!staffListRef.current) return;
    staffListRef.current.search(text);
  };

  return (
    <>
      <Header disabled={!staff || !date} onNext={onNext} />
      <ContentContainer>
        <Title step={NEW_APT_STEPS.DATE_STAFF} />
        <Calendar value={date} onChange={setDate} />
        <InputSearch onDebounce={handleSearch} />
        <RequestStaff checked={isRequestStaff} onChange={setRequestStaff} />
        <Staffs
          ref={staffListRef}
          data={staffs}
          selected={context.state.selectedStaff}
          onChooseStaff={handleChooseStaff}
        />
      </ContentContainer>
    </>
  );
};

export default DateStaff;

const Staffs = forwardRef(({ data = [], onChooseStaff, selected }, ref) => {
  const [search, setSearch] = useState("");

  const staffs = useMemo(() => {
    const str = search?.trim()?.toLowerCase();
    if (!str) return data;
    return data.filter((o) =>
      String(o.name || "")
        ?.toLowerCase()
        ?.includes(str)
    );
  }, [data, search]);

  const handleChooseStaff = (_staff) => () => {
    onChooseStaff(_staff);
  };

  useImperativeHandle(ref, () => ({
    search: (text) => setSearch(text),
  }));

  return (
    <div className="new_appointment__staffs">
      <Staff
        avatar={ANYONE_APPOINTMENT.avatar}
        name={ANYONE_APPOINTMENT.name}
        active={selected?.id === ANYONE_APPOINTMENT.id}
        onClick={handleChooseStaff(ANYONE_APPOINTMENT)}
      />
      {staffs.map((o) => (
        <Staff
          key={o.id}
          avatar={o.avatar}
          name={o.name}
          active={selected?.id === o.id}
          onClick={handleChooseStaff(o)}
        />
      ))}
    </div>
  );
});

const InputSearch = ({ onDebounce }) => {
  const [text, onChange] = useDebounce("", onDebounce);
  return (
    <div className="search-box">
      <Search />
      <input
        value={text}
        onChange={onChange}
        type="search"
        placeholder="Staff Name"
      />
    </div>
  );
};

const Staff = ({ avatar, name, active, onClick }) => {
  return (
    <button
      className={`new_appointment__staff ${active ? "active" : ""}`}
      onClick={onClick}
    >
      <div className="outbox_staffAvatar">
        <img className="staffAvatar" src={avatar} alt={name} />
        {active && (
          <div className="tick">
            <TickIcon />
          </div>
        )}
      </div>
      <p>{name}</p>
    </button>
  );
};
const TickIcon = () => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.988 26.1772C10.3877 26.1772 9.81739 25.9371 9.39718 25.5169L0.902823 17.0227C0.0323923 16.1522 0.0323923 14.7115 0.902823 13.8411C1.77325 12.9707 3.21397 12.9707 4.0844 13.8411L10.988 20.7445L26.4156 5.31689C27.286 4.44645 28.7267 4.44645 29.5972 5.31689C30.4676 6.18732 30.4676 7.62803 29.5972 8.49846L12.5788 25.5169C12.1585 25.9371 11.5883 26.1772 10.988 26.1772Z"
      fill="#0085E7"
    />
  </svg>
);

const RequestStaff = ({ checked, onChange }) => {
  return (
    <div
      className="new_appointment__request_staff"
      onClick={() => onChange(!checked)}
    >
      {checked ? <Checked /> : <Check />}
      <p>Request Staff</p>
    </div>
  );
};

const Checked = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="5" fill="#0085E7" />
    <path
      d="M9.72692 17.4737C9.40676 17.4737 9.10261 17.3456 8.8785 17.1215L4.34817 12.5913C3.88394 12.127 3.88394 11.3587 4.34817 10.8944C4.8124 10.4302 5.58078 10.4302 6.04501 10.8944L9.72692 14.5762L17.955 6.34817C18.4192 5.88394 19.1876 5.88394 19.6518 6.34817C20.1161 6.8124 20.1161 7.58078 19.6518 8.04501L10.5753 17.1215C10.3512 17.3456 10.0471 17.4737 9.72692 17.4737Z"
      fill="white"
    />
  </svg>
);
const Check = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="24"
      height="24"
      rx="5"
      fill="#C9CDD4"
      stroke="#BEBFC3"
      strokeWidth={2}
    />
  </svg>
);
const Search = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7_25711)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z"
        fill="#686868"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.487 17.9555C17.7796 17.6622 18.2544 17.6616 18.5477 17.9541L22.0717 21.469C22.365 21.7615 22.3656 22.2364 22.0731 22.5297C21.7806 22.8229 21.3057 22.8235 21.0125 22.531L17.4884 19.0162C17.1952 18.7236 17.1945 18.2488 17.487 17.9555Z"
        fill="#686868"
      />
    </g>
    <defs>
      <clipPath id="clip0_7_25711">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
