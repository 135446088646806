import React from 'react'
import CalendarKit from 'react-calendar';
import moment from 'moment';

const Calendar = ({ value, onChange }) => {
  return (
    <CalendarKit
      onChange={onChange}
      value={value}
      className={'calendar_kit'}
      prevLabel={<PrevIcon />}
      nextLabel={<NextIcon />}
      locale='en'
      minDate={new Date()}
      navigationLabel={(prop) => {
        const date = moment(prop.date);
        return <div>
          <p>{date.format('MMMM')}</p>
          <p className='text_year'>{date.format('YYYY')}</p>
        </div>;
      }}
    />
  )
}

export default Calendar;

const PrevIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.5882 31.5L9.41176 31.5C4.48993 31.5 0.5 27.5101 0.500001 22.5882L0.500002 9.41176C0.500002 4.48993 4.48993 0.499998 9.41177 0.499998L22.5882 0.499999C27.5101 0.5 31.5 4.48993 31.5 9.41176L31.5 22.5882C31.5 27.5101 27.5101 31.5 22.5882 31.5Z" fill="#E5E5E5" stroke="#E5E5E5" />
    <g clipPath="url(#clip0_7_26295)">
      <path d="M11.9618 16.4194C11.9618 16.1893 12.0583 15.9592 12.2509 15.7838L18.3142 10.2634C18.6999 9.91221 19.3253 9.91221 19.7108 10.2634C20.0964 10.6144 20.0964 11.1837 19.7108 11.5349L14.3457 16.4194L19.7107 21.304C20.0962 21.6551 20.0962 22.2243 19.7107 22.5753C19.3251 22.9267 18.6998 22.9267 18.314 22.5753L12.2507 17.055C12.0581 16.8795 11.9618 16.6494 11.9618 16.4194L11.9618 16.4194Z" fill="#686868" />
    </g>
    <defs>
      <clipPath id="clip0_7_26295">
        <rect width="12.8388" height="8.03819" fill="white" transform="translate(20 10) rotate(90)" />
      </clipPath>
    </defs>
  </svg>
);

const NextIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.41177 0.5H22.5882C27.5101 0.5 31.5 4.48993 31.5 9.41177V22.5882C31.5 27.5101 27.5101 31.5 22.5882 31.5H9.41177C4.48993 31.5 0.5 27.5101 0.5 22.5882V9.41177C0.5 4.48993 4.48993 0.5 9.41177 0.5Z" fill="#E5E5E5" stroke="#E5E5E5" />
    <g clipPath="url(#clip0_7_26291)">
      <path d="M20.0382 15.5806C20.0382 15.8107 19.9417 16.0408 19.7491 16.2162L13.6858 21.7366C13.3001 22.0878 12.6747 22.0878 12.2892 21.7366C11.9036 21.3856 11.9036 20.8163 12.2892 20.4651L17.6543 15.5806L12.2893 10.696C11.9038 10.3449 11.9038 9.77567 12.2893 9.42467C12.6749 9.07333 13.3002 9.07333 13.686 9.42467L19.7493 14.945C19.9419 15.1205 20.0382 15.3506 20.0382 15.5806V15.5806Z" fill="#686868" />
    </g>
    <defs>
      <clipPath id="clip0_7_26291">
        <rect width="12.8388" height="8.03819" fill="white" transform="matrix(0 -1 1 0 12 22)" />
      </clipPath>
    </defs>
  </svg>
)
